// import React, { useRef, useEffect, useCallback } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { TableContainer } from "@mui/material";

// const Diamondpopup = ({
//     purchaseProduct,
//     allDiamondAttributes,
//     handlediamondChange,
//     closePopup,
//     adddiamond,
//     deletediamond,
//     setPurchaseProduct,
//     from,
//     gindex,
//     iindex
// }) => {
//     const stoneNameRefs = useRef([]);

//     useEffect(() => {
//         if (purchaseProduct?.Diamonds?.length === 1 && !purchaseProduct.Diamonds[0].DiamondShape) {
//             stoneNameRefs.current[0]?.focus();
//         }
//     }, [purchaseProduct]);

//     // const handleAddRow = useCallback(() => {
//     //     if (from === 'addstock') {
//     //         setPurchaseProduct((prevProducts) => {
//     //             const updatedProducts = [...prevProducts];
//     //             const targetItem = updatedProducts[gindex][iindex];
//     //             targetItem.Diamonds = [...(targetItem.Diamonds || []), { ...adddiamond }];
//     //             return updatedProducts;
//     //         });
//     //         // Add a delay before focusing the next input field
//     //         setTimeout(() => {
//     //             const lastStoneIndex = purchaseProduct?.Diamonds?.length - 1;
//     //             stoneNameRefs.current[lastStoneIndex]?.focus();
//     //         }, 50); // Increase timeout delay if needed
//     //     } else {
//     //         setPurchaseProduct((prevState) => ({
//     //             ...prevState,
//     //             Diamonds: [...prevState.Diamonds, adddiamond],
//     //         }));

//     //         setTimeout(() => {
//     //             const lastStoneIndex = purchaseProduct?.Diamonds?.length - 1;
//     //             stoneNameRefs.current[lastStoneIndex]?.focus();
//     //         }, 50); // Increase timeout delay if needed
//     //     }
//     // }, [from, gindex, iindex, purchaseProduct, setPurchaseProduct, adddiamond]);

//     const handleEnterKey = (e, currentIndex, fieldName, fieldOrder) => {
//         if (e.key === 'Enter') {
//             e.preventDefault();

//             // Check if DiamondName is empty and show alert
//             if (fieldName === 'DiamondName' && !purchaseProduct?.Diamonds[currentIndex]?.DiamondName) {
//                 alert('Please enter the diamond Name');
//                 return;
//             }

//             const nextFieldIndex = fieldOrder.indexOf(fieldName) + 1;
//             if (nextFieldIndex < fieldOrder.length) {
//                 const nextField = fieldOrder[nextFieldIndex];
//                 const nextFieldElement = document.getElementById(`${nextField}-${currentIndex}`);
//                 nextFieldElement?.focus();
//                 nextFieldElement?.select();
//             } else {
//                 handleAddRow();
//                 setTimeout(() => {
//                     const nextRowIndex = purchaseProduct?.Diamonds.length;
//                     stoneNameRefs.current[nextRowIndex]?.focus();
//                 }, 50); // Increase timeout delay if needed
//             }
//         }
//     };

//     const DiamondField = ({ attributeType, value, onChange, onKeyDown, currentIndex, placeholder }) => (
//         <td style={tdStyle}>
//             <input
//                 value={value}
//                 onChange={onChange}
//                 onKeyDown={onKeyDown}
//                 type="text"
//                 placeholder={placeholder}
//                 list={`diamondAttributes-${attributeType}`}
//                 style={inputStyle}
//                 id={`${attributeType}-${currentIndex}`}
//                 ref={(el) => (stoneNameRefs.current[currentIndex] = el)}
//             />
//             <datalist id={`diamondAttributes-${attributeType}`}>
//                 {allDiamondAttributes
//                     .filter((attr) => attr.DiamondAttribute === attributeType)
//                     .map((attribute) => (
//                         <option key={attribute.DiamondValue} value={attribute.DiamondValue}>
//                             {attribute.DiamondValue}
//                         </option>
//                     ))}
//             </datalist>
//         </td>
//     );

//     const diamondFields = [
//         { attributeType: 'DiamondName', placeholder: 'Name' },
//         { attributeType: 'DiamondWeight', placeholder: 'Weight' },
//         { attributeType: 'DiamondRate', placeholder: 'Rate' },
//         { attributeType: 'DiamondPieces', placeholder: 'Pieces' },
//         { attributeType: 'DiamondClarity', placeholder: 'Clarity' },
//         { attributeType: 'DiamondColour', placeholder: 'Colour' },
//         { attributeType: 'DiamondCut', placeholder: 'Diamond Cut' },
//         { attributeType: 'DiamondShape', placeholder: 'Diamond Shape' },
//         { attributeType: 'DiamondSize', placeholder: 'Diamond Size' },
//         { attributeType: 'Certificate', placeholder: 'Certificate' },
//         { attributeType: 'SettingType', placeholder: 'Setting Type' },
//         { attributeType: 'DiamondAmount', placeholder: 'Amount' },
//         { attributeType: 'Description', placeholder: 'Description' },
//     ];

//     return (
//         <div className="adminAddProductsPopupInnerBox" style={{ maxWidth: '90vw', overflowX: 'hidden' }}>
//             <TableContainer sx={{ borderSpacing: '0', borderCollapse: 'collapse' }}>
//                 <table size="small" sx={{ borderRadius: '4px', borderCollapse: 'collapse', borderSpacing: '0' }}>
//                     <thead>
//                         <tr>
//                             {diamondFields.map((field) => (
//                                 <th key={field.attributeType} style={thStyle}>{field.placeholder}</th>
//                             ))}
//                             <th style={{ ...thStyle, width: '40px' }}>
//                                 {/* <FaPlus onClick={handleAddRow} style={{ cursor: 'pointer', color: 'green' }} /> */}
//                             </th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         {purchaseProduct?.Diamonds?.map((diamond, index) => (
//                             <tr key={index} style={{ borderBottom: '1px solid #ccc' }}>
//                                 {diamondFields.map((field) => (
//                                     <DiamondField
//                                         key={field.attributeType}
//                                         attributeType={field.attributeType}
//                                         value={diamond[field.attributeType]}
//                                         onChange={(e) => handlediamondChange(index, field.attributeType, e.target.value, stoneNameRefs.current[index])}
//                                         onKeyDown={(e) => handleEnterKey(e, index, field.attributeType, diamondFields.map(f => f.attributeType))}
//                                         currentIndex={index}
//                                         placeholder={field.placeholder}
//                                     />
//                                 ))}
//                                 <td style={tdStyle}>
//                                     <FaTrash onClick={() => deletediamond(index)} style={{ cursor: 'pointer', color: 'red' }} />
//                                 </td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>
//                 <button
//                     onClick={closePopup}
//                     className="bulkProductAddDeleteButton close-btn"
//                     style={{ marginTop: '1px' }}
//                 >
//                     Close
//                 </button>
//             </TableContainer>
//         </div>
//     );
// };

// const thStyle = {
//     padding: '5px',
//     border: '1px solid #ccc',
//     textAlign: 'center',
//     fontWeight: '600',
// };

// const tdStyle = {
//     padding: '5px',
//     border: '1px solid #ccc',
//     textAlign: 'center',
// };

// const inputStyle = {
//     width: '100%',
//     padding: '3px',
//     margin: '0',
//     boxSizing: 'border-box',
//     borderRadius: '4px',
//     border: '1px solid #ccc',
// };

// export default Diamondpopup;

import React, { useRef, useEffect, useCallback, useState } from 'react';

const Diamondpopup = ({
    purchaseProduct,
    allDiamondAttributes,
    handlediamondChange,
    closePopup,
    adddiamond,
    deletediamond,
    setPurchaseProduct,
    from,
    gindex,
    iindex,
}) => {
    const stoneNameRefs = useRef([]);

    // Focus on the first field of a new diamond row when a diamond is added
    useEffect(() => {
        if (purchaseProduct?.Diamonds?.length === 1 && !purchaseProduct.Diamonds[0].DiamondShape) {
            stoneNameRefs.current[0]?.focus();
        }
    }, [purchaseProduct?.Diamonds?.length]);

    const handleEnterKey = (e, currentIndex, fieldName, fieldOrder) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const nextFieldIndex = fieldOrder.indexOf(fieldName) + 1;
            if (nextFieldIndex < fieldOrder.length) {
                const nextField = fieldOrder[nextFieldIndex];
                const nextFieldElement = document.getElementById(`${nextField}-${currentIndex}`);
                nextFieldElement?.focus();
                nextFieldElement?.select();
                if (nextFieldElement && (nextFieldElement.tagName === 'INPUT' || nextFieldElement.tagName === 'TEXTAREA')) {
                    nextFieldElement.select(); // Select all text inside the input
                  }
            } else {
                handleAddRow();
                setTimeout(() => {
                    const nextRowIndex = purchaseProduct?.Stones.length;
                    stoneNameRefs.current[nextRowIndex]?.focus();
                  }, 0);
            }
        }
    };

        const handleAddRow = useCallback(() => {
        if (from === 'addstock') {
            setPurchaseProduct((prevProducts) => {
                const updatedProducts = [...prevProducts];
                const targetItem = updatedProducts[gindex][iindex];
                targetItem.Diamonds = [...(targetItem.Diamonds || []), { ...adddiamond }];
                return updatedProducts;
            });
            // Add a delay before focusing the next input field
            setTimeout(() => {
                const lastStoneIndex = purchaseProduct?.Diamonds?.length - 1;
                stoneNameRefs.current[lastStoneIndex]?.focus();
            }, 50); // Increase timeout delay if needed
        } else {
            setPurchaseProduct((prevState) => ({
                ...prevState,
                Diamonds: [...prevState.Diamonds, adddiamond],
            }));

            setTimeout(() => {
                const lastStoneIndex = purchaseProduct?.Diamonds?.length - 1;
                stoneNameRefs.current[lastStoneIndex]?.focus();
            }, 50); // Increase timeout delay if needed
        }
    }, [from, gindex, iindex, purchaseProduct, setPurchaseProduct,adddiamond]);

    const diamondFields = [
        { attributeType: 'DiamondName', placeholder: 'Name' },
        { attributeType: 'DiamondWeight', placeholder: 'Weight' },
        { attributeType: 'DiamondRate', placeholder: 'Rate' },
        { attributeType: 'DiamondPieces', placeholder: 'Pieces' },
        { attributeType: 'DiamondClarity', placeholder: 'Clarity' },
        { attributeType: 'DiamondColour', placeholder: 'Colour' },
        { attributeType: 'DiamondCut', placeholder: 'Diamond Cut' },
        { attributeType: 'DiamondShape', placeholder: 'Diamond Shape' },
        { attributeType: 'DiamondSize', placeholder: 'Diamond Size' },
        { attributeType: 'Certificate', placeholder: 'Certificate' },
        { attributeType: 'SettingType', placeholder: 'Setting Type' },
        { attributeType: 'DiamondAmount', placeholder: 'Amount' },
        { attributeType: 'Description', placeholder: 'Description' },
    ];

    const DiamondField = ({ attributeType, value, onChange, onKeyDown, currentIndex, placeholder }) => (
        <td style={tdStyle}>

        </td>
    );

    return (
        <div className="adminAddProductsPopupInnerBox" style={{ maxWidth: '90vw', overflowX: 'hidden' }}>
            <TableContainer sx={{ borderSpacing: '0', borderCollapse: 'collapse' }}>
                <table size="small" sx={{ borderRadius: '4px', borderCollapse: 'collapse', borderSpacing: '0' }}>
                    <thead>
                        <tr>
                            {diamondFields.map((field) => (
                                <th key={field.attributeType} style={thStyle}>{field.placeholder}</th>
                            ))}
                            <th style={{ ...thStyle, width: '40px' }}>
                                {/* Add button if needed */}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {purchaseProduct?.Diamonds?.map((diamond, index) => (
                            <tr key={index} style={{ borderBottom: '1px solid #ccc' }}>
                                {diamondFields.map((field) => (
                                    <td style={tdStyle}>
                                        <div>
                                            <input
                                                value={diamond[field.attributeType]}
                                                onChange={(e) => handlediamondChange(index, field.attributeType, e.target.value)}
                                                onKeyDown={(e) => handleEnterKey(e, index, field.attributeType, diamondFields.map(f => f.attributeType))}
                                                type="text"
                                                placeholder={field.placeholder}
                                                list={`diamondAttributes-${field.attributeType}`}
                                                style={inputStyle}
                                                id={`${field.attributeType}-${index}`}
                                                ref={(el) => (stoneNameRefs.current[index] = el)}
                                            />
                                            <datalist id={`diamondAttributes-${field.attributeType}`}>
                                                {allDiamondAttributes
                                                    .filter((attr) => attr.DiamondAttribute === field.attributeType)
                                                    .map((attribute) => (
                                                        <option key={attribute.DiamondValue} value={attribute.DiamondValue}>
                                                            {attribute.DiamondValue}
                                                        </option>
                                                    ))}
                                            </datalist>

                                        </div>
                                    </td>
                                ))}
                                <td style={tdStyle}>
                                    <FaTrash onClick={() => deletediamond(index)} style={{ cursor: 'pointer', color: 'red' }} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button onClick={closePopup} className="bulkProductAddDeleteButton close-btn" style={{ marginTop: '1px' }}>
                    Close
                </button>
            </TableContainer>
        </div>
    );
};
const thStyle = {
    padding: '5px',
    border: '1px solid #ccc',
    textAlign: 'center',
    fontWeight: '600',
};

const tdStyle = {
    padding: '5px',
    border: '1px solid #ccc',
    textAlign: 'center',
};

const inputStyle = {
    width: '100%',
    padding: '3px',
    margin: '0',
    boxSizing: 'border-box',
    borderRadius: '4px',
    border: '1px solid #ccc',
};

export default Diamondpopup;