// FieldConfig.js

export class FieldConfig {
  constructor(key, label, placeholder = "", priority = 1, type = 'input', visibility = '', IsVisible = false, place = '', PagePermission = 'disable') {
    this.key = key;
    this.label = label;
    this.placeholder = placeholder;
    this.priority = priority;
    this.type = type;
    this.visibility = visibility;
    this.IsVisible = IsVisible;
    this.PagePermission = PagePermission;
    this.place = place;

  }

  // Method to update visibility based on API response
  setVisibility(isVisible) {
    this.isVisible = isVisible;
  }
}




export const AddStockFields = [

  new FieldConfig("Supplier", "Supplier", "", 1, "dropdown", "ReadOnly", true, "top"),
  new FieldConfig("StockKeepingUnit", "SKU", "", 1, "inputselect", "ReadOnly", true, "top"),
  new FieldConfig("LotNumber", "Lot Number", "", 1, "inputselect", "ReadOnly", true, "top"),
  new FieldConfig("StockType", "Stock Type", "", 1, "dropdown", "ReadOnly", true, "top"),
  new FieldConfig("unlabelgold", "Unlabel Gold", "", 1, "text", "ReadOnly", true, "top"),
  new FieldConfig("unlabelsilver", "Unabel Silver", "", 1, "text", "ReadOnly", true, "top"),
  new FieldConfig("unlabeldiamond", "Unlabel Diamond", "", 1, "text", "ReadOnly", true, "top"),
  new FieldConfig("unlabelother", "Unlabel Other", "", 1, "text", "ReadOnly", true, "top"),
  //label
  new FieldConfig("CategoryId", "Category", "", 1, "dropdown", "ReadOnly", true, "middle"),
  new FieldConfig("productTypeId", "Product", "", 1, "dropdown", "ReadOnly", true, "middle"),
  new FieldConfig("purity", "Purity", "", 1, "dropdown", "ReadOnly", true, "middle"),
  new FieldConfig("DesignId", "Design", "", 1, "dropdown", "ReadOnly", true, "middle"),
  new FieldConfig("collectionmain", "Collection", "", 1, "dropdown", "ReadOnly", true, "middle"),
  new FieldConfig("PacketId", "Packet", "", 1, "dropdown", "ReadOnly", true, "middle"),
  new FieldConfig("boxId", "Box", "", 1, "dropdown", "ReadOnly", true, "middle"),
  new FieldConfig("mainstone", "Select stones", "", 1, "dropdown", "ReadOnly", true, "middle"),
  new FieldConfig("weightcategory", "Select weight", "", 1, "dropdown", "ReadOnly", true, "middle"),
  new FieldConfig("qty", "Quantity", "", 1, "inputnumber", "ReadOnly", true, "middle"),
  new FieldConfig("pieces", "Pieces", "", 1, "inputnumber", "ReadOnly", true, "middle"),
  new FieldConfig("images", "Images", "", 1, "imagesbox", "ReadOnly", false, "middle"),
  new FieldConfig("tgrosswt", "grosswt", "", 1, "inputnumber", "ReadOnly", true, "middle"),
  new FieldConfig("tclipWeight", "clipwt", "", 1, "inputnumber", "ReadOnly", true, "middle"),
  new FieldConfig("tstoneWeight", "stonewt", "", 1, "inputnumber", "ReadOnly", true, "middle"),
  new FieldConfig("tnetWt", "netwt", "", 1, "inputnumber", "ReadOnly", true, "middle"),
  new FieldConfig("tmrp", "mrp", "", 1, "inputnumber", "ReadOnly", true, "middle"),
  new FieldConfig("tpnme", "product name", "", 1, "inputtext", "ReadOnly", true, "middle"),
  new FieldConfig("tpdecription", "description", "", 1, "inputtext", "ReadOnly", true, "middle"),
  new FieldConfig("tgender", "gender", "", 1, "dropdown", "ReadOnly", true, "middle"),
  new FieldConfig("tmaking%", "making%", "", 1, "inputnumber", "ReadOnly", true, "middle"),

  //loose diamonds
  new FieldConfig("MDiamondShape", "Diamond shape1", "", 1, "dinputselect", "ReadOnly", false, "middle"),
  new FieldConfig("MDiamondClarity", "Diamond Clarity", "", 1, "dinputselect", "ReadOnly", false, "middle"),
  new FieldConfig("MDiamondColour", "Diamond Color", "", 1, "dinputselect", "ReadOnly", false, "middle"),
  new FieldConfig("MDiamondSize", "Diamond Size", "", 1, "dinputselect", "ReadOnly", false, "middle"),
  new FieldConfig("dsleves", "Diamond sleves", "", 1, "dinputselect", "ReadOnly", false, "middle"),
  new FieldConfig("dweight", "Diamond weight", "", 1, "dinputselect", "ReadOnly", false, "middle"),
  new FieldConfig("dselrate", "D SellRate", "", 1, "dinputselect", "ReadOnly", false, "middle"),
  new FieldConfig("images", "Totalweight", "", 1, "imagesbox", "ReadOnly", false, "middle"),
  // new FieldConfig("totalamount", "Totalamount", "", 1, "inputnumber", "ReadOnly", true, "middle"),
  new FieldConfig("MDiamondCut", "Diamond Cut", "", 1, "dinputselect", "ReadOnly", false, "middle"),
  new FieldConfig("dsetting", "Diamond Setting", "", 1, "dinputselect", "ReadOnly", false, "middle"),
  new FieldConfig("tcertificate", "certificate", "", 1, "dinputselect", "ReadOnly", false, "middle"),
  //unlabel gold
  new FieldConfig("tfine", "fine", "", 1, "inputtext", "ReadOnly", false, "middle"),
  new FieldConfig("twastage", "wastage", "", 1, "dropdown", "ReadOnly", false, "middle"),
  new FieldConfig("tfinewastage", "fine+wastage", "", 1, "inputnumber", "ReadOnly", false, "middle"),

  new FieldConfig("addbtn", "Add", "", 1, "button", "ReadOnly", true, "middle"),
  new FieldConfig("importbtn", "Import", "", 1, "button", "ReadOnly", true, "middle"),
  //grid


  new FieldConfig("VendorId", "Vendor", "", 1, "dropdown", "ReadOnly", true, "grid"),

  new FieldConfig("ItemCode", "ItemCode", "", 1, "read", "ReadOnly", true, "grid"),
  new FieldConfig("RFIDCode", "RFIDCode", "", 1, "number", "ReadOnly", true, "grid"),
  new FieldConfig("TIDNumber", "TidValue", "", 1, "read", "ReadOnly", true, "grid"),
  new FieldConfig("TotalWeight", "TotalWT" , "", 1, "read", "ReadOnly", true, "grid"),
  // new FieldConfig("TotalStoneWeight", "TStoneWT", "", 1, "read", "ReadOnly", true, "grid"),

  new FieldConfig("GrossWt", "Grosswt", "", 1, "number", "ReadOnly", true, "grid"),
  new FieldConfig("TotalStoneWeight", "StoneWt", "", 1, "number", "ReadOnly", true, "grid"),
  new FieldConfig("ClipWeight", "ClipWt", "", 1, "number", "ReadOnly", true, "grid"),
  new FieldConfig("ClipQuantity", "ClipQty", "", 1, "number", "ReadOnly", true, "grid"),
  new FieldConfig("NetWt", "Netwt", "", 1, "number", "ReadOnly", true, "grid"),
  new FieldConfig("TotalNetWt", "TNetwt", "", 1, "number", "ReadOnly", true, "grid"),
  new FieldConfig("Size", "Size", "", 1, "number", "ReadOnly", true, "grid"),
  new FieldConfig("Pieces", "Pieces", "", 1, "number", "ReadOnly", true, "grid"),
  new FieldConfig("Description", "Description", "", 1, "number", "ReadOnly", true, "grid"),
  new FieldConfig("ProductTitle", "Producttitle", "", 1, "number", "ReadOnly", true, "grid"),
  new FieldConfig("HUIDCode", "Huid", "", 1, "number", "ReadOnly", true, "grid"),
  new FieldConfig("HallmarkAmount", "Hallmark", "", 1, "number", "ReadOnly", true, "grid"),
  new FieldConfig("TotalStoneAmount", "TotalSAmt", "", 1, "number", "ReadOnly", true, "grid"),
  new FieldConfig("MakingPerGram", "Making/grm", "", 1, "number", "ReadOnly", true, "grid"),
  new FieldConfig("MakingPercentage", "Making%", "", 1, "number", "ReadOnly", true, "grid"),
  new FieldConfig("MakingFixedAmt", "MakingFixedAmt", "", 1, "number", "ReadOnly", true, "grid"),
  new FieldConfig("MakingFixedWastage", "MakingFixedWastage", "", 1, "number", "ReadOnly", true, "grid"),
  new FieldConfig("MRP", "MRP", "", 1, "number", "ReadOnly", true, "grid"),
  new FieldConfig("OccassionName", "Occcassion", "", 1, "number", "ReadOnly", true, "grid"),
  new FieldConfig("Gender", "Gender", "", 1, "dropdown", "ReadOnly", true, "grid"),
  new FieldConfig("Status", "status", "", 1, "number", "ReadOnly", true, "grid"),
  new FieldConfig("Featured", "Featured", "", 1, "number", "ReadOnly", true, "grid"),
  new FieldConfig("Metal", "Metal", "", 1, "number", "ReadOnly", true, "grid"),
  
  
  
  
  // new FieldConfig("FinePercent", "Fine%", "", 1, "number", "ReadOnly", true, "grid"),
  // new FieldConfig("WastagePercent", "Wastage%", "", 1, "number", "ReadOnly", true, "grid"),
  // new FieldConfig("Quantity", "Qty", "", 1, "number", "ReadOnly", true, "grid"),
  // new FieldConfig("StonePieces", "Stone Pcs", "", 1, "number", "ReadOnly", true, "grid"),
  // new FieldConfig("MakingPerGram", "Making/GRM", "", 1, "number", "ReadOnly", true, "grid"),
  // new FieldConfig("MakingFixedWastage", "Fixed Wastage", "", 1, "number", "ReadOnly", true, "grid"),
  // new FieldConfig("MakingFixedAmt", "Fixed Amt", "", 1, "number", "ReadOnly", true, "grid"),
  // new FieldConfig("MakingPercentage", "Making%", "", 1, "number", "ReadOnly", true, "grid"),
  // new FieldConfig("MetalRate", "Rate/10Gm", "", 1, "number", "ReadOnly", true, "grid"),
  // new FieldConfig("StoneAmount", "Stone Amt", "", 1, "number", "ReadOnly", true, "grid"),
  // new FieldConfig("HallmarkAmt", "Hallmark", "", 1, "number", "ReadOnly", true, "grid"),
  // new FieldConfig("TagWeight", "Tag Wt", "", 1, "number", "ReadOnly", true, "grid"),
  // new FieldConfig("FindingWeight", "Finding Wt", "", 1, "number", "ReadOnly", true, "grid"),
  // new FieldConfig("LanyardWeight", "Lanyard wt", "", 1, "number", "ReadOnly", true, "grid"),
  // new FieldConfig("ItemAmt", "Item Amt", "", 1, "number", "ReadOnly", true, "grid"),
  // new FieldConfig("MakingAmt", "Making Amt", "", 1, "number", "ReadOnly", true, "grid"),
  // new FieldConfig("TotalItemAmt", "Total Item Amt", "", 1, "number", "ReadOnly", true, "grid"),
  // new FieldConfig("FineWt", "Fine Wt", "", 1, "number", "ReadOnly", true, "grid"),
  // new FieldConfig("FineWastageWt", "FineWastage", "", 1, "number", "ReadOnly", true, "grid"),
  // new FieldConfig("WastageWt", "Wastage wt", "", 1, "number", "ReadOnly", true, "grid"),
  // new FieldConfig("Testing", "Testing", "", 1, "number", "ReadOnly", true, "grid"),
  // new FieldConfig("AddToUnlabelled", "Add to unlabel", "", 1, "checkbox", "ReadOnly", true, "grid"),
 

  new FieldConfig("DiamondShape", "D Shape", "", 1, "text", "ReadOnly", false, "grid"),
  new FieldConfig("DiamondClarity", "D Clarity", "", 1, "text", "ReadOnly", false, "grid"),
  new FieldConfig("DiamondColour", "D Colour", "", 1, "text", "ReadOnly", false, "grid"),
  new FieldConfig("DiamondCut", "D Cut", "", 1, "text", "ReadOnly", false, "grid"),
  new FieldConfig("DiamondSize", "Diamond Size", "", 1, "number", "ReadOnly", false, "grid"),
  new FieldConfig("Sieve", "Diamond Sleves", "", 1, "number", "ReadOnly", false, "grid"),
  new FieldConfig("DiamondWeight", "Diamond wt", "", 1, "number", "ReadOnly", false, "grid"),
  new FieldConfig("DiamondSellRate", "D Sell Rate", "", 1, "number", "ReadOnly", false, "grid"),
  new FieldConfig("DiamondSettingType", "D SettingType", "", 1, "text", "ReadOnly", false, "grid"),
  new FieldConfig("DiamondCertificate", "Diamond certificate", "", 1, "text", "ReadOnly", false, "grid"),
  new FieldConfig("DiamondDescription", "D Description", "", 1, "text", "ReadOnly", false, "grid"),



  new FieldConfig("DiamondName", "Diamond Name", "", 1, "text", "ReadOnly", false, "grid"),
  new FieldConfig("DiamondAmount", "Diamond Amount", "", 1, "number", "ReadOnly", false, "grid"),
  new FieldConfig("DiamondPieces", "Diamond Pcs", "", 1, "number", "ReadOnly", false, "grid"),
  new FieldConfig("DiamondRate", "Diamond Rate", "", 1, "number", "ReadOnly", false, "grid"),
  new FieldConfig("DiamondPurchaseRate", "D Purchase Rate", "", 1, "number", "ReadOnly", false, "grid"),
  
  new FieldConfig("DiamondPurchaseAmount", "D Purchase Amt", "", 1, "number", "ReadOnly", false, "grid"),
  new FieldConfig("DiamondSellAmount", "D Sell Amt", "", 1, "number", "ReadOnly", false, "grid"),
  new FieldConfig("TotalDiamondWeight", "D Purchase Amt", "", 1, "number", "ReadOnly", false, "grid"),
  new FieldConfig("TotalDiamondAmount", "D Sell Amt", "", 1, "number", "ReadOnly", false, "grid"),
  

  new FieldConfig("Stones", "Stones", "", 1, "button", "ReadOnly", true, "grid"),
  new FieldConfig("DButton", "Diamonds", "", 1, "button", "ReadOnly", true, "grid"),
];



