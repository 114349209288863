import React, { useState } from "react";
import { useEffect } from "react";
import "./Userrole.css";
import AdminTopNavbar from "../../Heading/AdminTopNavbar";
import SideHeading from "../../Heading/SideHeading";
// import { useSelector } from "react-redux";
import VerticalMenu from "./VerticleMenu/VerticalMenu";
import { PurchaseEntryFields } from "Components/support/Allfields/purchasefields";
import GetApiService from "Components/Api/getapiService";
import { useAdminData } from "Components/Pages/AdminPanel/AdminSettings/useAdminData";
import { FaEdit } from "react-icons/fa"; // Import edit icon from react-icons
import { AddStockFields } from "Components/support/Allfields/addstock";
import { toPlainFields } from "Components/support/Allfields/fieldconverter";
import { setPermissions } from "redux/action/Actions";
import { useSelector, useDispatch } from "react-redux";
import { a243, a244, a245, a246, a247 } from "Components/Api/RootApiPath";




export default function UserRole() {
  const [roles, setRoles] = useState("");
  const [pageDetails, setPageDetails] = useState("");
  // const [itemfields, setItemfields] = useState([]);
  const [customRole, setCustomRole] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [employees, setEmployees] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");


  const [checkedItems, setCheckedItems] = useState({});
  const [checkedHeaders, setCheckedHeaders] = useState({});
  const [expandedItems, setExpandedItems] = useState({});
  const [selectedPermissions, setSelectedPermissions] = useState({});
  const [expandedHeaders, setExpandedHeaders] = useState({});
  const [fieldSequence, setFieldSequence] = useState({});
  const [itemfields, setItemfields] = useState([]);
  const [allrole, setAllrole] = useState([])
  const [allpermissions, setAllpermissions] = useState([])
  const [errorMessage, setErrorMessage] = useState(null);
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [selectedRole, setSelectedRole] = useState({
    RoleId: '',
    RoleName: '',
  });




  const fieldDefinitions = {
    "Purchase Entry": toPlainFields(PurchaseEntryFields),
    "Add Bulk Stock New": toPlainFields(AddStockFields)

    // "Add Single Stock": toPlainFields(addstockfields),
    // Add other specific fields if needed
  };


  const dispatch = useDispatch();




  const {
    clientCode,
    CompanyId,
    CounterId,
    BranchId,
    EmployeId,
    employeeCode,
    rdPurchaseFormat,
  } = useAdminData();

  const apiService = new GetApiService(clientCode);


  const loadData = async () => {
    // setLoading(true);
    try {
      const apiCalls = [

        apiService.fetchAllEmployees(),
        apiService.fetchAllRole(),
      ];

      const results = await Promise.allSettled(apiCalls);

      results.forEach((result, index) => {
        if (result.status === "fulfilled") {
          // Handle successful response
          switch (index) {
            case 0:
              setEmployees(result.value);
              break;
            case 1:
              console.log('check allvalues ', result.value);

              setAllrole(result.value);
              break;

            default:
              break;
          }
        } else {
          if (index + 1 > 1) {
            console.error(
              `Error loading data for API ${index + 1}:`,
              result.reason
            );
            handleError(
              `Failed to load data for API ${index + 1}: ${result.reason}`
            );
          }
        }
      });
    } catch (error) {
      console.error("Error loading data:", error);
      handleError("Error loading data. Please try again later.");
    } finally {
      // setLoading(false);
    }
  };

  const handleError = (message) => {
    setErrorMessage(message);
    setShowModal(true); // Open the modal
  };

  useEffect(() => {
    if (clientCode) {
      loadData();
    }
  }, [clientCode]);
  // useEffect(() => {

  //   const field = toPlainFields(PurchaseEntryFields)
  //   setItemfields(field)

  // }, [])

  const menuData1 = [
    {
      heading: "User Master",
      items: [
        "Company",
        "Branch",
        "Counter",
        "Department",
        "Roles",
        "Employees",
        "Bank Account",
        "Devices",
        "Tax",
        "Rate Conversion",
      ],
    },
    {
      heading: "Product Master",
      items: [
        "Category",
        "Product",
        "Design",
        "Purity",
        "Packet",
        "Box",
        "Stone",
        "Diamond Size/Weight/Rate",
        "SKU",
        "Rates",
        "Collection",
        "Occasion",
      ],
    },
    {
      heading: "Trading",
      items: [
        "Purchase Entry",
        "Create Packet",
        "Add Bulk Stock New",
        "Add Single Stock",
        "Invoice",
        "Make Payments",
        "Receive Payments",
        "Credit Note / Sale Return",
        "Debit Note / Purchase Returns",
        "Stock Transfer",
        "Stock Transfer List",
      ],
    },
    {
      heading: "Reports",
      items: [
        "Stock",
        "Sale",
        "Order List",
        "Purchase",
        "Customer Ledger",
        "Supplier Ledger",
        "Old Metal",
        "Cash",
      ],
    },
    {
      heading: "Settings",
      items: [
        "Vendor Tounche",
        "Customer Tounche",
        "Diamond Attributes",
        "Pair Customer Vendor",
        "Customer Slab",
        "Customer Rate Of Interest",
        "Customer Credit Period",
      ],
    },
  ];

  const menuData = [
    {
      heading: "User Master",
      items: [
        { displayName: "Company", route: "add_company" },
        { displayName: "Branch", route: "add_branch" },
        { displayName: "Counter", route: "add_counter" },
        { displayName: "Department", route: "add_department" },
        { displayName: "Roles", route: "add_roles" },
        { displayName: "Employees", route: "add_employees" },
        { displayName: "Bank Account", route: "add_banks" },
        { displayName: "Devices", route: "add_devices" },
        { displayName: "Tax", route: "add_tax" },
        { displayName: "Rate Conversion", route: "add_rate" },
      ],
    },
    {
      heading: "Product Master",
      items: [
        { displayName: "Category", route: "add_category" },
        { displayName: "Product", route: "add_product" },
        { displayName: "Design", route: "add_design" },
        { displayName: "Purity", route: "add_purity" },
        { displayName: "Packet", route: "add_packet" },
        { displayName: "Box", route: "add_box" },
        { displayName: "Stone", route: "add_stone" },
        { displayName: "Diamond Size/Weight/Rate", route: "add_diamond_size_weight_rate" },

        { displayName: "SKU", route: "add_sku" },
        { displayName: "Rates", route: "add_rates" },
        { displayName: "Collection", route: "add_collection" },
        { displayName: "Occasion", route: "add_occasion" },
      ],
    },
    {
      heading: "Trading",
      items: [
        { displayName: "Purchase Entry", route: "purchase_entry" },
        { displayName: "Create Packet", route: "create_packet" },
        { displayName: "Add Bulk Stock New", route: "add_bulk_stock_new" },
        { displayName: "Add Single Stock", route: "add_single_stock" },
        { displayName: "Invoice", route: "invoice" },
        { displayName: "Make Payments", route: "make_payments" },
        { displayName: "Receive Payments", route: "receive_payments" },
        { displayName: "Credit Note / Sale Return", route: "credit_note_sale_return" },
        { displayName: "Debit Note / Purchase Returns", route: "debit_note_purchase_returns" },
        { displayName: "Stock Transfer", route: "stock_transfer" },
        { displayName: "Stock Transfer List", route: "stock-transfer_list" },
      ],
    },

    {
      heading: "Reports",
      items: [
        { displayName: "Stock", route: "stock_report" },
        { displayName: "Sale", route: "admin_sale_report" },
        { displayName: "Order List", route: "admin_orders" },
        { displayName: "Purchase", route: "purchase" },
        { displayName: "Customer Ledger", route: "admin_customer_ledger" },
        { displayName: "Supplier Ledger", route: "admin_vendor_ledger" },
        { displayName: "Old Metal", route: "purchase" },
        { displayName: "Cash", route: "cash_report" },
      ],
    },
    {
      heading: "Settings",
      items: [
        { displayName: "Vendor Tounche", route: "vendor_tounche" },
        { displayName: "Customer Tounche", route: "customer_tounche" },
        { displayName: "Diamond Attributes", route: "diamond_attributes" },
        { displayName: "Pair Customer Vendor", route: "pair_customer_vendor" },
        { displayName: "Customer Slab", route: "customer_slab" },
        { displayName: "Customer Rate Of Interest", route: "customer_rate_of_interest" },
        { displayName: "Customer Credit Period", route: "customer_credit_period" },
        { displayName: "User Management", route: "user-management" },
      ],
    },
  ];

  // const [initialModulesData,  setInitialModulesData]= menuData.map((section, sectionIndex) => ({
  //   heading: section.heading.toLowerCase().replace(/ /g, "_"), // e.g., "user_master"
  //   PageId: sectionIndex, // Use index as a unique PageId for demonstration; adjust as needed
  //   PageName: section.heading.toLowerCase().replace(/ /g, "_"), // e.g., "user_master"
  //   PageDisplayName: section.heading,
  //   PermissionId: "0",
  //   PagePermission: "enable",
  //   items: section.items.map((itemName) => ({
  //     itemName: itemName,
  //     PagePermission: "enable",
  //     PageControls: fieldDefinitions[itemName] || [], // Use specific fields if defined, or an empty array
  //   })),
  // }));
  const [initialModulesData, setInitialModulesData] = useState(
    menuData.map((section, sectionIndex) => ({
      heading: section.heading.toLowerCase().replace(/ /g, "_"), // e.g., "user_master"
      PageId: sectionIndex, // Use index as a unique PageId for demonstration; adjust as needed
      PageName: section.heading.toLowerCase().replace(/ /g, "_"), // e.g., "user_master"
      PageDisplayName: section.heading,
      PermissionId: "0",
      PagePermission: "enable",
      items: section.items.map((item) => ({
        itemName: item.displayName, // Use displayName from each item
        path: item.route,
        PagePermission: "enable",
        PageControls: fieldDefinitions[item.displayName] || [], // Use specific fields if defined, or an empty array
      })),
    }))
  );


  const fetchUserPermissions = async (clientCode) => {
    try {
      const response = await fetch(a243, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ClientCode: clientCode,
        }),
      });

      const data = await response.json();
      setAllpermissions(data);
      // const permissions = data[0]?.Modules || []; // Access `Modules` within the response
      // return permissions;
      // return data; // This should return an array of permissions
    } catch (error) {
      console.error("Error fetching permissions:", error);
      return [];
    }
  };
  useEffect(() => {
    fetchUserPermissions(clientCode);
  }, [])

  const handleEdit = async (employeeId) => {
    try {


      const employeePermissions = allpermissions.find(
        (permission) => permission.UserId === employeeId.Id
      );

      if (employeePermissions) {



        setSelectedUser(employeePermissions.UserId);

        // Find the selected user in the employees array
        const selectedEmployee = employees.find(employee => employee.Id === parseInt(employeePermissions.UserId));


        if (selectedEmployee) {
          console.log("Selected Employee: ", selectedEmployee);

          // Check if the selected user's RoleId matches any RoleId from allroles
          const selectrole = allrole.find(role => parseInt(role.RoleId) === parseInt(selectedEmployee.RoleId));

          // Log the role to debug
          console.log("Check Employee Role: ", selectrole);
          console.log("check employee", selectrole);
          setUserName(selectedEmployee.UserName);
          setPassword(selectedEmployee.Password);

          setSelectedRole({
            RoleId: selectrole.RoleId,
            RoleName: selectrole.RoleName
          });
          setCustomRole(employeePermissions.CustomRole)

        }

        const field = toPlainFields(PurchaseEntryFields)
        console.log("Employee permissions found:", employeePermissions);
        console.log("local Permissions:", fieldDefinitions);

        const updatedEmployeePermissions = ensureEmployeeFields(employeePermissions, fieldDefinitions);

        console.log("Updated Employee Permissions:", updatedEmployeePermissions);
        const transformedPermissions = transformPermissions(menuData, employeePermissions);


        console.log("Transformed Permissions:", transformedPermissions);
        setInitialModulesData(transformedPermissions)

        // setInitialData(employeePermissions); // Set the permissions data to initialData
      } else {
        console.log("No permissions found for this employee.");
      }

    } catch (error) {
      console.error("Failed to load permissions:", error);
    }
  };
  const transformPermissions = (menuData, employeePermissions) => {
    return menuData.map((section) => {
      // Find matching modules for the current section heading
      const matchedItems = section.items.map((item) => {
        // Find corresponding module in employeePermissions based on PageName and route
        const matchingModule = employeePermissions.Modules.find(
          (module) => module.PageName === item.route
        );
        console.log('check matching ', matchingModule);

        // If a matching module is found, set its permissions; otherwise, use defaults
        return {
          itemName: item.displayName,
          path: item.route,
          PageId: matchingModule ? matchingModule.PageId : 0,
          PagePermission: matchingModule ? matchingModule.PagePermission : "enable",
          PageControls: matchingModule ? matchingModule.PageControls : [],
        };
      });

      return {
        heading: section.heading.toLowerCase().replace(/ /g, "_"),
        PageId: section.PageId,
        PageName: section.heading.toLowerCase().replace(/ /g, "_"),
        PageDisplayName: section.heading,
        PermissionId: "0",
        PagePermission: "enable",
        items: matchedItems,
      };
    });
  };

  function ensureEmployeeFields(employeePermissions, fieldDefinitions) {
    // Iterate over each module in employeePermissions
    const updatedModules = employeePermissions.Modules.map((module) => {
      // Find the corresponding local fields for this module
      const localFields = fieldDefinitions[module.PageDisplayName];

      // If there are local fields for this module, check for missing and existing fields
      if (localFields) {
        // Map the existing keys in employee module's PageControls
        const existingKeys = new Set(module.PageControls.map((field) => field.key));

        // Check for fields in localFields that are missing in the employee's PageControls
        const missingFields = localFields.filter((localField) => !existingKeys.has(localField.key));

        // Check for fields that are already existing but may need updating
        const updatedFields = localFields.map((localField) => {
          const existingField = module.PageControls.find(field => field.key === localField.key);

          if (existingField) {
            // If the field exists, you can update it here. For example:
            return {
              ...existingField,
              // Assuming `localField` contains the updated field data:
              // value: localField.value || existingField.value,  // Update the field value if necessary
              place: localField.place ,  // Update label if changed
              label: localField.label,
              type: localField.type ,
              // Any other properties you may want to update
            };
          }
          return localField;  // For missing fields, add them later
        });

        // Add missing fields to PageControls
        module.PageControls = [...updatedFields, ...missingFields];
      }

      return module;
    });

    // Return updated employee permissions with all fields ensured
    return {
      ...employeePermissions,
      Modules: updatedModules,
    };
  }


  // function ensureEmployeeFields(employeePermissions, fieldDefinitions) {
  //   // Iterate over each module in employeePermissions
  //   const updatedModules = employeePermissions.Modules.map((module) => {
  //     // Find the corresponding local fields for this module
  //     const localFields = fieldDefinitions[module.PageDisplayName];

  //     // If there are local fields for this module, check for missing fields
  //     if (localFields) {
  //       // Map the existing keys in employee module's PageControls
  //       const existingKeys = new Set(module.PageControls.map((field) => field.key));

  //       // Check for fields in localFields that are missing in the employee's PageControls
  //       const missingFields = localFields.filter((localField) => !existingKeys.has(localField.key));

  //       // Add missing fields to PageControls
  //       module.PageControls = [...module.PageControls, ...missingFields];
  //     }
  //     return module;
  //   });

  //   // Return updated employee permissions with all fields ensured
  //   return {
  //     ...employeePermissions,
  //     Modules: updatedModules,
  //   };
  // }

  const handleSave = async () => {


    const newEmployee = {
      srNo: employees.length + 1,
      employeeName: selectedUser, // Assume this dropdown gives employee name
      userName,
      password,
      role: roles,
      customRole,
      status: true,
    };
    // setEmployees([...employees, newEmployee]);
    // setSelectedUser("");
    // setUserName("");
    // setPassword("");
    // setRoles("");
    // setCustomRole("");

    const selectedEmployee = employees.find(employee => employee.Id === parseInt(selectedUser));

    if (!selectedEmployee) {
      alert("Selected employee not found. Please select a valid user.");
      return; // Stop further execution
    }

    const username = userName
    const pass = password

    if (!selectedEmployee?.Id || !username || !pass || !clientCode) {
      alert("Please fill in all the required fields.");
      return; // Stop further execution
    }



    const transformedPayload1 = {
      UserId: selectedEmployee.Id,
      UserName: username,
      Password: pass,
      ClientCode: clientCode,
      Role: selectedEmployee.Designation,
      RoleId: selectedEmployee.RoleId,
      CustomRole: "Admin1",
      Modules: initialModulesData.map(page => ({
        PageId: page.PageId,
        PageName: page.PageName,
        PageDisplayName: page.PageDisplayName,
        PermissionId: page.PermissionId,
        PagePermission: page.PagePermission,
        PageControls: page.items.map(item => ({
          PageDisplayName: item.itemName,
          PagePermission: item.PagePermission,
          PageControls: item.PageControls.map(control => ({
            key: control.key,
            label: control.label,
            placeholder: control.placeholder,
            priority: control.priority,
            type: control.type,
            visibility: control.visibility,
            place: "middle" // Adjust if a specific place is needed
          }))
        }))
      }))
    };

    const transformedPayload = {
      UserId: selectedEmployee.Id,
      UserName: username,
      Password: pass,
      ClientCode: clientCode,
      Role: selectedRole.RoleName, // Pass RoleName
      RoleId: selectedRole.RoleId || '', // Pass RoleId
      CustomRole: customRole,
      modules: initialModulesData.flatMap(page =>
        page.items.map(item => ({
          PageId: item.PageId,
          PageName: item.path,
          PageDisplayName: item.itemName,
          PermissionId: item.PermissionId,
          PagePermission: item.PagePermission,
          PageControls: item.PageControls.map(control => ({
            key: control.key,
            label: control.label,
            placeholder: control.placeholder,
            priority: control.priority,
            type: control.type,
            visibility: control.visibility,
            place: control.place // Adjust if a specific place is needed
          }))
        }))
      )
    };

    console.log("check initialdta ", transformedPayload);

    try {
      const response = await fetch(a244, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // Ensure Content-Type is set to JSON
        },
        body: JSON.stringify(transformedPayload),
      });

      // Parse the response as JSON
      const data = await response.json();

      // Check if the response is successful
      if (response.ok) {
        console.log("Response data:", data); // Log the response


        // fetchemployeePermissions(clientCode,selectedEmployee.Id)
        fetchemployeePermissions(clientCode, selectedEmployee.Id).then((permissions) => {
          dispatch(setPermissions(permissions)); // Dispatch the permissions to Redux

        });
        alert("User permissions successfully saved.");
      } else {
        console.error("Error in response:", data); // Handle error response
        alert("Failed to save user permissions.");
      }
    } catch (error) {
      // Catch any network or request-related errors
      console.error("Request failed:", error);
      alert("There was an error with the request.");
    }

  };

  const fetchemployeePermissions = async (clientCode, employeeid) => {
    console.log('check clientcode ', clientCode, '   ', employeeid);

    try {
      const response = await fetch(a243, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ClientCode: clientCode
          ,
          EmployeeId: employeeid
        }),
      });
      if (!response.ok) {
        // Handle any non-200 responses
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      const permissions = data[0]?.Modules || []; // Access `Modules` within the response
      return permissions;
      // return data; // This should return an array of permissions
    } catch (error) {
      console.error("Error fetching permissions:", error);
      return [];
    }
  };



  // const toggleStatus = (index, employee) => {
  //   // const updatedEmployees = [...employees];
  //   // updatedEmployees[index].status = !updatedEmployees[index].status;
  //   // setEmployees(updatedEmployees);

  // };


  const toggleStatus = async (index, employee) => {
    // Toggle the StatusType of the employee

    if (employee.SuperAdmin === 1) {
      alert("Super admin can't be blocked")
      return
    }
    console.log('check employee ', employee);


    const updatedStatus = !employee.StatusType;

    // Update the employee status locally (optional, if you want to show the change immediately in UI)
    const updatedEmployees = [...employees];
    updatedEmployees[index].StatusType = updatedStatus;
    setEmployees(updatedEmployees);  // Assuming you have a setEmployees function from useState

    // Prepare the payload for the API call
    const payload = {
      ClientCode: "LS000065",  // Replace with actual ClientCode
      Id: employee.Id,         // Employee's unique ID
      StatusType: updatedStatus // The new status value (true or false)
    };

    try {
      // Call the API to update the employee status
      const response = await fetch(a245, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload), // Send the updated data in the request body
      });

      if (!response.ok) {
        throw new Error("Failed to update employee status");
      }

      // Handle response if necessary
      const data = await response.json();
      console.log("Status updated successfully:", data);

    } catch (error) {
      console.error("Error updating status:", error);
    }
  };





  const allStates = useSelector((state) => state);
  const adminLoggedIn = allStates.reducer1;
  //   let Entryby_Staff_id = parseInt(adminLoggedIn);
  // const clientCode = adminLoggedIn.ClientCode;
  console.log("clientCode ", clientCode);

  useEffect(() => {
    const fetchData = async () => {
      const t1 =
        "https://dev.loyalstring.co.in/api/RoleManagement/GetAllPageDetails";

      const t2 =
        "https://dev.loyalstring.co.in/api/RoleManagement/GetAllNewRolesDetails";
      try {
        const formData1 = {
          ClientCode: clientCode,
        };
        const response1 = await fetch(t1, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData1),
        });

        console.log("response1", response1);

        const data1 = await response1.json();

        console.log("data1", data1);

        const formData2 = {
          ClientCode: clientCode,
        };
        const response2 = await fetch(t2, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData2),
        });
        const data2 = await response2.json();

        console.log("data2", data2);
      } catch (e) {
        console.log(e);
      }
    };
    fetchData();
  }, []);



  //conditionally render the fields
  // useEffect(() => {
  //   const RenderFields = () => {
  //     if (pageDetails == "PurchaseEntry") {
  //       setItemfields(PurchaseEntryFeilds);
  //     } else if (pageDetails == "AddStock") {
  //       setItemfields(AddStockFields);
  //     } else if (pageDetails == "Wholesale") {
  //       setItemfields(WholeSale);
  //     }
  //   };

  //   RenderFields();
  // }, [pageDetails]);





  // const handleHeaderToggleChange = (header) => {
  //   event.stopPropagation();
  //   setCheckedHeaders((prevState) => ({
  //     ...prevState,
  //     [header]: !prevState[header],
  //   }));
  //   const isEnabled = !checkedHeaders[header];
  //   menuData
  //     .find((section) => section.heading === header)
  //     .items.forEach((item) => {
  //       setCheckedItems((prevState) => ({
  //         ...prevState,
  //         [item]: isEnabled,
  //       }));
  //     });
  // };

  // const handleSequenceChange = (item, value) => {
  //   setFieldSequence((prevState) => ({
  //     ...prevState,
  //     [item]: value,
  //   }));
  // };
  // const toggleVisibility = (key, visibilityValue) => {
  //   setItemfields((prevFields) =>
  //     prevFields.map((field) =>
  //       field.key === key
  //         ? {
  //           ...field,
  //           visibility: visibilityValue, // Set the visibility based on the selected checkbox
  //         }
  //         : field
  //     )
  //   );
  // };


  const toggleVisibility = (pageId, itemName, key, visibilityValue) => {
    setInitialModulesData((prevModulesData) =>
      prevModulesData.map((section) => {
        if (section.PageId !== pageId) return section;

        return {
          ...section,
          items: section.items.map((item) => {
            if (item.itemName !== itemName) return item;

            return {
              ...item,
              PageControls: item.PageControls.map((field) =>
                field.key === key
                  ? { ...field, visibility: visibilityValue }
                  : field
              ),
            };
          }),
        };
      })
    );
  };


  // const handleSequenceChange = (pageId, itemName, key, value) => {
  //   setInitialModulesData((prevModulesData) =>
  //     prevModulesData.map((section) => {
  //       if (section.PageId !== pageId) return section;

  //       return {
  //         ...section,
  //         items: section.items.map((item) => {
  //           if (item.itemName !== itemName) return item;

  //           return {
  //             ...item,
  //             PageControls: item.PageControls.map((field) =>
  //               field.key === key
  //                 ? { ...field, priority: value }  // Update priority when field key matches
  //                 : field
  //             ),
  //           };
  //         }),
  //       };
  //     })
  //   );
  // };

  const handleSequenceChange = (pageId, itemName, key, value) => {
    setInitialModulesData((prevModulesData) =>
      prevModulesData.map((section) => {
        if (section.PageId !== pageId) return section;

        return {
          ...section,
          items: section.items.map((item) => {
            if (item.itemName !== itemName) return item;

            // Find the place of the current field (top, middle, bottom)
            const currentField = item.PageControls.find((field) => field.key === key);
            const place = currentField ? currentField.place : null;

            // Check if priority value already exists in the same section (place)
            const isDuplicate = item.PageControls.some(
              (field) => field.place === place && field.priority === value && field.key !== key
            );

            // If a duplicate exists, prevent the update and return the item unchanged
            if (isDuplicate) {
              alert(`Priority ${value} already exists in the ${place} section. Please choose a unique priority.`);
              return item;
            }

            // If no duplicate, proceed with updating the priority
            return {
              ...item,
              PageControls: item.PageControls.map((field) =>
                field.key === key
                  ? { ...field, priority: value }  // Update priority when field key matches
                  : field
              ),
            };
          }),
        };
      })
    );
  };







  const handleItemClick = (item) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [item]: !prevState[item],
    }));


    if (item == "Purchase Entry") {
      const field = toPlainFields(PurchaseEntryFields)
      setItemfields(field)
      console.log('check clicked items', field);

    }
    if (item == "Add Bulk Stock New") {
      const field = toPlainFields(PurchaseEntryFields)
      setItemfields([])

    }




  };

  const handleHeaderClick = (header) => {
    event.stopPropagation();
    console.log('checkheader ', header);

    setExpandedHeaders((prevState) => ({
      ...prevState,
      [header]: !prevState[header],
    }));
  };
  // const handleToggleChange = (item) => {
  //   setCheckedItems((prevState) => ({
  //     ...prevState,
  //     [item]: !prevState[item],
  //   }));
  // };


  const handleHeaderToggleChange = (header) => {
    event.stopPropagation();
    const isEnabled = !checkedHeaders[header]; // Determine if the header is being enabled or disabled

    setCheckedHeaders((prevState) => ({
      ...prevState,
      [header]: isEnabled,
    }));

    // Find the section corresponding to the header and update PagePermission for all items
    setInitialModulesData((prevModulesData) =>
      prevModulesData.map((section) => {
        if (section.heading !== header) return section;

        return {
          ...section,
          items: section.items.map((item) => ({
            ...item,
            PagePermission: isEnabled ? 'enabled' : 'disabled',
          })),
        };
      })
    );

    // Update checkedItems state for all items in the section
    // menuData
    //   .find((section) => section.heading === header)
    //   .items.forEach((item) => {
    //     setCheckedItems((prevState) => ({
    //       ...prevState,
    //       [item.itemName]: isEnabled,
    //     }));
    //   });
  };

  const handleToggleChange = (itemName) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [itemName]: !prevState[itemName],
    }));

    const isEnabled = !checkedItems[itemName];

    // Update PagePermission for the specific item in initialModulesData
    // setInitialModulesData((prevModulesData) =>
    //   prevModulesData.map((section) => ({
    //     ...section,
    //     items: section.items.map((item) =>
    //       item.itemName === itemName
    //         ? { ...item, PagePermission: isEnabled ? 'enable' : 'disable' }
    //         : item
    //     ),
    //   }))
    // );


    setInitialModulesData((prevModulesData) =>
      prevModulesData.map((section) => ({
        ...section,
        items: section.items.map((item) =>
          item.itemName === itemName
            ? { ...item, PagePermission: item.PagePermission === 'enable' ? 'disable' : 'enable' }
            : item
        ),
      }))
    );


  };



  const handleUserSelect = (e) => {
    const selectedId = e.target.value;

    // Find the selected user in the employees array
    const selectedEmployee = employees.find(employee => employee.Id === parseInt(selectedId));


    const selectedRole = allpermissions.find(role => role.UserId === selectedEmployee.Id);

    if (selectedRole) {
      // If RoleId matches, show the alert
      alert("Employee has permission, please edit.");
      return;
    }
    setSelectedUser(selectedId);

    if (selectedEmployee) {
      setUserName(selectedEmployee.UserName);
      setPassword(selectedEmployee.Password);

    }
  };

  const handleSelectAll = (heading, itemName, pageControls, visibility) => {
    const updatedControls = pageControls.map((control) => ({
      ...control,
      visibility: visibility,
    }));




    // Update state or call a function to apply these changes
    updateVisibility(heading, itemName, updatedControls);
    console.log('check update  ', initialModulesData);
  };

  const updateVisibility = (heading, itemName, updatedControls) => {

    console.log('check initial ', initialModulesData);

    setInitialModulesData((prevModulesData) =>
      prevModulesData.map((section) => {
        if (section.heading === heading) {
          return {
            ...section,
            items: section.items.map((item) => {
              if (item.itemName === itemName) {

                return {
                  ...item,
                  PageControls: updatedControls,
                };
              }
              return item;
            }),
          };
        }
        return section;
      })
    );
  };


  const handlerolechange = (e) =>{

    const selectedRole = allrole.find(role => role.RoleId === parseInt(e.target.value));
      setSelectedRole({
        RoleId: selectedRole.RoleId,
        RoleName: selectedRole.RoleName
      });

      const employeePermissions = allpermissions.find(
        (permission) => permission.RoleId === selectedRole.RoleId
      );
      if (employeePermissions) {

        const updatedEmployeePermissions = ensureEmployeeFields(employeePermissions, fieldDefinitions);

        console.log("Updated Employee Permissions:", updatedEmployeePermissions);
        const transformedPermissions = transformPermissions(menuData, employeePermissions);


        console.log("Transformed Permissions:", transformedPermissions);
        setInitialModulesData(transformedPermissions)



      }
  }

  return (
    <div>
      <AdminTopNavbar />

      <div className="main-container">
        {/* <SideHeading /> */}

        <div className="right-box">
          <div className="right-box-header">
            <h1
              style={{
                fontSize: "15px",
                color: "#000",
                fontWeight: "bold",
                borderBottom: "2px solid #00B4B8",
                display: "inline-block",
                paddingBottom: "4px",
              }}
            >
              User Management
            </h1>
            <h1
              style={{
                fontSize: "15px",
                color: "#000",
                fontWeight: "bold",
                borderBottom: "2px solid #00B4B8",
                display: "inline-block",
                paddingBottom: "4px",
              }}
            >
              Your subscription is for 3 users
            </h1>
          </div>

          <div className="right-box-body">
            <div className="right-box-body-head">
              <div style={{ width: "97%" }}>
                <div className="dropdown-container">
                  <div className="dropdown-item">
                    <label htmlFor="selectUser">Select User</label>
                    <select
                      id="selectUser"
                      value={selectedUser}
                      onChange={handleUserSelect}
                    >
                      <option value="" disabled>
                        Choose a user
                      </option>
                      {employees.map((employee) => (
                        <option key={employee.EmployeeCode} value={employee.Id}>
                          {employee.FirstName} {employee.LastName} - {employee.Designation}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="dropdown-item">
                    <label htmlFor="userName">User Name</label>
                    <input
                      type="text"
                      id="userName"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                    />
                  </div>

                  <div className="dropdown-item">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>

                  <div className="dropdown-item">
                    <label htmlFor="roles">Role</label>
                    <select
                      id="roles"
                      value={selectedRole.RoleId}
                      onChange={(e) => {handlerolechange(e)}
                        
                      //   {
                      //   const selectedRole = allrole.find(role => role.RoleId === parseInt(e.target.value));
                      //   setSelectedRole({
                      //     RoleId: selectedRole.RoleId,
                      //     RoleName: selectedRole.RoleName
                      //   });
                      // }
                    
                    }
                    >
                      <option value="" disabled>Select a role</option>
                      {allrole.map((role) => (
                        <option key={role.RoleId} value={role.RoleId}>
                          {role.RoleName}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="dropdown-item">
                    <label htmlFor="customRole">Custom Role</label>
                    <input
                      id="customRole"
                      type="text"
                      value={customRole}
                      onChange={(e) => setCustomRole(e.target.value)}
                      placeholder="Type custom role here"
                    />
                  </div>
                  <div></div>
                </div>

                <div style={{ display: "flex", justifyContent: "right" }}>
                  <button onClick={handleSave}>Save</button>
                </div>
                {/* <h1 style={{fontSize:  '14px', marginTop: '10px'}}>Users List</h1> */}
                {/* <div className="employee-table-container">
                  <table className="employee-table">
                    <thead>
                      <tr>
                        <th>Sr No.</th>
                        <th>Employee Name</th>
                        <th>User Name</th>
                        <th>Password</th>
                        <th>Role</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employees.map((employee, index) => (
                        <tr key={index}>
                          <td>{employee.Id}</td>
                          <td>{employee.FirstName}</td>
                          <td>{employee.UserName}</td>
                          <td>{employee.password}</td>
                          <td>{employee.Designation}</td>
                          <td>
                            <button onClick={() => toggleStatus(index)}>
                              {employee.status ? "Active" : "Deactive"}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div> */}
                <div className="employee-table-container">
                  <table className="employee-table">
                    <thead>
                      <tr>
                        <th>Sr No.</th>
                        <th>Employee Name</th>
                        <th>User Name</th>
                        <th>Password</th>
                        <th>Role</th>
                        <th>Status</th>
                        <th>Edit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employees.map((employee, index) => (
                        <tr key={employee.Id}>
                          <td>{index + 1}</td>
                          <td>{employee.FirstName} {employee.LastName}</td>
                          <td>{employee.UserName}</td>
                          <td>{employee.password}</td>
                          <td>{employee.Designation}</td>
                          <td>
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={employee.StatusType}
                                onChange={() => toggleStatus(index, employee)}
                              />
                              <span className="slider round"></span>
                            </label>
                          </td>
                          <td>
                            <FaEdit
                              onClick={() => handleEdit(employee)}
                              style={{ cursor: "pointer", color: "blue" }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <VerticalMenu
              menuData={menuData}
              itemfields={itemfields}
              toggleVisibility={toggleVisibility}
              handleHeaderToggleChange={handleHeaderToggleChange}
              expandedHeaders={expandedHeaders}
              checkedHeaders={checkedHeaders}
              handleHeaderClick={handleHeaderClick}
              expandedItems={expandedItems}
              checkedItems={checkedItems}
              handleItemClick={handleItemClick}
              handleToggleChange={handleToggleChange}
              fieldSequence={fieldSequence}
              handleSequenceChange={handleSequenceChange}
              initialModulesData={initialModulesData}
              handleSelectAll={handleSelectAll}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
