import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import AdminHeading from "../Heading/AdminHeading";
import AdminBreadCrump from "../Heading/AdminBreadCrump";
import AlertMessage from "../../../Other Functions/AlertMessage";
import '../../PagesStyles/Addstock.css'
import { useSelector } from "react-redux";
import {
  a125,
  a128,
  a131,
  a134,
  a137,
  a146,
  a149,
  a152,
  a153,
  a16,
  a162,
  a163,
  a170,
  a175,
  a176,
  a177,
  a178,
  a18,
  a181,
  a191,
  a194,
  a198,
  a199,
  a20,
  a217,
  a22,
  a226,
  a24,
  a28,
  a30,
  a31,
  a33,
  a41,
  a43,
  a47,
  a55,
  a57,
  a71,
  a8,
  a90,
  a98,
} from "../../../Api/RootApiPath";
import QRCode from "qrcode";
import jsPDF from "jspdf";
import { InfinitySpin } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineEdit, AiOutlineFileAdd } from "react-icons/ai";
import { BiSave, BiListUl } from "react-icons/bi";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { BsImages } from "react-icons/bs";
import { IoIosAddCircleOutline, IoMdAddCircleOutline } from "react-icons/io";
import { CiImport } from "react-icons/ci";
import { GenerateLabel } from "../../../Other Functions/GenerateLabel";
import { color } from "chart.js/helpers";
import { Grid } from "@mui/material";
import GetApiService from "../../../Api/getapiService";
import { ClipLoader } from "react-spinners";
import ErrorModal from "../../../Other Functions/popup";
import { FaPlus, FaTrash } from 'react-icons/fa';
import { TableContainer } from "@mui/material";
import { AddStockFields } from "Components/support/Allfields/addstock";
import { toPlainFields } from "Components/support/Allfields/fieldconverter";
import StonePopup from "Components/support/purchasesupport/StonePopup";
import Diamondpopup from "Components/support/purchasesupport/diamondpopup1";



export default function AdminAddBulkStockNew() {

  const thStyle = {
    padding: '5px',
    border: '1px solid #ccc',
    textAlign: 'center',
    fontWeight: '600',
  };

  const tdStyle = {
    padding: '5px',
    border: '1px solid #ccc',
    textAlign: 'center',
  };

  const inputStyle = {
    width: '100%',
    padding: '3px',
    margin: '0',
    boxSizing: 'border-box',
    borderRadius: '4px',
    border: '1px solid #ccc',
    minWidth: '50px'
  };


  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [diamondTemplateId, setDiamondTemplateId] = useState(null);
  const [qr, setQr] = useState("");
  const [productName, setProductName] = useState("");
  const [allProducts, setAllProducts] = useState([]);
  //   const [categoryId, setCategoryId] = useState("");
  const [pieces, setPieces] = useState(1);
  const [huid, setHuid] = useState("");
  const [netWt, setNetWt] = useState(0);
  const [size, setSize] = useState(0);
  const [hallmark, setHallmark] = useState("");
  const [hallmarkAmount, setHallmarkAmount] = useState("0");
  const [grosswt, setGrosswt] = useState(0);
  const [purity, setPurity] = useState("");
  const [collection, setCollection] = useState("");
  const [collectionmain, setCollectionmain] = useState("");
  const [occasion, setOccasion] = useState("");
  const [gender, setGender] = useState("");
  const [description, setDescription] = useState("");
  // const [productTypeId, setProductTypeId] = useState("");
  const [partyTypeId, setPartyTypeId] = useState("");
  const [VendorName, setVendorName] = useState('')
  const [boxId, setBoxId] = useState(0);
  const [making_per_gram, setMaking_per_gram] = useState(0);
  const [making_Fixed_Amt, setMaking_Fixed_Amt] = useState(0);
  const [making_Percentage, setMaking_Percentage] = useState(0);
  const [making_Fixed_Wastage, setMaking_Fixed_Wastage] = useState(0);
  const [stoneWeight, setStoneWeight] = useState(0);
  const [clipWeight, setClipWeight] = useState(0);
  const [stoneAmount, setStoneAmount] = useState(0);
  const [weights, setWeights] = useState(0);
  const [featured, setFeatured] = useState("");
  const [productCode, setProductCode] = useState("");
  const [mrp, setMRP] = useState(0);
  const [itemCode, setItemCode] = useState("");
  const [itemType, setItemType] = useState("");
  const [quantity, setQuantity] = useState(1);
  const [finePerc, setFinePerc] = useState("0");
  const [wastagePerc, setWastagePerc] = useState("0");
  const [fineWastagePerc, setFineWastagePerc] = useState("0");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  const [category, setCategory] = useState("");
  const [baseMetal, setBaseMetal] = useState("");
  const [productTypeData, setProductTypeData] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);
  const [purityData, setPurityData] = useState([]);
  const [partyData, setPartyData] = useState([]);
  const [boxData, setBoxData] = useState([]);
  const [collectionTypeData, setCollectionTypeData] = useState([]);
  const [collectionmainlist, setCollectionmainlist] = useState([]);
  const [allLabelledStockData, setAllLabelledStockData] = useState([]);
  const [addedProducts, setAddedProducts] = useState([]);
  const [parameter, setParameter] = useState("");
  const [formValue, setFormValue] = useState("");
  const [productType, setProductType] = useState("");
  const [boxType, setBoxType] = useState("");
  const [productInEdit, setProductInEdit] = useState([]);
  const [productInEditImages, setProductInEditImages] = useState();
  const [updatedProducts, setUpdatedProducts] = useState([]);
  const [showImportPopup, setShowImportPopup] = useState(false);
  const [importFile, setImportFile] = useState([]);

  const [loadingTop, setLoadingTop] = useState(true);
  const [loadingAdd, setLoadingAdd] = useState(true);

  const [goldAlert, setGoldAlert] = useState(false);
  const [barCodeAlert, setBarCodeAlert] = useState(false);
  const [importAlert, setImportAlert] = useState(false);
  const [isLooseDiamond, setIsLooseDiamond] = useState(false);
  const [firebaseData, setFirebaseData] = useState({});
  const [isMounted, setIsMounted] = useState(false);
  const [rifdData, setRifdData] = useState([]);
  const [checkedProducts, setCheckedProducts] = useState([]);
  const [selectedItemCodes, setSelectedItemCodes] = useState([]);
  const [allItemCodesArray, setAllItemCodesArray] = useState([]);
  const [deleteAll, setDeleteAll] = useState(false);
  const [deleteSelected, setDeleteSelected] = useState(false);
  const [showAllFields, setShowAllFields] = useState(false);
  const [showAllFields2, setShowAllFields2] = useState(false);
  const [stockType, setStockType] = useState("Labelled");
  const [branch, setBranch] = useState("");
  const [lotNumber, setLotNumber] = useState(0);
  const [packetNumber, setPacketNumber] = useState(0);

  // new logic for barcode and tid below
  const [barcodeNumbersArray, setBarcodeNumbersArray] = useState([]);
  const [piecesBox, setPiecesBox] = useState(false);
  const [productPiecesEditId, setProductPiecesEditId] = useState(0);
  const [halfInputs, setHalfInputs] = useState(true);
  const [allPurchaseItems, setAllPurchaseItems] = useState([]);
  const [allFilteredPurchaseItems, setAllFilteredPurchaseItems] = useState([]);
  const [allPacketNumbers, setAllPacketNumbers] = useState([]);
  // const [barcodeNumbersArray, setBarcodeNumbersArray] = useState([]);

  const [allSku, setAllSku] = useState([]);
  const [allSelectedSkuStones, setAllSelectedSkuStones] = useState([]);
  const [selectedSkuStones, setSelectedSkuStones] = useState([]);
  const [selectedweights, setSelectedweights] = useState([]);
  const [allSelectedSkuDiamonds, setAllSelectedSkuDiamonds] = useState([]);
  const [selectedSkuDiamonds, setSelectedSkuDiamonds] = useState([]);

  const [diamondSize, setDiamondSize] = useState("");
  const [diamondWeight, setDiamondWeight] = useState("0");
  const [diamondSleve, setDiamondSleve] = useState("0");
  const [diamondPurchaseRate, setDiamondPurchaseRate] = useState("0");
  const [diamondSellRate, setDiamondSellRate] = useState("0");
  const [diamondClarity, setDiamondClarity] = useState("");
  const [diamondColour, setDiamondColour] = useState("");
  const [diamondShape, setDiamondShape] = useState("");
  const [diamondCut, setDiamondCut] = useState("");
  const [diamondQty, setDiamondQty] = useState("");
  const [diamondTotalWeight, setDiamondTotalWeight] = useState("");
  const [diamondSettingType, setDiamondSettingType] = useState("");
  const [diamondCertificate, setDiamondCertificate] = useState("");
  const [diamondPieces, setDiamondPieces] = useState("1");
  const [diamondPurchaseAmount, setDiamondPurchaseAmount] = useState("0");
  const [diamondTotalAmount, setDiamondTotalAmount] = useState("0");
  const [diamondDescription, setDiamondDescription] = useState("");

  const [selectedProductIndex, setSelectedProductIndex] = useState(null);
  const [allDiamondSizeWeightRate, setAllDiamondSizeWeightRate] = useState([]);
  const [allDiamondAttributes, setAllDiamondAttributes] = useState([]);
  const [newStonesList, setNewStonesList] = useState([]);
  const [newDiamondsList, setNewDiamondsList] = useState([]);
  const [allStonesList, setAllStonesList] = useState([]);
  const [allStonesListmain, setAllStonesListmain] = useState([]);
  const [allDiamondsList, setAllDiamondsList] = useState([]);
  const [showAddStoneBox, setShowAddStoneBox] = useState(false);
  const [showAddDiamondBox, setShowAddDiamondBox] = useState(false);
  const [grossWithClip, setGrossWithClip] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [branchOption, setBranchOption] = useState([]);
  const [showDiamondBtn, setShowDiamondBtn] = useState(false);
  const [mainpieces, setmainpieces] = useState("");
  const [addStone, setAddStone] = useState({
    StoneName: "",
    StoneWeight: 0,
    StonePieces: 0,
    StoneRate: 0,
    StoneAmount: 0,
    Description: "",
    ClientCode: "",
    EmployeeCode: "",
    ProductId: 0,
  });
  const [addDiamond, setAddDiamond] = useState({
    DiamondName: "",
    DiamondWeight: 0,
    DiamondRate: 0,
    DiamondPieces: 0,
    DiamondClarity: "",
    DiamondColour: "",
    DiamondCut: "",
    DiamondShape: "",
    DiamondSize: 0,
    Certificate: "",
    SettingType: "",
    DiamondAmount: 0,
    DiamondPurchaseAmt: 0,
    Description: "",
  });

  const [filteredsku, setFilteredsku] = useState([]);
  const [filteredparty, setFilteredparty] = useState([]);
  const [filteredlot, setFilteredlot] = useState([]);
  const [selectedSku, setSelectedSku] = useState([]);
  const [selectedSkuName, setSelectedSkuName] = useState("");
  const [unlabeledgold, setUnlabeledgold] = useState(0);
  const [unlabeledqty, setUnlabeledqty] = useState(0);
  const [groupproducts, setGroupedProducts] = useState([]);
  const [expandedGroups, setExpandedGroups] = useState({}); // To track which groups are expanded
  const [groupindex, setGroupindex] = useState();
  const [itemindex, setItemindex] = useState();


  const allStates = useSelector((state) => state);
  const adminLoggedIn = allStates.reducer1;
  //   let Entryby_Staff_id = parseInt(adminLoggedIn);
  const clientCode = adminLoggedIn.ClientCode;
  const employeeCode = adminLoggedIn.EmployeeCode;
  const CompanyId = adminLoggedIn.CompanyId;
  const BranchId = adminLoggedIn.BranchId;
  const CounterId = adminLoggedIn.CounterId;
  const labelFormat = parseInt(adminLoggedIn.Clients.LabelFormat);

  const apiService = new GetApiService(clientCode);

  const loadData = async () => {
    setLoading(true);
    try {
      const apiCalls = [
        apiService.fetchAllCategories(),
        apiService.fetchAllBranches(),
        apiService.fetchAllProductType(),
        apiService.fetchAllPurities(),
        apiService.fetchAllCustomers(),
        apiService.fetchAllDesigns(),
        apiService.fetchAllBoxs(),
        apiService.fetchAllRdPurchaseItems(),
        apiService.fetchAllPacketNumbers(),
        apiService.fetchAllSku(),
        apiService.fetchAllDiamondSizeWeightRate(),
        apiService.fetchAllDiamondAttributes(),
        apiService.fetchAllLabelledStock(),
        apiService.fetchAllCollection(),
      ];

      const results = await Promise.allSettled(apiCalls);

      results.forEach((result, index) => {
        if (result.status === "fulfilled") {
          // Handle successful response
          switch (index) {
            case 0:
              console.log("checking category ", result);
              if (Array.isArray(result.value)) {
                // Check if the first item's CategoryName is 'Gold'
                if (
                  result.value.length > 0 &&
                  result.value[0].CategoryName !== "GOLD"
                ) {
                  setCategoriesData(result.value.reverse());
                } else {
                  // Set data as-is (without reversing) if the first item is 'Gold'
                  setCategoriesData(result.value);
                }
              } else {
                setErrorMessage(
                  "Error: Unexpected response format for Categories."
                );
              }
              break;
            case 1:
              setBranchOption(result.value);
              if (result.value.length > 0 && !branch) {
                setBranch(result.value[0].BranchName);
              }
              break;
            case 2:
              setProductTypeData(result.value);
              break;
            case 3:
              setPurityData(result.value);
              break;
            case 4:
              setPartyData(result.value);
              setFilteredparty(result.value);
              break;
            case 5:
              setCollectionTypeData(result.value);
              break;
            case 6:
              setBoxData(result.value);
              break;
            case 7:
              setAllPurchaseItems(result.value);
              setAllFilteredPurchaseItems(result.value);



              break;
            case 8:
              setAllPacketNumbers(result.value);
              break;
            case 9:
              setAllSku(result.value);
              setFilteredsku(result.value);



              break;
            case 10:
              setAllDiamondSizeWeightRate(result.value);
              console.log('check all diamond', result.value)
              break;
            case 11:
              setAllDiamondAttributes(result.value);
              console.log("checking attributes  ", result.value);
              break;
            case 12:
              setAllLabelledStockData(result.value);


              break;
            case 13:
              setCollectionmainlist(result.value);

              console.log("check allcollections ", result.value);
              break;
            default:
              break;
          }
        } else {
          if (index + 1 > 1) {
            console.error(
              `Error loading data for API ${index + 1}:`,
              result.reason
            );
            handleError(
              `Failed to load data for API ${index + 1}: ${result.reason}`
            );
          }
        }
      });
    } catch (error) {
      console.error("Error loading data:", error);
      handleError("Error loading data. Please try again later.");
    } finally {
      setLoading(false);
      setLoadingTop(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [clientCode]);




  const apifields = useMemo(
    () => adminLoggedIn.Modules.filter((module) => module.PageName === "add_bulk_stock_new"),
    [adminLoggedIn]
  );

  const localfields = useMemo(() => toPlainFields(AddStockFields), []);
  const apifieldsControls = useMemo(() => apifields[0]?.PageControls || [], [apifields]);

  const [itemfields, setItemfields] = useState(() => {
    const updatedFields = localfields.map((localField) => {
      const matchingApiField = apifieldsControls.find(
        (apiField) => apiField.key === localField.key
      );
      if (matchingApiField) {
        return {
          ...localField,
          label: matchingApiField.label,
          placeholder: matchingApiField.placeholder,
          priority: matchingApiField.priority,
          type: localField.type,
          visibility: matchingApiField.visibility,
          place: matchingApiField.place,
        };
      }
      return localField;
    });

    console.log('check update fields ', updatedFields);

    return updatedFields;
  });

  // useEffect(() => {
  //   if (apifieldsControls.length > 0) {  
  //     setItemfields((prevItemfields) => {
  //       const updatedFields = localfields.map((localField) => {
  //         const matchingApiField = apifieldsControls.find(
  //           (apiField) => apiField.key === localField.key
  //         );
  //         if (matchingApiField) {
  //           return {
  //             ...localField,
  //             label: matchingApiField.label,
  //             placeholder: matchingApiField.placeholder,
  //             priority: matchingApiField.priority,
  //             type: matchingApiField.type,
  //             visibility: matchingApiField.visibility,
  //             place: matchingApiField.place,
  //           };
  //         }
  //         return localField;
  //       });

  //       // Avoid duplicates by merging only distinct fields
  //       const uniqueFields = updatedFields.filter(
  //         (updatedField, index, self) =>
  //           index === self.findIndex((f) => f.key === updatedField.key)
  //       );

  //       console.log('check fields', uniqueFields);


  //       return uniqueFields;
  //     });
  //   }
  // }, [apifieldsControls, localfields]); 





  // Sort columns by priority
  // const sortedColumns = columns.sort((a, b) => a.priority - b.priority);


  // Function to get unique Lot Numbers
  const getUniqueLotNumbers = (items) => {
    if (!Array.isArray(items)) return [];
    const lotSet = new Set(); // Set to store unique lot numbers
    return items.filter((item) => {
      if (!lotSet.has(item.LotNumber)) {
        lotSet.add(item.LotNumber); // Add lot number to set if it's unique
        return true; // Keep this item in the filtered array
      }
      return false; // Ignore duplicate lot numbers
    });
  };

  // Assuming `allFilteredPurchaseItems` is already available
  const uniqueLotNumbers = getUniqueLotNumbers(allFilteredPurchaseItems);


  const handleError = (message) => {
    setErrorMessage(message);
    setShowModal(true); // Open the modal
  };

  useEffect(() => {
    // partyData
    // setFilteredparty(partyData);
    // setFilteredsku(allSku)


    let filteredItems = allPurchaseItems;
    console.log('check all purchasae items', allPurchaseItems);

    // case 1
    // Filter based on selected Lot Number, but only if `lotNumber` is not empty


    let totalPurchaseGrossWt = 0;
    let totalPurchaseQty = 0; // Add totalPurchaseQty

    if (lotNumber && lotNumber !== "0") {
      filteredItems = filteredItems.filter(
        (item) => item.LotNumber === lotNumber
      );

      let vendorid = filteredItems[0].VendorName;

      const fparty = filteredparty.filter(
        (item) => item.VendorName == vendorid
      );

      let labelledStockItems = allLabelledStockData.filter(item => item.LotNumber === lotNumber);

      if (selectedSkuName && selectedSkuName.trim() !== "") {

        filteredItems = filteredItems.filter(
          (item) => item.StockKeepingUnit === selectedSkuName
        );
        labelledStockItems = labelledStockItems.filter(item => item.SKU === selectedSkuName);
        totalPurchaseGrossWt = filteredItems.reduce((acc, item) => {
          return acc + parseFloat(item.GrossWt || 0);
        }, 0);
        totalPurchaseQty = filteredItems.reduce((acc, item) => {
          return acc + parseFloat(item.Quantity || 0);
        }, 0);

      } else {
        // StockKeepingUnit = ""
        const filteredItems1 = filteredItems.filter(
          (item) => item.StockKeepingUnit === ""
        );
        labelledStockItems = labelledStockItems.filter(item => item.SKU === "");
        totalPurchaseGrossWt = filteredItems1.reduce((acc, item) => {
          return acc + parseFloat(item.GrossWt || 0);
        }, 0);
        totalPurchaseQty = filteredItems1.reduce((acc, item) => {
          return acc + parseFloat(item.Quantity || 0);
        }, 0);
      }
      const skuToSet = allSku.filter((sku) =>
        filteredItems.some(item => item.StockKeepingUnit === sku.StockKeepingUnit)
      );

      console.log('checking labellist ', labelledStockItems)

      console.log('checking selected sku ', skuToSet)
      console.log('checking filtered items ', filteredItems)
      console.log('checking all sku ', allSku)



      // Filter labelled stock data based on selected LotNumber
      // const labelledStockItems = allLabelledStockData.filter(item => item.LotNumber === lotNumber);

      // Calculate total gross weight from labelled stock for the given LotNumber
      const totalLabelledGrossWt = labelledStockItems.reduce((acc, item) => {
        return acc + parseFloat(item.GrossWt || 0);
      }, 0);
      const totalLabelledQty = labelledStockItems.reduce((acc, item) => {
        return acc + parseFloat(item.ClipQuantity || 1);
      }, 0);


      // Calculate unlabelled gross weight (purchase gross wt - labelled gross wt)
      const unlabelledGrossWt = totalPurchaseGrossWt - totalLabelledGrossWt;
      const unlabelledQty = totalPurchaseQty - totalLabelledQty;
      setUnlabeledgold(unlabelledGrossWt || 0)
      setUnlabeledqty(unlabelledQty || 0);

      console.log('Total Purchase GrossWt:', totalPurchaseGrossWt);
      console.log('Total Labelled GrossWt:', totalLabelledGrossWt);
      console.log('Unlabelled GrossWt:', unlabelledGrossWt);
      console.log('Unlabelled qty:', unlabelledQty);


      setFilteredsku(skuToSet);
      setFilteredparty(filteredItems);
      setPartyTypeId(filteredItems[0].Id)







    } else if (selectedSkuName && selectedSkuName.trim() !== "") {

      filteredItems = filteredItems.find(
        (sku) => sku.StockKeepingUnit === selectedSkuName
      );
      if (filteredItems) {
        filteredItems = filteredItems
      } else {
        filteredItems = allPurchaseItems;
        setFilteredsku(allSku);
      }


      // setFilteredsku(allSku);
      // setFilteredparty(partyData);
    } else {
      setFilteredsku(allSku);
      setFilteredparty(partyData);
    }



    console.log('checking filtered purchase', filteredItems)

    // Set the filtered items
    setAllFilteredPurchaseItems(filteredItems);


    // if (partyTypeId && partyTypeId !== 0) {
    //   filteredItems = filteredItems.filter(
    //     (item) => item.VendorId === parseInt(partyTypeId)
    //   );
    // }

    console.log("checking partyTypeId", partyTypeId);
    setAllFilteredPurchaseItems(filteredItems);
  }, [partyTypeId, selectedSkuName, lotNumber, allPurchaseItems]);

  // useEffect(()=>{

  //   if(selectedSkuName){
  //     const allFilteredPurchaseItemsList = allPurchaseItems.filter(
  //       (x) => x.SKUId === selectedSku.Id
  //     );
  //     setAllFilteredPurchaseItems(allFilteredPurchaseItemsList);
  //   }else{
  //     setAllFilteredPurchaseItems(allPurchaseItems);
  //   }

  // }, [partyTypeId, selectedSkuName, lotNumber])

  // useEffect(() => {
  //   let filteredItems = allPurchaseItems;

  //   // Filter based on partyTypeId
  //   if (partyTypeId) {
  //     const selectedPartyVendorCode = partyData
  //       .filter(party => party.Id === partyTypeId)
  //       .map(party => party.VendorCode)
  //       .join(",");

  //     filteredItems = filteredItems.filter(item => selectedPartyVendorCode.includes(item.VendorId));
  //   }

  //   // Filter based on selectedSkuName
  //   if (selectedSkuName) {
  //     const selectedSku = allSku.find(sku => sku.StockKeepingUnit === selectedSkuName);
  //     if (selectedSku) {
  //       filteredItems = filteredItems.filter(item => item.SKUId === selectedSku.Id);
  //     }
  //   }

  //   // Filter based on lotNumber
  //   if (lotNumber) {
  //     filteredItems = filteredItems.filter(item => item.LotNumber === lotNumber);
  //   }

  //   // Set the filtered items based on the selected filters
  //   setAllFilteredPurchaseItems(filteredItems);

  // }, [partyTypeId, selectedSkuName, lotNumber, allPurchaseItems, allSku, partyData]);

  const reloadData = () => {
    setShowModal(false); // Close the modal
    loadData(); // Reload data
  };

  const useWarnIfUnsavedChanges = (hasUnsavedChanges) => {
    const navigate = useNavigate();

    const customNavigate = useCallback(
      (to, options = {}) => {
        if (
          hasUnsavedChanges &&
          !window.confirm(
            "You have unsaved changes. Are you sure you want to leave?"
          )
        ) {
          // User does not confirm navigation, do nothing.
          return;
        }

        // No unsaved changes or user confirmed, proceed with navigation.
        navigate(to, options);
      },
      [hasUnsavedChanges, navigate]
    );

    return customNavigate;
  };
  const customNavigate = useWarnIfUnsavedChanges(hasUnsavedChanges);

  const handlePiecesChange = (value, idRcvd, close) => {
    const updatedProducts = addedProducts.map((product) => {
      if (product.id === idRcvd) {
        const arrayOfObjects = [];

        for (let i = 0; i < value; i++) {
          const object = {
            key: `value${i + 1}`,
          };
          arrayOfObjects.push(object);
        }

        setBarcodeNumbersArray(arrayOfObjects);
      }
    });
  };

  const closePiecesEditBox = () => {
    const updatedProducts = addedProducts.map((product) => {
      if (product.id === productPiecesEditId) {
        return {
          ...product,
          pieces: 1,
        };
      }
      return product;
    });
    setAddedProducts(updatedProducts);
    setPiecesBox(false);
  };
  const handleBarcodeNumberChange = (newValue, index) => {
    // Convert the barcode number to uppercase
    const uppercaseBarcodeNumber = newValue.toUpperCase();

    // Find a matching product in the rifdData array based on uppercaseBarcodeNumber
    const matchingProduct = rifdData.find(
      (item) => item.barcodeNumber === uppercaseBarcodeNumber
    );

    setBarcodeNumbersArray((prevBarcodeNumbersArray) => {
      const updatedArray = [...prevBarcodeNumbersArray];

      if (uppercaseBarcodeNumber.trim() === "") {
        // If barcode number is empty, update the array with an empty object
        updatedArray[index] = {};
      } else if (matchingProduct) {
        // If a matching product is found, update tid with the matching product's tid value
        const updatedItem = {
          [uppercaseBarcodeNumber]: matchingProduct.tid,
        };
        updatedArray[index] = updatedItem;
      } else {
        // If no matching product is found, set tid to an empty string
        const updatedItem = {
          [uppercaseBarcodeNumber]: "",
        };
        updatedArray[index] = updatedItem;
      }

      return updatedArray;
    });
  };
  let barcodeNumberString = "";
  let tidNumberString = "";
  const handleCheckTidValues = () => {
    // Check if all tid values are non-empty, unique, and do not include the word 'value'
    const uniqueTidValues = new Set(
      barcodeNumbersArray.map((item) => {
        const tidValue = Object.values(item)[0];
        return tidValue !== null &&
          tidValue !== "" &&
          !tidValue.toLowerCase().includes("value")
          ? tidValue
          : null;
      })
    );

    // Check if all barcode numbers are unique and do not include their key names
    const allBarcodeNumbersValid = barcodeNumbersArray.every((item) => {
      const barcodeNumber = Object.keys(item)[0];
      const tidValue = Object.values(item)[0];
      return (
        barcodeNumber !== tidValue &&
        !barcodeNumber.toLowerCase().includes("key")
      );
    });

    if (
      uniqueTidValues.size === barcodeNumbersArray.length &&
      allBarcodeNumbersValid
    ) {
      // Generate barcodeNumberString and tidNumberString
      const barcodeNumberString = Array.from(
        barcodeNumbersArray.map((item) => Object.keys(item)[0])
      ).join(","); // Join barcode numbers with commas
      const tidNumberString = Array.from(uniqueTidValues).join(","); // Join unique tid values with commas

      // Now you can use barcodeNumberString and tidNumberString as needed
      // console.log("barcodeNumberString:", barcodeNumberString);
      // console.log("tidNumberString:", tidNumberString);

      // Search for the product in addedProducts array with matching id and update barcodeNumber and tid
      const updatedProducts = addedProducts.map((product) => {
        if (product.id === productPiecesEditId) {
          return {
            ...product,
            barcodeNumber: barcodeNumberString,
            tid: tidNumberString,
          };
        }
        return product;
      });

      // Set the state with updated products
      setAddedProducts(updatedProducts);
      setPiecesBox(false);
    } else {
      if (uniqueTidValues.size !== barcodeNumbersArray.length) {
        alert(
          "Not all tid values are non-empty, unique, or contain the word 'value'."
        );
      }

      if (!allBarcodeNumbersValid) {
        alert("Invalid barcode numbers.");
      }
    }
  };

  // console.log("barcodeNumberString:", barcodeNumberString);
  // console.log("tidNumberString:", tidNumberString);
  // console.log("barcodeNumberString:", barcodeNumberString);
  // console.log("tidNumberString:", tidNumberString);
  useEffect(() => {
    if (!piecesBox) {
      setBarcodeNumbersArray([]);
    }
  }, [piecesBox]);
  // console.log(addedProducts, "addedProducts for barcode");
  // console.log(barcodeNumbersArray, "barcodeNumbersArray");
  // new logic for barcode and tid above

  let Entryby_Staff_id = parseInt(adminLoggedIn);

  const scrollToCenter = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    // console.log("scroll");
  };

  let categoryId = parseInt(category);
  let categoryName = categoriesData.filter((x) => x.Id == parseInt(category))[0]
    ?.CategoryName;
  let productTypeId = parseInt(productType.split(",")[0]) || 0;
  let productTypeName = productType.split(",")[1];
  let collectionId = parseInt(collection.split(",")[0]) || 0;

  let bid = parseInt(boxType.split(",")[0]) || 0;
  let bname = boxType.split(",")[1];

  let collectionName = collection.split(",")[1];
  let collectionmainId = 0;
  let collectionmainName = "";

  if (collectionmain && collectionmain.includes(",")) {
    collectionmainId = parseInt(collectionmain.split(",")[0]) || 0;
    collectionmainName = collectionmain.split(",")[1] || "";
  }
  // let collectionmainId = parseInt(collectionmain.split(",")[0]) || 0;
  // let collectionmainName = collectionmain.split(",")[1];
  let purityId = parseInt(purity.split(",")[0]) || 0;
  let purityName = purity.split(",")[1];
  let partyId = parseInt(partyTypeId);
  let partyName = partyTypeId;
  let metalName = categoriesData.filter((x) => x.Id === parseInt(baseMetal))[0]
    ?.CategoryName;

  const handleFileInputChange = (event) => {
    const files = event.target.files;
    if (5 > files.length > 0) {
      const newFiles = Array.from(files).slice(0, 5 - selectedFiles.length);
      setSelectedFiles([...selectedFiles, ...newFiles]);
    }
  };

  useEffect(() => {
    const formData = {
      ClientCode: clientCode,
    };
    fetch(a175, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((data) => setRifdData(data));
    setLoadingAdd(true);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    let totalStoneAmount =
      selectedSkuStones.length > 0
        ? selectedSkuStones.reduce(
          (a, b) =>
            a +
            (
              parseFloat(b.StoneAmount) * parseFloat(selectedSku.Pieces)
            ).toFixed(2),
          0
        )
        : 0;
    setLoading(true);
    let formData = new FormData();

    formData.append("ProductTitle", productName);
    formData.append("CategoryId", categoryId);
    // formData.append("Category_Name", categoryName);
    formData.append("ProductId", parseInt(productTypeId));
    formData.append("DesignId", parseInt(collectionId));
    formData.append("CollectionId", parseInt(collectionmainId));
    formData.append("VendorId", parseInt(partyId));
    formData.append("SupplierId", parseInt(partyId));
    // formData.append("Party_Details", partyName);
    // formData.append("purity", purityName);
    formData.append("PurityId", purityId);
    // formData.append("BoxId", parseInt(bid));
    formData.append("MRP", parseFloat(mrp));
    formData.append("Quantity", parseInt(quantity));
    formData.append("GrossWt", `${parseFloat(grosswt).toFixed(3)}`);
    formData.append("ClipWeight", `${parseFloat(clipWeight).toFixed(3)}`);
    formData.append(
      "TotalStoneWeight",
      `${parseFloat(stoneWeight).toFixed(3)}`
    );
    formData.append(
      "TotalStoneAmount",
      `${parseFloat(stoneAmount).toFixed(2)}`
    );
    formData.append("NetWt", parseFloat(netWt).toFixed(3));
    // formData.append("Entryby_Staff_id", parseInt(Entryby_Staff_id));
    // formData.append("Product_No", partyName);
    formData.append("ProductCode", productCode);
    formData.append("MetalName", `${metalName}`);
    formData.append("MetalId", `${baseMetal}`);
    formData.append("Pieces", `${parseInt(pieces)}`);
    formData.append("HUIDCode", huid);
    formData.append("Size", size);
    // formData.append("Hallmark", hallmark);
    formData.append("HallmarkAmount", hallmarkAmount);
    formData.append("CollectionName", "");
    formData.append("OccassionName", "");
    formData.append("Gender", gender);
    formData.append("description", description);
    formData.append("MakingFixedAmt", making_Fixed_Amt);
    formData.append("MakingPerGram", making_per_gram);
    formData.append(
      "MakingPercentage",
      making_Percentage !== "" ? making_Percentage : "0"
    );
    formData.append("MakingFixedWastage", making_Fixed_Wastage);
    // formData.append("StoneAmount", stoneAmount);
    formData.append("Featured", featured);
    // formData.append("Itemtype", productTypeName);
    // formData.append("Product_type", productTypeName);
    formData.append("BranchName", branch);
    formData.append("SKU", selectedSkuName);
    formData.append("BlackBeads", "");
    formData.append("BoxName", bname);
    formData.append("Colour", selectedSku ? selectedSku.Colour : "");
    formData.append("Status", "Active");
    formData.append("CuttingGrossWt", "0");
    formData.append("CuttingNetWt", "0");
    formData.append("HSNCode", "0");
    formData.append("LotNumber", `${lotNumber}`);
    formData.append("WarehouseId", 0);
    formData.append("Margin", "0");
    formData.append("OtherWeight", selectedSku ? selectedSku.OtherWeight : "0");
    formData.append("OfferPrice", "0");
    formData.append("PouchWeight", selectedSku ? selectedSku.PouchWeight : "0");
    formData.append("TagWeight", selectedSku ? selectedSku.TagWeight : "0");
    formData.append(
      "FindingWeight",
      selectedSku ? selectedSku.FindingWeight : "0"
    );
    formData.append(
      "LanyardWeight",
      selectedSku ? selectedSku.LanyardWeight : "0"
    );
    formData.append("Ranking", "0");
    formData.append("UpdatedFrom", "Web");
    formData.append("Width", "0");
    formData.append("Height", "0");
    formData.append("ClientCode", clientCode);
    formData.append("EmployeeCode", employeeCode ? employeeCode : "");
    formData.append("CompanyId", CompanyId ? CompanyId : 0);
    formData.append("BranchId", BranchId ? BranchId : 0);
    formData.append("CounterId", CounterId ? CounterId : 0);
    formData.append("EstimatedDays", "0");
    formData.append("MetalRate", "0");
    formData.append("PurchaseCost", "0");
    formData.append("Rating", "0");
    formData.append("TotalDiamondAmount", diamondTotalAmount);
    formData.append("TotalDiamondPieces", "0");
    formData.append("TotalDiamondWeight", diamondTotalWeight);
    formData.append("TotalStonePieces", "0");
    formData.append("ClipQuantity", clipWeight !== 0 ? "1" : "0");

    formData.append("DiamondSize", `${diamondSize}`);
    formData.append("DiamondWeight", `${diamondWeight}`);
    formData.append("DiamondPurchaseRate", `${diamondPurchaseRate}`);
    formData.append("DiamondSellRate", `${diamondSellRate}`);
    formData.append("DiamondClarity", `${diamondClarity}`);
    formData.append("DiamondColour", `${diamondColour}`);
    formData.append("DiamondShape", `${diamondShape}`);
    formData.append("DiamondCut", `${diamondCut}`);
    formData.append("DiamondSettingType", `${diamondSettingType}`);
    formData.append("DiamondCertificate", `${diamondCertificate}`);
    formData.append("DiamondPieces", `${diamondPieces}`);
    // formData.append("DiamondSleve", `${diamondSleve}`);
    formData.append("DiamondPurchaseAmount", `${diamondPurchaseAmount}`);
    formData.append("DiamondSellAmount", `${diamondTotalAmount}`);
    formData.append("DiamondDescription", `${diamondDescription}`);

    // formData.append("BarcodeNumber", "");
    // formData.append("Images", "");
    if (selectedSkuName !== "" || selectedSku.length > 0) {
      formData.append("Images", selectedSku.Images);
    } else if (selectedFiles.length > 0) {
      selectedFiles.forEach((file) => {
        formData.append("Images", file);
      });
    } else {
      formData.append("Images", "");
      console.log(" No Images Selected");
    }
    for (const [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }
    // console.log(formData, "formData");

    // formData.append("ImageList1", "");
    // formData.append("ImageList2", "");
    // formData.append("ImageList3", "");
    // formData.append("ImageList4", "");
    // formData.append("ImageList5", "");

    try {
      const response = await fetch(a176, {
        method: "POST",
        // headers: {
        //   "Content-Type": "application/json",
        // },
        // body: JSON.stringify(formData),
        body: formData,
      });
      const data = await response.json();
      if (response.ok) {
        // Check if there is a selected stone object to add
        if (selectedSkuStones && Object.keys(selectedSkuStones).length > 0) {
          const updatedProducts = data.map((product) => {
            let SKUStoneItems = selectedSkuStones.SKUStoneItem;
            // Create a new object for each product to avoid mutating the original data directly
            return {
              ...product,
              Stones: [...product.Stones, ...SKUStoneItems], // Add the selected stone object to the Stones array of each product
            };
          });
          setAddedProducts(updatedProducts); // Update your state with the new products array
          // setAddedProducts(data); // Just set the data if no stone is selected
        } else {
          setAddedProducts(data); // Just set the data if no stone is selected
        }

        setLoading(false);
        // console.log("added", data);
        const allItemCodes = data.map((product) => ({
          ItemCode: product.ItemCode,
        }));
        setAllItemCodesArray(allItemCodes);
        setDeleteAll(true);
        setPartyTypeId("");
        setCategory("");
        setProductType("");
        setBoxType("");
        setPurity("");
        setQuantity(1);
        setCollection("");
        setCollectionmain("");
        setGrosswt(0);
        setNetWt(0);
        setGender("");
        setStoneWeight(0);
        setClipWeight(0);
        setMRP(0);
        setProductName("");
        setDescription("");
        setSelectedSku([]);
        setSelectedSkuName("");
        setHasUnsavedChanges(true);
        // setHasUnsavedChanges(false);
        // customNavigate("/adminhome");
        scrollToCenter("adminAddBulkStockAddedTitleStatement");
        // setData(data.data);
        // updateImages();
        // alert("added");
      } else {
        // Handle the error if the upload fails
        console.error("Failed to upload the files.");
      }
    } catch (error) {
      // Handle any network or fetch API errors
      console.error("An error occurred:", error);
    }
  };

  const oldhandleCreateAddedProducts = (e) => {
    e.preventDefault();

    console.log('checking allstonemain ', quantity)
    // let updatedStonesList = [...allStonesList];


    if ((lotNumber && lotNumber !== "0")) {
      if (unlabeledqty < quantity) {
        alert("Error: Quantity exceeds the available limits.");
        return; // Stop the process
      }
    }



    let updatedStonesList = Array.isArray(allStonesList) ? [...allStonesList] : [];




    // Loop through each item in allStonesListmain
    allStonesListmain.forEach((mainStone) => {
      const foundInAllStones = updatedStonesList.find((stoneMain) =>
        Array.isArray(stoneMain.SKUStoneItem) && // Check if SKUStoneItem is an array
        stoneMain.SKUStoneItem.some(
          (stoneItem) =>
            stoneItem.StoneName === mainStone.StoneName &&
            stoneItem.StoneWeight === mainStone.StoneWeight &&
            stoneItem.StonePieces === mainStone.StonePieces
        )
      );

      // If mainStone is not found in any item of allStonesList, add it
      if (!foundInAllStones) {
        const newStoneMain = {
          Id: null, // You may assign a new Id or use the one from mainStone
          StoneMainName: mainStone.StoneName, // This could be set according to your data model
          StoneMainWeight: mainStone.StoneWeight,
          StoneMainPieces: mainStone.StonePieces,
          StoneMainRate: mainStone.StoneRate,
          StoneMainAmount: mainStone.StoneAmount,
          StoneMainDescription: mainStone.Description,
          SKUStoneItem: [
            {
              Id: mainStone.Id,
              StoneName: mainStone.StoneName,
              StoneWeight: mainStone.StoneWeight,
              StonePieces: mainStone.StonePieces,
              StoneRate: mainStone.StoneRate,
              StoneAmount: mainStone.StoneAmount,
              Description: mainStone.Description,
              ClientCode: mainStone.ClientCode,
              SKUStoneMainId: mainStone.Id,
              StoneMasterId: null, // Set appropriate value
              SKUId: mainStone.Id, // Set appropriate value
              CompanyId: mainStone.CompanyId,
              CounterId: mainStone.CounterId,
              BranchId: mainStone.BranchId,
              EmployeeId: mainStone.EmployeeId,
              Status: mainStone.Status,
              StoneLessPercent: mainStone.StoneLessPercent,
            },
          ],
        };

        // Push the missing stone to allStonesList
        updatedStonesList.push(newStoneMain);
      }
    });

    console.log('check allstones ', allStonesList);
    console.log('check mainstone ', allStonesListmain);


    setReadOnly(true);
    let totalStoneAmount =
      selectedSkuStones && selectedSkuStones.length > 0
        ? selectedSkuStones.reduce(
          (a, b) =>
            a +
            (
              parseFloat(b.StoneAmount) * parseFloat(selectedSku.Pieces)
            ).toFixed(2),
          0
        )
        : 0;

    const totalCollectionItem =
      allLabelledStockData &&
      allLabelledStockData.filter((x) => x.DesignId == collectionId);

    console.log("check all items ", totalCollectionItem);
    const lastCollectionItem = totalCollectionItem.reduce(
      (max, item) => (item.Id > max.Id ? item : max),
      { Id: 0 }
    );

    let lastCollectionItemId = "";

    if (totalCollectionItem.length > 0) {
      lastCollectionItemId = lastCollectionItem.ItemCode;
    } else {
      const collectionTypeItem = collectionTypeData.filter(
        (x) => x.Id == parseInt(collectionId)
      )[0];

      if (collectionTypeItem) {
        lastCollectionItemId = collectionTypeItem.LabelCode;
      }
    }

    // const match = lastCollectionItemId.match(/(\D+)(\d*)$/);

    // let prefix = "ABC";
    // let number = 0;
    // if (match) {
    //   prefix = match[1]; // Non-numeric part
    //   number = match[2] ? parseInt(match[2]) : 0; // Numeric part or 0 if empty

    //   console.log("Prefix:", prefix); // "FTP"
    //   console.log("Number:", number); // "21"
    // }

    // const match = lastCollectionItemId.match(/^(\D+)(\d*)$/);

    // const match = lastCollectionItemId.match(/^(\D+)(\d+)$/);
    const match = lastCollectionItemId.match(/^(.+?)(\d+)$/);

    let prefix = "";
    let number = 0;

    const extractNumber = (str) => {
      // Regex to find the last sequence of digits at the end of the string
      const match = str.match(/(\d+)$/);
      return match ? parseInt(match[0], 10) : 0;
    };

    if (match) {
      prefix = match[1]; // Non-numeric part
      number = extractNumber(lastCollectionItemId);
    } else {
      // If no match, use the label as the prefix and start numbering from 1
      prefix = lastCollectionItemId;
      number = 0;
    }

    // const newItemCode = `${prefix}${String(number + 1).padStart(2, '0')}`;

    // console.log(lastCollectionItem, "lastCollectionItemCode");
    // console.log(lastCollectionItem, "lastCollectionItemCode");
    // console.log(lastCollectionItem, "lastCollectionItemCode");
    // console.log(selectedSku, "selectedSku");
    // console.log(Array.isArray(selectedSku), "Is selectedSku an array?");
    // console.log(
    //   selectedSku && selectedSku.length > 0,
    //   "selectedSku length check"
    // );
    // console.log(selectedSkuStones, "selectedSkuStones");
    const curBranch = branchOption.find((item) => item.BranchName === branch);
    let selectedSkuData =
      Array.isArray(selectedSku) && selectedSku.length > 0
        ? selectedSku[0]
        : selectedSku && typeof selectedSku === "object"
          ? selectedSku
          : {};
    let createdProduct = {
      ProductTitle: productName,
      CategoryId: categoryId,
      ProductId: parseInt(productTypeId),
      DesignId: parseInt(collectionId),
      CollectionId: parseInt(collectionmainId),
      VendorId: parseInt(partyId),
      SupplierId: parseInt(partyId),
      PurityId: purityId,
      MRP: `${parseFloat(mrp).toFixed(2)}`,
      Quantity: quantity,
      GrossWt: !grossWithClip
        ? `${parseFloat(grosswt).toFixed(3)}`
        : `${parseFloat(parseFloat(grosswt) - parseFloat(clipWeight)).toFixed(
          3
        )}`,
      ClipWeight: `${parseFloat(clipWeight).toFixed(3)}`,
      TotalStoneWeight: `${parseFloat(stoneWeight).toFixed(3)}`,
      TotalStoneAmount: `${parseFloat(stoneAmount).toFixed(2)}`,
      NetWt: `${parseFloat(netWt).toFixed(3)}`,
      ProductCode: productCode,
      MetalName: `${metalName}`,
      MetalId: `${baseMetal}`,
      Pieces: `${parseInt(pieces)}`,
      HUIDCode: huid,
      Size: `${size}`,
      HallmarkAmount: `${hallmarkAmount}`,
      CollectionName: "",
      OccassionName: "",
      Gender: gender,
      Description: description,
      MakingFixedAmt: `${making_Fixed_Amt}`,
      MakingPerGram: `${making_per_gram}`,
      MakingPercentage: making_Percentage !== "" ? `${making_Percentage}` : "0",
      MakingFixedWastage: `${making_Fixed_Wastage}`,
      Featured: featured,
      BranchName: branch,
      SKU: selectedSkuName,
      SKUId: selectedSkuData.Id || 0,
      BlackBeads: "",
      BoxId: bid ? bid : 0,
      BoxName: bname ? bname : "",
      Status: "Active",
      CuttingGrossWt: "0",
      CuttingNetWt: "0",
      HSNCode: "0",
      LotNumber: `${lotNumber}`,
      WarehouseId: 0,
      WeightCategory: weights,
      Margin: "0",
      OfferPrice: "0",
      Colour: selectedSkuData.Colour || "",
      OtherWeight: selectedSkuData.OtherWeight
        ? `${selectedSkuData.OtherWeight}`
        : "0",
      PouchWeight: selectedSkuData.PouchWeight
        ? `${selectedSkuData.PouchWeight}`
        : "0",
      TagWeight: selectedSkuData.TagWeight
        ? `${selectedSkuData.TagWeight}`
        : "0",
      FindingWeight: selectedSkuData.FindingWeight
        ? `${selectedSkuData.FindingWeight}`
        : "0",
      LanyardWeight: selectedSkuData.LanyardWeight
        ? `${selectedSkuData.LanyardWeight}`
        : "0",
      Ranking: "0",
      UpdatedFrom: "Web",
      Width: "0",
      Height: "0",
      ClientCode: clientCode,
      EmployeeCode: employeeCode ? employeeCode : "",
      CompanyId: CompanyId ? CompanyId : 0,
      BranchId: curBranch.Id ? curBranch.Id : 0,
      CounterId: CounterId ? CounterId : 0,
      EstimatedDays: "0",
      MetalRate: "0",
      PurchaseCost: "0",
      Rating: "0",
      TotalDiamondAmount: "0",
      TotalDiamondPieces: "0",
      TotalDiamondWeight: "0",
      TotalStonePieces: "0",
      ClipQuantity: clipWeight !== 0 ? "1" : "0",
      DiamondSize: `${diamondSize}`,
      DiamondWeight: `${diamondWeight}`,
      DiamondPurchaseRate: `${diamondPurchaseRate}`,
      DiamondSellRate: `${diamondSellRate}`,
      DiamondClarity: `${diamondClarity}`,
      DiamondColour: `${diamondColour}`,
      DiamondShape: `${diamondShape}`,
      DiamondCut: `${diamondCut}`,
      DiamondSettingType: `${diamondSettingType}`,
      DiamondCertificate: `${diamondCertificate}`,
      DiamondPieces: `${diamondPieces.toString()}`,
      DiamondPurchaseAmount: `${diamondPurchaseAmount}`,
      DiamondSellAmount: `${diamondTotalAmount}`,
      DiamondDescription: `${diamondDescription}`,
      DesignName: collectionName,
      CollectionNameSKU: collectionmainName,
      PurityName: purityName,
      ProductName: productTypeName,
      TaxPercentage: "3",
      Stones: newStonesList,
      Diamonds: [],
      PacketId: packetNumber !== "" ? parseInt(packetNumber) : 0,
    };

    // const productList = Array.from(
    //   { length: createdProduct.Quantity },
    //   (_, i) => (
    //     {
    //     ...createdProduct,
    //     Quantity: "1",
    //     ItemCode: `${prefix}${number + i + 1}`,
    //     console.log(`${prefix}${number + i + 1}` , "checkingcode");
    //   }

    // )
    // );
    const productList = Array.from(
      { length: createdProduct.Quantity },
      (_, i) => {
        const newNumber = number + i + 1;
        const newItemCode = `${prefix}${newNumber}`;
        return {
          ...createdProduct,
          Quantity: "1",
          ItemCode: newItemCode,
        };
      }
    );

    if (
      selectedSkuStones &&
      selectedSkuStones.Id !== 0 &&
      Object.keys(selectedSkuStones).length > 0
    ) {
      const updatedProducts = productList.map((product) => {
        let SKUStoneItems = selectedSkuStones.SKUStoneItem;
        return {
          ...product,
          Stones: [...product.Stones, ...SKUStoneItems], // Add the selected stone object to the Stones array of each product
        };
      });
      setAddedProducts(updatedProducts); // Update your state with the new products array
    } else {
      setAddedProducts(productList); // Just set the data if no stone is selected
    }

    setLoading(false);

    const allItemCodes = productList.map((product) => ({
      ItemCode: product.ItemCode,
    }));
    setAllItemCodesArray(allItemCodes);
    setDeleteAll(true);
    setPartyTypeId("");
    setCategory("");
    setProductType("");
    setBoxType("");
    setPurity("");
    setQuantity(1);
    setCollection("");
    setCollectionmain("");
    setGrosswt(0);
    setNetWt(0);
    setGender("");
    setStoneWeight(0);
    setClipWeight(0);
    setMRP(0);
    setProductName("");
    setDescription("");
    // setSelectedSku([]);
    // setSelectedSkuName("");
    setHasUnsavedChanges(true);
    scrollToCenter("adminAddBulkStockAddedTitleStatement");
  };


  const handleCreateAddedProducts = (e) => {
    e.preventDefault();

    console.log('checking allstonemain ', quantity)
    // let updatedStonesList = [...allStonesList];


    const parsedQuantity = parseInt(quantity);

    console.log('checking allstonemain ', parsedQuantity);

    // Check for valid quantity (non-empty, non-null, and >= 1)
    if (isNaN(parsedQuantity) || parsedQuantity < 1) {
      alert("Error: Quantity must be at least 1 and cannot be empty or null.");
      return; // Stop the process
    }


    if ((lotNumber && lotNumber !== "0")) {
      if (unlabeledqty < quantity) {
        alert("Error: Quantity exceeds the available limits.");
        return; // Stop the process
      }
    }



    let updatedStonesList = Array.isArray(allStonesList) ? [...allStonesList] : [];




    // Loop through each item in allStonesListmain
    allStonesListmain.forEach((mainStone) => {
      const foundInAllStones = updatedStonesList.find((stoneMain) =>
        Array.isArray(stoneMain.SKUStoneItem) && // Check if SKUStoneItem is an array
        stoneMain.SKUStoneItem.some(
          (stoneItem) =>
            stoneItem.StoneName === mainStone.StoneName &&
            stoneItem.StoneWeight === mainStone.StoneWeight &&
            stoneItem.StonePieces === mainStone.StonePieces
        )
      );

      // If mainStone is not found in any item of allStonesList, add it
      if (!foundInAllStones) {
        const newStoneMain = {
          Id: null, // You may assign a new Id or use the one from mainStone
          StoneMainName: mainStone.StoneName, // This could be set according to your data model
          StoneMainWeight: mainStone.StoneWeight,
          StoneMainPieces: mainStone.StonePieces,
          StoneMainRate: mainStone.StoneRate,
          StoneMainAmount: mainStone.StoneAmount,
          StoneMainDescription: mainStone.Description,
          SKUStoneItem: [
            {
              Id: mainStone.Id,
              StoneName: mainStone.StoneName,
              StoneWeight: mainStone.StoneWeight,
              StonePieces: mainStone.StonePieces,
              StoneRate: mainStone.StoneRate,
              StoneAmount: mainStone.StoneAmount,
              Description: mainStone.Description,
              ClientCode: mainStone.ClientCode,
              SKUStoneMainId: mainStone.Id,
              StoneMasterId: null, // Set appropriate value
              SKUId: mainStone.Id, // Set appropriate value
              CompanyId: mainStone.CompanyId,
              CounterId: mainStone.CounterId,
              BranchId: mainStone.BranchId,
              EmployeeId: mainStone.EmployeeId,
              Status: mainStone.Status,
              StoneLessPercent: mainStone.StoneLessPercent,
            },
          ],
        };

        // Push the missing stone to allStonesList
        updatedStonesList.push(newStoneMain);
      }
    });

    console.log('check allstones ', allStonesList);
    console.log('check mainstone ', allStonesListmain);


    setReadOnly(true);
    let totalStoneAmount =
      selectedSkuStones && selectedSkuStones.length > 0
        ? selectedSkuStones.reduce(
          (a, b) =>
            a +
            (
              parseFloat(b.StoneAmount) * parseFloat(selectedSku.Pieces)
            ).toFixed(2),
          0
        )
        : 0;

    const totalCollectionItem =
      allLabelledStockData &&
      allLabelledStockData.filter((x) => x.DesignId == collectionId);

    console.log("check all items ", totalCollectionItem);
    const lastCollectionItem = totalCollectionItem.reduce(
      (max, item) => (item.Id > max.Id ? item : max),
      { Id: 0 }
    );

    let lastCollectionItemId = "";

    if (totalCollectionItem.length > 0) {
      lastCollectionItemId = lastCollectionItem.ItemCode;
    } else {
      const collectionTypeItem = collectionTypeData.filter(
        (x) => x.Id == parseInt(collectionId)
      )[0];

      if (collectionTypeItem) {
        lastCollectionItemId = collectionTypeItem.LabelCode;
      }
    }

    // const match = lastCollectionItemId.match(/(\D+)(\d*)$/);

    // let prefix = "ABC";
    // let number = 0;
    // if (match) {
    //   prefix = match[1]; // Non-numeric part
    //   number = match[2] ? parseInt(match[2]) : 0; // Numeric part or 0 if empty

    //   console.log("Prefix:", prefix); // "FTP"
    //   console.log("Number:", number); // "21"
    // }

    // const match = lastCollectionItemId.match(/^(\D+)(\d*)$/);

    // const match = lastCollectionItemId.match(/^(\D+)(\d+)$/);
    // const match = lastCollectionItemId.match(/^(.+?)(\d+)$/);

    // let prefix = "";
    // let number = 0;

    // const extractNumber = (str) => {
    //   // Regex to find the last sequence of digits at the end of the string
    //   const match = str.match(/(\d+)$/);
    //   return match ? parseInt(match[0], 10) : 0;
    // };

    // if (match) {
    //   prefix = match[1]; // Non-numeric part
    //   number = extractNumber(lastCollectionItemId);
    // } else {
    //   // If no match, use the label as the prefix and start numbering from 1
    //   prefix = lastCollectionItemId;
    //   number = 0;
    // }

    // const newItemCode = `${prefix}${String(number + 1).padStart(2, '0')}`;

    // console.log(lastCollectionItem, "lastCollectionItemCode");
    // console.log(lastCollectionItem, "lastCollectionItemCode");
    // console.log(lastCollectionItem, "lastCollectionItemCode");
    // console.log(selectedSku, "selectedSku");
    // console.log(Array.isArray(selectedSku), "Is selectedSku an array?");
    // console.log(
    //   selectedSku && selectedSku.length > 0,
    //   "selectedSku length check"
    // );
    // console.log(selectedSkuStones, "selectedSkuStones");
    const curBranch = branchOption.find((item) => item.BranchName === branch);

    const selectedVendor = partyData.find(event => event.Id == parseInt(partyId));
    let selectedSkuData =
      Array.isArray(selectedSku) && selectedSku.length > 0
        ? selectedSku[0]
        : selectedSku && typeof selectedSku === "object"
          ? selectedSku
          : {};
    let createdProduct = {
      ProductTitle: productName,
      CategoryId: categoryId,
      ProductId: parseInt(productTypeId),
      DesignId: parseInt(collectionId),
      CollectionId: parseInt(collectionmainId),
      VendorId: parseInt(partyId),
      VendorName: selectedVendor ? selectedVendor.VendorName : '',
      SupplierId: parseInt(partyId),
      PurityId: purityId,
      MRP: `${parseFloat(mrp).toFixed(2)}`,
      Quantity: quantity,
      GrossWt: !grossWithClip
        ? `${parseFloat(grosswt).toFixed(3)}`
        : `${parseFloat(parseFloat(grosswt) - parseFloat(clipWeight)).toFixed(
          3
        )}`,
      ClipWeight: `${parseFloat(clipWeight).toFixed(3)}`,
      TotalStoneWeight: `${parseFloat(stoneWeight).toFixed(3)}`,
      TotalStoneAmount: `${parseFloat(stoneAmount).toFixed(2)}`,
      NetWt: `${parseFloat(netWt).toFixed(3)}`,
      ProductCode: productCode,
      MetalName: `${metalName}`,
      MetalId: `${baseMetal}`,
      Pieces: `${parseInt(pieces)}`,
      HUIDCode: huid,
      Size: `${size}`,
      HallmarkAmount: `${hallmarkAmount}`,
      CollectionName: `${collectionmainName}`,
      OccassionName: "",
      Gender: gender,
      Description: description,
      MakingFixedAmt: `${making_Fixed_Amt}`,
      MakingPerGram: `${making_per_gram}`,
      MakingPercentage: making_Percentage !== "" ? `${making_Percentage}` : "0",
      MakingFixedWastage: `${making_Fixed_Wastage}`,
      Featured: featured,
      BranchName: branch,
      SKU: selectedSkuName,
      SKUId: selectedSkuData.Id || 0,
      BoxId: bid ? bid : 0,
      BoxName: bname ? bname : "",
      Status: "Active",
      CuttingGrossWt: "0",
      CuttingNetWt: "0",
      HSNCode: "0",
      LotNumber: `${lotNumber}`,
      WarehouseId: 0,
      WeightCategory: weights,
      Margin: "0",
      OfferPrice: "0",
      Colour: selectedSkuData.Colour || "",
      OtherWeight: selectedSkuData.OtherWeight
        ? `${selectedSkuData.OtherWeight}`
        : "0",
      PouchWeight: selectedSkuData.PouchWeight
        ? `${selectedSkuData.PouchWeight}`
        : "0",
      TagWeight: selectedSkuData.TagWeight
        ? `${selectedSkuData.TagWeight}`
        : "0",
      FindingWeight: selectedSkuData.FindingWeight
        ? `${selectedSkuData.FindingWeight}`
        : "0",
      LanyardWeight: selectedSkuData.LanyardWeight
        ? `${selectedSkuData.LanyardWeight}`
        : "0",
      Ranking: "0",
      UpdatedFrom: "Web",
      Width: "0",
      Height: "0",
      ClientCode: clientCode,
      EmployeeCode: employeeCode ? employeeCode : "",
      CompanyId: CompanyId ? CompanyId : 0,
      BranchId: curBranch.Id ? curBranch.Id : 0,
      CounterId: CounterId ? CounterId : 0,
      EstimatedDays: "0",
      MetalRate: "0",
      PurchaseCost: "0",
      Rating: "0",
      TotalDiamondAmount: "0",
      TotalDiamondPieces: "0",
      TotalDiamondWeight: "0",
      TotalStonePieces: "0",
      ClipQuantity: clipWeight !== 0 ? "1" : "0",
      DiamondSize: `${diamondSize}`,
      DiamondWeight: `${diamondWeight}`,
      DiamondPurchaseRate: `${diamondPurchaseRate}`,
      DiamondSellRate: `${diamondSellRate}`,
      DiamondClarity: `${diamondClarity}`,
      DiamondColour: `${diamondColour}`,
      DiamondShape: `${diamondShape}`,
      DiamondCut: `${diamondCut}`,
      DiamondSettingType: `${diamondSettingType}`,
      DiamondCertificate: `${diamondCertificate}`,
      DiamondPieces: `${diamondPieces.toString()}`,
      DiamondPurchaseAmount: `${diamondPurchaseAmount}`,
      DiamondSellAmount: `${diamondTotalAmount}`,
      DiamondDescription: `${diamondDescription}`,
      DesignName: collectionName,
      CollectionNameSKU: collectionmainName,
      PurityName: purityName,
      ProductName: productTypeName,
      TaxPercentage: "3",
      Stones: newStonesList,
      Diamonds: [],
      PacketId: packetNumber !== "" ? parseInt(packetNumber) : 0,
    };

    // const productList = Array.from(
    //   { length: createdProduct.Quantity },
    //   (_, i) => (
    //     {
    //     ...createdProduct,
    //     Quantity: "1",
    //     ItemCode: `${prefix}${number + i + 1}`,
    //     console.log(`${prefix}${number + i + 1}` , "checkingcode");
    //   }

    // )
    // );


    // const totalCollectionItem =
    // allLabelledStockData &&
    // allLabelledStockData.filter((x) => x.DesignId == collectionId);
    const relevantLabelStockItems = allLabelledStockData.filter((item) => item.DesignId == collectionId);
    const relevantGroupItems = groupproducts.flat().filter((item) => item.DesignId == collectionId);

    const lastLabelStockItem = relevantLabelStockItems.reduce((max, item) => item.Id > max.Id ? item : max, { Id: 0 });
    // const lastGroupItem = relevantGroupItems.reduce((max, item) => item.Id > max.Id ? item : max, { Id: 0 });

    const lastGroupItem = relevantGroupItems.reduce((max, item) => {
      // Parse the numeric part of ItemCode (assuming ItemCode is a string)
      const currentItemCodeNumber = parseInt(item.ItemCode.match(/\d+/g)?.pop() || "0"); // Extract numeric part
      const maxItemCodeNumber = parseInt(max.ItemCode.match(/\d+/g)?.pop() || "0");

      return currentItemCodeNumber > maxItemCodeNumber ? item : max;
    }, { ItemCode: "0" }); // Start with a default ItemCode


    let lastitemcode = "";

    console.log('check lastgroupitem ', relevantLabelStockItems);
    console.log('check lastcode ', lastLabelStockItem);

    if (relevantGroupItems.length > 0) {

      lastitemcode = lastGroupItem.ItemCode;

    } else {
      if (totalCollectionItem.length > 0) {
        lastitemcode = lastLabelStockItem.ItemCode;
      } else {
        const collectionTypeItem = collectionTypeData.filter(
          (x) => x.Id == parseInt(collectionId)
        )[0];

        if (collectionTypeItem) {
          lastitemcode = collectionTypeItem.LabelCode;
        }
      }
    }

    const match = lastitemcode.match(/^(.+?)(\d+)$/);

    let prefix = "";
    let number = 0;

    const extractNumber = (str) => {
      // Regex to find the last sequence of digits at the end of the string
      const match = str.match(/(\d+)$/);
      return match ? parseInt(match[0], 10) : 0;
    };

    if (match) {
      prefix = match[1]; // Non-numeric part
      number = extractNumber(lastitemcode);
    } else {
      // If no match, use the label as the prefix and start numbering from 1
      prefix = lastitemcode;
      number = 0;
    }





    const productList = Array.from(
      { length: createdProduct.Quantity },
      (_, i) => {
        const newNumber = number + i + 1;
        const newItemCode = `${prefix}${newNumber}`;
        return {
          ...createdProduct,
          Quantity: "1",
          ItemCode: newItemCode,
        };
      }
    );

    if (
      selectedSkuStones &&
      selectedSkuStones.Id !== 0 &&
      Object.keys(selectedSkuStones).length > 0
    ) {
      const updatedProducts = productList.map((product) => {
        let SKUStoneItems = selectedSkuStones.SKUStoneItem;
        return {
          ...product,
          Stones: [...product.Stones, ...SKUStoneItems], // Add the selected stone object to the Stones array of each product
        };
      });
      // setAddedProducts(updatedProducts); // Update your state with the new products array
      // setGroupedProducts(prevGroups => [...prevGroups, updatedProducts]);
      setGroupedProducts(prevGroups => {
        const newGroups = [...prevGroups, updatedProducts];
        setExpandedGroups([...Array(newGroups.length - 1).fill(false), true]); // Auto-expand the latest group
        return newGroups;
      });
    } else {
      // setAddedProducts(productList); // Just set the data if no stone is selected
      // setGroupedProducts(prevGroups => [...prevGroups, productList]);
      setGroupedProducts(prevGroups => {
        const newGroups = [...prevGroups, productList];
        setExpandedGroups([...Array(newGroups.length - 1).fill(false), true]); // Auto-expand the latest group
        return newGroups;
      });
    }


    setLoading(false);

    const allItemCodes = productList.map((product) => ({
      ItemCode: product.ItemCode,
    }));
    setAllItemCodesArray(allItemCodes);
    setDeleteAll(true);
    setPartyTypeId("");
    setCategory("");
    setProductType("");
    setBoxType("");
    setPurity("");
    setQuantity(1);
    setCollection("");
    setCollectionmain("");
    setGrosswt(0);
    setNetWt(0);
    setGender("");
    setStoneWeight(0);
    setClipWeight(0);
    setMRP(0);
    setProductName("");
    setDescription("");
    setSelectedSku([]);
    setSelectedSkuName("");
    setHasUnsavedChanges(true);
    scrollToCenter("adminAddBulkStockAddedTitleStatement");
  };


  useEffect(() => {
    console.log('check grouped items ', groupproducts);

  }, [groupproducts])



  const handleSubmitUnlabelled = async (e) => {
    e.preventDefault();

    if (grosswt <= 0) {
      alert(`Gross wt should not be 0`);
      return;
    }

    setReadOnly(true);
    setLoading(true);

    let formData = new FormData();

    formData.append("ProductTitle", productName);
    formData.append("HSNCode", "");
    formData.append("Description", description);
    formData.append("ProductCode", productCode);
    formData.append("MetalName", metalName);
    formData.append("CategoryId", categoryId);
    formData.append("ProductId", productTypeId);
    formData.append("DesignId", collectionId);
    formData.append("CollectionId", parseInt(collectionmainId));
    formData.append("PurityId", purityId);
    formData.append("Colour", "");
    formData.append("TotalGrossWt", `${grosswt}`);
    formData.append("Size", `${size}`);
    formData.append("TotalNetWt", `${netWt}`);
    formData.append("CollectionName", "");
    formData.append("OccassionName", "");
    formData.append("ClipWeight", "0");
    formData.append("ClipQuantity", "0");
    formData.append("Gender", gender);
    formData.append("MakingFixedAmt", `${making_Fixed_Amt}`);
    formData.append("MakingPerGram", `${making_per_gram}`);
    formData.append("MakingFixedWastage", `${making_Fixed_Wastage}`);
    formData.append("MakingPercentage", `${making_Percentage}`);
    formData.append("TotalStoneWeight", `${stoneWeight}`);
    formData.append("TotalStoneAmount", `${stoneAmount}`);
    formData.append("TotalStonePieces", "");
    formData.append("TotalDiamondWeight", diamondTotalWeight);
    formData.append("TotalDiamondPieces", String(diamondPieces));
    formData.append("TotalDiamondAmount", diamondTotalAmount);
    formData.append("Featured", "");
    formData.append("Pieces", pieces);
    formData.append("HallmarkAmount", hallmarkAmount);
    formData.append("HUIDCode", huid);
    formData.append("MRP", `${mrp}`);
    formData.append("VendorId", partyId);
    formData.append("BoxId", bid);
    formData.append("TIDNumber", "");
    formData.append("RFIDCode", "");
    formData.append("BlackBeads", "");
    formData.append("Height", "");
    formData.append("Width", "");
    formData.append("OrderedItemId", 0);
    formData.append("CuttingGrossWt", "0");
    formData.append("CuttingNetWt", "0");
    formData.append("MetalRate", "0");
    formData.append("LotNumber", `${lotNumber}`);
    formData.append("DeptId", 0);
    formData.append("PurchaseCost", "");
    formData.append("Margin", "");
    formData.append("BranchName", `${branch}`);
    formData.append("BoxName", "");
    formData.append("EstimatedDays", "0");
    formData.append("OfferPrice", "0");
    formData.append("Rating", "0");
    formData.append("SKU", selectedSkuName);
    formData.append("Ranking", "0");
    formData.append("CompanyId", CompanyId ? CompanyId : 0);
    formData.append("CounterId", CounterId ? CounterId : 0);
    formData.append("BranchId", BranchId ? BranchId : 0);
    formData.append("Status", "Active");
    formData.append("ClientCode", clientCode);
    formData.append("EmployeeCode", employeeCode ? employeeCode : "0");
    formData.append("UpdatedFrom", "Web");
    formData.append("SupplierId", partyId);
    formData.append("Quantity", quantity);
    formData.append("GroupCode", "0");
    formData.append("FinePercentage", `${finePerc}`);
    formData.append("WastagePercentage", `${wastagePerc}`);
    formData.append("FinePlusWastageWeight", `${fineWastagePerc}`);
    formData.append("Images", "");
    formData.append("MetalId", baseMetal);
    formData.append("WarehouseId", 0);

    // formData.append("DiamondSize", `${diamondSize}`);
    // formData.append("DiamondWeight", `${diamondWeight}`);
    // formData.append("DiamondPurchaseRate", `${diamondPurchaseRate}`);
    // formData.append("DiamondSellRate", `${diamondSellRate}`);
    // formData.append("DiamondClarity", `${diamondClarity}`);
    // formData.append("DiamondColour", `${diamondColour}`);
    // formData.append("DiamondShape", `${diamondShape}`);
    // formData.append("DiamondCut", `${diamondCut}`);
    // formData.append("DiamondSettingType", `${diamondSettingType}`);
    // formData.append("DiamondCertificate", `${diamondCertificate}`);
    // formData.append("DiamondPieces", `${diamondPieces}`);
    // formData.append("DiamondPurchaseAmount", `${diamondPurchaseAmount}`);
    // formData.append("DiamondSellAmount", `${diamondTotalAmount}`);
    // formData.append("DiamondDescription", `${diamondDescription}`);
    // formData.append("PacketId", packetNumber !== "" ? packetNumber : 0);

    console.log("check form data ", formData);

    for (const [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      const response = await fetch(a198, {
        method: "POST",
        body: formData,
      });
      const data = await response.json();
      // console.log(data, "Unlabeldata");
      // console.log(data.data, "Unlabeldata");
      // console.log(data, "rcvd data 1st hit");
      if (!data.Message) {
        setAddedProducts([data]);
        // console.log("Inside Response");
        setLoading(false);
        // console.log("added", data);
        // const allItemCodes = data.data.map((product) => ({
        //   ItemCode: product.itemCode,
        // }));
        // setAllItemCodesArray(allItemCodes);
        setDeleteAll(true);
        setPartyTypeId("");
        setCategory("");
        setProductType("");
        setBoxType("");
        setPurity("");
        setQuantity(1);
        setCollection("");
        setCollectionmain();
        setGrosswt(0);
        setNetWt(0);
        setClipWeight(0);
        setGender("");
        setStoneWeight(0);
        setMRP(0);
        setProductName("");
        setDescription("");
        setSelectedSku([]);
        setSelectedSkuName("");
        setHasUnsavedChanges(true);
        // setStockType("Labelled");
        scrollToCenter("adminAddBulkStockAddedTitleStatement");
        // setData(data.data);
        // updateImages();
        // alert("added");
      } else {
        // Handle the error if the upload fails
        console.error("Failed to upload the files  .", data);
      }
    } catch (error) {
      // Handle any network or fetch API errors
      console.error("An error occurred:", error);
    }
  };
  useEffect(() => {
    // console.log("allItemCodesArray", allItemCodesArray);
  }, [allItemCodesArray]);

  //handle unlabelled gold and silver
  const handleGold = (e, property) => {
    const { value } = e.target;

    setLotNumber(value);

    const gold = 0;

    allFilteredPurchaseItems.map((x) => {
      if (x.LotNumber == value) {
        console.log("checking unlabeled ", x);
      }
    });
  };


  // console.log("allItemCodesArray outside useEffect", allItemCodesArray);
  const handleInputChange = (e, index, property) => {
    const { value } = e.target;
    // Copy the addedProducts array to avoid direct state mutation
    const updatedProducts = [...addedProducts];

    const product = updatedProducts[index];



    // Update the specific property in the product object
    let updatedProduct = { ...product, [property]: value };

    // Parse properties to numbers or set them as 0 if the value is empty or invalid
    const grosswt =
      stockType === "Labelled"
        ? parseFloat(product.GrossWt) || 0
        : stockType === "Unlabelled"
          ? parseFloat(product.TotalGrossWt) || 0
          : 0;
    const stoneWeight = parseFloat(product.TotalStoneWeight) || 0;
    const netWt = parseFloat(product.NetWt) || 0;
    const clipWeight = parseFloat(product.ClipWeight) || 0;

    if (property === "RFIDCode") {
      // Convert the barcode number to uppercase before doing the comparison
      const barcodeValue = value.toUpperCase();
      updatedProduct.RFIDCode = barcodeValue; // Set the barcodeNumber property to uppercase

      //     // Check for a match in the allLabelledStockData array
      // const matchingLabelledProduct = allLabelledStockData.find(
      //   (item) => item.RFIDCode === barcodeValue
      // );

      const matchingLabelledProduct = allLabelledStockData.find((item) => {
        return item.RFIDCode === barcodeValue && item.Status !== "Sold";
      });

      if (matchingLabelledProduct) {
        // Show an alert if a match is found
        alert(`Barcode ${barcodeValue} is already in use for another product.`);
        updatedProduct.RFIDCode = ""; // Clear the RFIDCode field
      } else {
        // If no match is found, proceed with the normal logic
        const matchingProduct = rifdData.find(
          (item) => item.BarcodeNumber === barcodeValue
        );

        if (matchingProduct) {
          updatedProduct.TIDNumber = matchingProduct.TidValue;
        } else {
          // If no matching product found, set TIDNumber to null
          updatedProduct.TIDNumber = null;
        }
      }

      // // Find a matching product in the rifdData array
      // const matchingProduct = rifdData.find(
      //   (item) => item.BarcodeNumber === barcodeValue
      // );

      // if (matchingProduct) {
      //   updatedProduct.TIDNumber = matchingProduct.TidValue;
      // } else {
      //   // If no matching product found, set 'tid' to null or some default value
      //   updatedProduct.TIDNumber = null; // or any default value you want
      //   // setBarCodeAlert(true);
      // }
    }

    // // If 'grosswt' is changed, calculate 'netWt'
    // if (property === "GrossWt" && !isNaN(value)) {
    //   updatedProduct.NetWt =
    //     parseFloat(value) -
    //       parseFloat(updatedProduct.ClipWeight) -
    //       parseFloat(updatedProduct.TotalStoneWeight) >
    //       0
    //       ? (
    //         parseFloat(value) -
    //         parseFloat(updatedProduct.ClipWeight) -
    //         parseFloat(updatedProduct.TotalStoneWeight)
    //       ).toFixed(3)
    //       : (updatedProduct.GrossWt = value);
    // }

    // Parse updated properties to numbers, defaulting to 0 if empty or invalid
    // const grosswt = parseFloat(product.GrossWt) || 0;

    let totalFilteredGrossWt = 0;
    if (lotNumber && lotNumber !== "0") {
      totalFilteredGrossWt = unlabeledgold;
      // allFilteredPurchaseItems.reduce((acc, item) => {
      //   return acc + parseFloat(item.GrossWt || 0);
      // }, 0);


      // const stoneWeight = parseFloat(product.TotalStoneWeight) || 0;
      // const netWt = parseFloat(product.NetWt) || 0;

      // Update 'GrossWt' -> recalculate 'NetWt'
      if (property === "GrossWt" && !isNaN(value)) {
        if (value > totalFilteredGrossWt) {
          alert("Error: Total GrossWt of all items exceeds the available GrossWt.");
          updatedProduct.GrossWt = 0;
          updatedProduct.NetWt = 0;
        } else {
          updatedProduct.NetWt =
            parseFloat(value) - parseFloat(clipWeight) - parseFloat(stoneWeight) > 0
              ? (
                parseFloat(value) -
                parseFloat(clipWeight) -
                parseFloat(stoneWeight)
              ).toFixed(3)
              : 0;
        }
      }

    } else {
      if (property === "GrossWt" && !isNaN(value)) {

        updatedProduct.NetWt =
          parseFloat(value) - parseFloat(clipWeight) - parseFloat(stoneWeight) > 0
            ? (
              parseFloat(value) -
              parseFloat(clipWeight) -
              parseFloat(stoneWeight)
            ).toFixed(3)
            : 0;

      }

    }



    // // Update 'TotalStoneWeight' -> recalculate 'NetWt'
    // if (property === "TotalStoneWeight" && !isNaN(value)) {
    //   updatedProduct.NetWt =
    //     grosswt > parseFloat(value)
    //       ? (grosswt - parseFloat(value)).toFixed(3)
    //       : 0;
    // }
    // // console.log('propertyvalue ',property, '  ', value , updatedProduct.)

    // // Update 'ClipWeight' -> recalculate 'NetWt'
    // if (property === "ClipWeight" && !isNaN(value)) {
    //   if (grosswt > clipWeight) {
    //     // If clip weight is valid, recalculate net weight
    //     updatedProduct.NetWt = (grosswt - (clipWeight + stoneWeight)).toFixed(3);
    //   } else {
    //     // If clip weight exceeds gross weight, reset values
    //     // updatedProduct.GrossWt = (clipWeight + stoneWeight).toFixed(3);
    //     updatedProduct.NetWt = 0;
    //     updatedProduct.ClipWeight = 0
    //   }
    // }

    // // Update 'NetWt' -> recalculate 'GrossWt' and 'TotalStoneWeight'
    // if (property === "NetWt" && !isNaN(value)) {
    //   updatedProduct.GrossWt = (
    //     parseFloat(value) + parseFloat(clipWeight) + stoneWeight
    //   ).toFixed(3);
    //   updatedProduct.TotalStoneWeight = (
    //     grosswt - parseFloat(value) - parseFloat(clipWeight)
    //   ).toFixed(3);
    // }



    if (property === "TotalGrossWt" && !isNaN(value)) {


      updatedProduct.TotalNetWt =
        parseFloat(value) - parseFloat(updatedProduct.TotalStoneWeight) > 0
          ? (
            parseFloat(value) -
            parseFloat(updatedProduct.ClipWeight) -
            parseFloat(updatedProduct.TotalStoneWeight)
          ).toFixed(3)
          : (updatedProduct.TotalGrossWt = value);
    }

    // If 'stoneWeight' is changed, calculate 'netWt'
    if (property === "TotalStoneWeight" && !isNaN(value)) {
      const clipWeight1 = parseFloat(value);
      if (grosswt > clipWeight1) {
        updatedProduct.NetWt = updatedProduct.GrossWt - parseFloat(value) - updatedProduct.ClipWeight
        updatedProduct.TotalStoneWeight = value

        // parseFloat(updatedProduct.GrossWt) > value
        //   ? ((updatedProduct.GrossWt - parseFloat(value)-value).toFixed(3),updatedProduct.TotalStoneWeight= value)
        //   : (
        //     // (updatedProduct.stoneWeight = 0)
        //     updatedProduct.NetWt = (updatedProduct.GrossWt - updatedProduct.ClipWeight).toFixed(3),  // Correct calculation for NetWt
        //         updatedProduct.TotalStoneWeight = 0  // Reset TotalStoneWeight to 0
        // (updatedProduct.GrossWt = value),
        // (updatedProduct.TotalStoneWeight = value),
        // (updatedProduct.NetWt = 0)
        // );
      } else {
        updatedProduct.NetWt = updatedProduct.GrossWt - 0 - updatedProduct.ClipWeight
        updatedProduct.TotalStoneWeight = 0
        // updatedProduct.NetWt =
        // parseFloat(updatedProduct.GrossWt) > value
        //   ? ((updatedProduct.GrossWt - parseFloat(value)-value).toFixed(3),updatedProduct.TotalStoneWeight= value)
        //   : (
        //     // (updatedProduct.stoneWeight = 0)
        //     updatedProduct.NetWt = (updatedProduct.GrossWt - updatedProduct.ClipWeight).toFixed(3),  // Correct calculation for NetWt
        //         updatedProduct.TotalStoneWeight = 0  // Reset TotalStoneWeight to 0
        //     // (updatedProduct.GrossWt = value),
        //     // (updatedProduct.TotalStoneWeight = value),
        //     // (updatedProduct.NetWt = 0)
        //   );
      }



      // updatedProduct.stoneWeight = value
      // updatedProduct.TotalStoneWeight= value
    }
    if (property === "ClipWeight" && !isNaN(value)) {
      const clipWeight1 = parseFloat(value);
      if (grosswt > clipWeight1) {
        // If gross weight is greater than the clip weight, update net weight
        updatedProduct.NetWt = parseFloat(
          grosswt - (stoneWeight + clipWeight1)
        ).toFixed(3);
        updatedProduct.ClipWeight = clipWeight1;
        updatedProduct.ClipQuantity = "1";
      } else {
        // If clip weight is greater or equal to the gross weight, adjust accordingly
        // updatedProduct.GrossWt = parseFloat(clipWeight + stoneWeight).toFixed(
        //   3
        // );
        updatedProduct.ClipWeight = 0;// clipWeight;
        updatedProduct.ClipQuantity = "1";
        updatedProduct.NetWt = parseFloat(
          grosswt - (stoneWeight + 0)
        ).toFixed(3);//0; // Set net weight to 0 if conditions dictate
      }
    }

    // If 'netWt' is changed, calculate 'grosswt' and 'stoneWeight'
    if (property === "NetWt" && !isNaN(value)) {
      updatedProduct.GrossWt = (
        parseFloat(value) +
        parseFloat(updatedProduct.ClipWeight) +
        stoneWeight
      ).toFixed(3);
      updatedProduct.TotalStoneWeight = (
        grosswt -
        parseFloat(value) -
        parseFloat(updatedProduct.ClipWeight)
      ).toFixed(3);
    }

    if (property === "TotalNetWt" && !isNaN(value)) {
      updatedProduct.TotalGrossWt = (
        parseFloat(value) +
        stoneWeight +
        parseFloat(updatedProduct.ClipWeight)
      ).toFixed(3);
      updatedProduct.TotalStoneWeight = (grosswt - parseFloat(value)).toFixed(
        3
      );
    }
    if (property === "Pieces" && value > 1 && stockType === "Labelled") {
      setPiecesBox(true);
      setProductPiecesEditId(index);
      handlePiecesChange(value, index);
    }

    // Second case: check if total GrossWt does not exceed filtered GrossWt

    updatedProducts[index] = updatedProduct;


    if (lotNumber && lotNumber !== "0") {

      const totalCurrentGrossWt = updatedProducts.reduce((acc, item) => {
        return acc + parseFloat(item.GrossWt || 0);
      }, 0);

      if (totalCurrentGrossWt > totalFilteredGrossWt) {
        alert("Error: Total GrossWt of all items exceeds the available GrossWt.");
        return; // Stop further execution
      }
    }

    console.log('checking updateproducts ', updatedProducts);


    setAddedProducts(updatedProducts);
  };

  // // console.log("allItemCodesArray outside useEffect", allItemCodesArray);
  // const handleInputChange = (e, index, property) => {
  //   const { value } = e.target;
  //   // Copy the addedProducts array to avoid direct state mutation
  //   const updatedProducts = [...addedProducts];

  //   const product = updatedProducts[index];



  //   // Update the specific property in the product object
  //   let updatedProduct = { ...product, [property]: value };

  //    // Parse properties to numbers or set them as 0 if the value is empty or invalid
  //    const grosswt =
  //    stockType === "Labelled"
  //      ? parseFloat(product.GrossWt) || 0
  //      : stockType === "Unlabelled"
  //        ? parseFloat(product.TotalGrossWt) || 0
  //        : 0;
  //  const stoneWeight = parseFloat(product.TotalStoneWeight) || 0;
  //  const netWt = parseFloat(product.NetWt) || 0;

  //   if (property === "RFIDCode") {
  //     // Convert the barcode number to uppercase before doing the comparison
  //     const barcodeValue = value.toUpperCase();
  //     updatedProduct.RFIDCode = barcodeValue; // Set the barcodeNumber property to uppercase

  //     //     // Check for a match in the allLabelledStockData array
  //     // const matchingLabelledProduct = allLabelledStockData.find(
  //     //   (item) => item.RFIDCode === barcodeValue
  //     // );

  //     const matchingLabelledProduct = allLabelledStockData.find((item) => {
  //       return item.RFIDCode === barcodeValue && item.Status !== "Sold";
  //     });

  //     if (matchingLabelledProduct) {
  //       // Show an alert if a match is found
  //       alert(`Barcode ${barcodeValue} is already in use for another product.`);
  //       updatedProduct.RFIDCode = ""; // Clear the RFIDCode field
  //     } else {
  //       // If no match is found, proceed with the normal logic
  //       const matchingProduct = rifdData.find(
  //         (item) => item.BarcodeNumber === barcodeValue
  //       );

  //       if (matchingProduct) {
  //         updatedProduct.TIDNumber = matchingProduct.TidValue;
  //       } else {
  //         // If no matching product found, set TIDNumber to null
  //         updatedProduct.TIDNumber = null;
  //       }
  //     }

  //     // // Find a matching product in the rifdData array
  //     // const matchingProduct = rifdData.find(
  //     //   (item) => item.BarcodeNumber === barcodeValue
  //     // );

  //     // if (matchingProduct) {
  //     //   updatedProduct.TIDNumber = matchingProduct.TidValue;
  //     // } else {
  //     //   // If no matching product found, set 'tid' to null or some default value
  //     //   updatedProduct.TIDNumber = null; // or any default value you want
  //     //   // setBarCodeAlert(true);
  //     // }
  //   }

  //   // If 'grosswt' is changed, calculate 'netWt'
  //   if (property === "GrossWt" && !isNaN(value)) {
  //     updatedProduct.NetWt =
  //       parseFloat(value) -
  //         parseFloat(updatedProduct.ClipWeight) -
  //         parseFloat(updatedProduct.TotalStoneWeight) >
  //         0
  //         ? (
  //           parseFloat(value) -
  //           parseFloat(updatedProduct.ClipWeight) -
  //           parseFloat(updatedProduct.TotalStoneWeight)
  //         ).toFixed(3)
  //         : (updatedProduct.GrossWt = value);
  //   }

  //   // Parse updated properties to numbers, defaulting to 0 if empty or invalid
  // // const grosswt = parseFloat(product.GrossWt) || 0;
  // // const clipWeight = parseFloat(product.ClipWeight) || 0;
  // // const totalFilteredGrossWt = allFilteredPurchaseItems.reduce((acc, item) => {
  // //   return acc + parseFloat(item.GrossWt || 0);
  // // }, 0);
  // // // const stoneWeight = parseFloat(product.TotalStoneWeight) || 0;
  // // // const netWt = parseFloat(product.NetWt) || 0;

  // // // Update 'GrossWt' -> recalculate 'NetWt'
  // // if (property === "GrossWt" && !isNaN(value)) {
  // //   if(value>totalFilteredGrossWt){
  // //     // alert("Error: Total GrossWt of all items exceeds the available GrossWt.");
  // //     updatedProduct.GrossWt = 0;
  // //     updatedProduct.NetWt = 0;
  // //   }else{
  // //     updatedProduct.NetWt =
  // //     parseFloat(value) - parseFloat(clipWeight) - parseFloat(stoneWeight) > 0
  // //       ? (
  // //           parseFloat(value) -
  // //           parseFloat(clipWeight) -
  // //           parseFloat(stoneWeight)
  // //         ).toFixed(3)
  // //       : 0;
  // //   }
  // // }

  // // // Update 'TotalStoneWeight' -> recalculate 'NetWt'
  // // if (property === "TotalStoneWeight" && !isNaN(value)) {
  // //   updatedProduct.NetWt =
  // //     grosswt > parseFloat(value)
  // //       ? (grosswt - parseFloat(value)).toFixed(3)
  // //       : 0;
  // // }
  // // // console.log('propertyvalue ',property, '  ', value , updatedProduct.)

  // // // Update 'ClipWeight' -> recalculate 'NetWt'
  // // if (property === "ClipWeight" && !isNaN(value)) {
  // //   if (grosswt > clipWeight) {
  // //     // If clip weight is valid, recalculate net weight
  // //     updatedProduct.NetWt = (grosswt - (clipWeight + stoneWeight)).toFixed(3);
  // //   } else {
  // //     // If clip weight exceeds gross weight, reset values
  // //     // updatedProduct.GrossWt = (clipWeight + stoneWeight).toFixed(3);
  // //     updatedProduct.NetWt = 0;
  // //     updatedProduct.ClipWeight = 0
  // //   }
  // // }

  // // // Update 'NetWt' -> recalculate 'GrossWt' and 'TotalStoneWeight'
  // // if (property === "NetWt" && !isNaN(value)) {
  // //   updatedProduct.GrossWt = (
  // //     parseFloat(value) + parseFloat(clipWeight) + stoneWeight
  // //   ).toFixed(3);
  // //   updatedProduct.TotalStoneWeight = (
  // //     grosswt - parseFloat(value) - parseFloat(clipWeight)
  // //   ).toFixed(3);
  // // }



  //   if (property === "TotalGrossWt" && !isNaN(value)) {


  //     updatedProduct.TotalNetWt =
  //       parseFloat(value) - parseFloat(updatedProduct.TotalStoneWeight) > 0
  //         ? (
  //           parseFloat(value) -
  //           parseFloat(updatedProduct.ClipWeight) -
  //           parseFloat(updatedProduct.TotalStoneWeight)
  //         ).toFixed(3)
  //         : (updatedProduct.TotalGrossWt = value);
  //   }

  //   // If 'stoneWeight' is changed, calculate 'netWt'
  //   if (property === "TotalStoneWeight" && !isNaN(value)) {
  //     updatedProduct.NetWt =
  //       parseFloat(updatedProduct.GrossWt) > value
  //         ? (updatedProduct.GrossWt - parseFloat(value)).toFixed(3)
  //         : ((updatedProduct.GrossWt = value),
  //           (updatedProduct.TotalStoneWeight = value),
  //           (updatedProduct.NetWt = 0));
  //     // updatedProduct.stoneWeight = value
  //     // updatedProduct.TotalStoneWeight= value
  //   }
  //   if (property === "ClipWeight" && !isNaN(value)) {
  //     const clipWeight = parseFloat(value);
  //     if (grosswt > clipWeight) {
  //       // If gross weight is greater than the clip weight, update net weight
  //       updatedProduct.NetWt = parseFloat(
  //         grosswt - (stoneWeight + clipWeight)
  //       ).toFixed(3);
  //       updatedProduct.ClipWeight = clipWeight;
  //       updatedProduct.ClipQuantity = "1";
  //     } else {
  //       // If clip weight is greater or equal to the gross weight, adjust accordingly
  //       updatedProduct.GrossWt = parseFloat(clipWeight + stoneWeight).toFixed(
  //         3
  //       );
  //       updatedProduct.ClipWeight = clipWeight;
  //       updatedProduct.ClipQuantity = "1";
  //       updatedProduct.NetWt = 0; // Set net weight to 0 if conditions dictate
  //     }
  //   }

  //   // If 'netWt' is changed, calculate 'grosswt' and 'stoneWeight'
  //   if (property === "NetWt" && !isNaN(value)) {
  //     updatedProduct.GrossWt = (
  //       parseFloat(value) +
  //       parseFloat(updatedProduct.ClipWeight) +
  //       stoneWeight
  //     ).toFixed(3);
  //     updatedProduct.TotalStoneWeight = (
  //       grosswt -
  //       parseFloat(value) -
  //       parseFloat(updatedProduct.ClipWeight)
  //     ).toFixed(3);
  //   }

  //   if (property === "TotalNetWt" && !isNaN(value)) {
  //     updatedProduct.TotalGrossWt = (
  //       parseFloat(value) +
  //       stoneWeight +
  //       parseFloat(updatedProduct.ClipWeight)
  //     ).toFixed(3);
  //     updatedProduct.TotalStoneWeight = (grosswt - parseFloat(value)).toFixed(
  //       3
  //     );
  //   }
  //   if (property === "Pieces" && value > 1 && stockType === "Labelled") {
  //     setPiecesBox(true);
  //     setProductPiecesEditId(index);
  //     handlePiecesChange(value, index);
  //   }

  //    // Second case: check if total GrossWt does not exceed filtered GrossWt

  //    updatedProducts[index] = updatedProduct;

  // const totalCurrentGrossWt = updatedProducts.reduce((acc, item) => {
  //   return acc + parseFloat(item.GrossWt || 0);
  // }, 0);

  // if (totalCurrentGrossWt > totalFilteredGrossWt) {
  //   alert("Error: Total GrossWt of all items exceeds the available GrossWt.");
  //   return; // Stop further execution
  // }

  // console.log('checking updateproducts ',updatedProducts );


  //   setAddedProducts(updatedProducts);
  // };

  // ... (rest of the code)




  const playTimer = () => {
    setTimeout(() => {
      setGoldAlert(false), setBarCodeAlert(false), setImportAlert(false);
    }, 2000);
  };

  function findClosestHigherDiamondWeight(
    data,
    inputWeight,
    inputShape,
    inputClarity
  ) {
    // Convert inputWeight to a number
    const positiveInputWeight = parseFloat(inputWeight);

    // Filter and sort the weights
    const higherWeights = data
      .map((item) => {
        // Convert DiamondWeight to a positive number
        const positiveWeight = Math.abs(parseFloat(item.DiamondWeight));
        return {
          ...item,
          DiamondWeight: positiveWeight,
        };
      })
      // Filter weights greater than input weight
      .filter((item) => item.DiamondShape === inputShape)
      .filter((item) => item.DiamondClarity === inputClarity)
      .filter((item) => item.DiamondWeight == positiveInputWeight)
      // Sort in ascending order
      .sort((a, b) => a.DiamondWeight - b.DiamondWeight);
    // Get the closest higher weight
    return higherWeights.length > 0 ? higherWeights[0] : null;
  }


  // Function to find the closest weight category based on the GrossWt
  function findClosestWeightCategory(grossWt, weightCategoriesArray) {
    // Sort weight categories in ascending order
    weightCategoriesArray.sort((a, b) => a - b);

    // Initialize closest category with the first element
    let closestCategory = weightCategoriesArray[0];

    // Loop through each category to find the closest
    for (let i = 0; i < weightCategoriesArray.length; i++) {
      let currentCategory = weightCategoriesArray[i];

      // If the grossWt is less than or equal to the current category, assign it
      if (grossWt <= currentCategory) {
        closestCategory = currentCategory;
        break;
      }

      // If the grossWt is slightly higher, find the closest match based on distance
      if (grossWt > currentCategory) {
        if (i < weightCategoriesArray.length - 1) {
          const nextCategory = weightCategoriesArray[i + 1];

          if (grossWt <= nextCategory) {
            closestCategory = (grossWt - currentCategory) <= (nextCategory - grossWt)
              ? currentCategory
              : nextCategory;
            break;
          }
        }
      }
    }

    return closestCategory;
  }

  const handleEditProducts = async () => {
    setLoading(true);
    setReadOnly(false);
    console.log(addedProducts, "save:label");

    try {
      // Validate 'grosswt' for all products
      const hasInvalidGrossWt = addedProducts.some(
        (product) =>
          (product.GrossWt === "" && product.CategoryId == 1) ||
          (parseFloat(product.GrossWt) === 0 && product.CategoryId == 1)
      );

      const hasMissingBarcodeAndTid = addedProducts.some((product) => {
        if (product.RFIDCode && product.RFIDCode.length !== 0) {
          // Barcode is not empty or null, so check if tid is missing
          return product.TIDNumber === null || product.TIDNumber === "";
        }
        // Barcode is either empty or null, so no need to check tid
        return false;
      });

      if (hasInvalidGrossWt) {
        setLoading(false);
        setGoldAlert(true);
        playTimer();
      } else if (hasMissingBarcodeAndTid) {
        setLoading(false);
        setBarCodeAlert(true);
        playTimer();
      } else {
        // Convert grosswt, stoneWeight, and netWt to strings before sending

        const updatedProductsString = addedProducts.map((product) => {
          // Calculate total stone weight
          // let totalStoneWeight = 0;
          // if (product.Stones.length > 0) {
          //   totalStoneWeight = product.Stones.reduce((a, b) => {
          //     const stoneWeight = parseFloat(b.StoneWeight);
          //     return a + stoneWeight;
          //   }, 0);
          // }
          // totalStoneWeight = parseFloat(totalStoneWeight).toFixed(3) ||product.TotalStoneWeight ;

          let totalStoneWeight = 0;
          if (product.Stones.length > 0) {
            totalStoneWeight = product.Stones.reduce((total, stone) => {
              // Ensure StoneWeight and pieces are valid numbers
              const stoneWeight = parseFloat(stone.StoneWeight) || 0;
              const pieces = parseFloat(product.Pieces) || 1; // Default to 1 if pieces is not provided

              return total + (stoneWeight * pieces);
            }, 0);
          }

          // If totalStoneWeight is 0, use product.TotalStoneWeight, otherwise use the calculated value
          totalStoneWeight =
            totalStoneWeight > 0
              ? parseFloat(totalStoneWeight).toFixed(3)
              : product.TotalStoneWeight;


          // Determine the nearest weight category based on the product's GrossWt
          let weightCategory = null; // Initialize as null

          if (selectedSkuName !== "" && selectedSku) {
            let wt = selectedSku.WeightCategories;

            if (wt) {
              if (!product.WeightCategory) {
                // Split weight categories string into an array of numbers
                const weightCategoriesArray = wt.split(',').map(Number);

                // Find the closest weight category
                const grossWt = parseFloat(product.GrossWt);

                // Ensure that findClosestWeightCategory returns a valid number or null
                weightCategory = findClosestWeightCategory(grossWt, weightCategoriesArray) || null;
              } else {
                weightCategory = product.WeightCategory ? parseFloat(product.WeightCategory) : null;
              }
            }
          }
          const updatedStones = product.Stones.map((stone) => ({
            ...stone,
            StonePieces: stone.StonePieces.toString(),
          }));
          return {
            ...product,
            GrossWt: !grossWithClip
              ? product.GrossWt.toString()
              : parseFloat(
                parseFloat(product.GrossWt) - parseFloat(product.ClipWeight)
              )
                .toFixed(3)
                .toString(),
            TotalStoneWeight: totalStoneWeight,
            NetWt: product.NetWt.toString(),
            ClipWeight: product.ClipWeight.toString(),
            WeightCategory: weightCategory,
            Stones: updatedStones,
          };
        });

        // const updatedProductsString = addedProducts.map((product) => ({
        //   ...product,
        //   GrossWt: !grossWithClip
        //     ? product.GrossWt.toString()
        //     : parseFloat(
        //         parseFloat(product.GrossWt) - parseFloat(product.ClipWeight)
        //       )
        //         .toFixed(3)
        //         .toString(),

        //         TotalStoneWeight: totalStoneWeight,
        //   TotalStoneWeight:
        //     product.Stones.length > 0
        //       ? product.Stones.reduce(
        //           (a, b) =>
        //             parseFloat(a + parseFloat(b.StoneWeight)).toFixed(3),
        //           0
        //         )
        //       : product.TotalStoneWeight.toString(),
        //   NetWt: product.NetWt.toString(),
        //   ClipWeight: product.ClipWeight.toString(),
        // }));

        let editProduct = false;
        if (updatedProductsString && updatedProductsString[0].Id) {
          editProduct = true;
        } else {
          editProduct = false;
        }
        console.log(
          "updatedProductsStringupdatedProductsString : ",
          updatedProductsString
        );

        // Send the updated products to the edit API endpoint
        // const transformedData = updatedProductsString.map((item,ind))
        // const response = await fetch(a177, {
        const response = await fetch(!editProduct ? a217 : a177, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedProductsString),
        });
        const rcvdData = await response.json();
        //       openLabelInNew(rcvdData.data);
        //       console.log("addedProducts", addedProducts);
        //       console.log("updatedProducts", updatedProducts);
        //       // setUpdatedProducts(rcvdData.data);
        //       setLoading(false);
        //     }
        //   } catch (error) {
        //     alert(error);
        //     console.error(error);
        //     setLoading(false);
        //   }
        // };
        if (rcvdData.Message) {
          setLoading(false);
          alert(rcvdData.Message); // Show error message
          const productsWithErrors = addedProducts.map((product) =>
            product.RFIDCode === rcvdData.errorBarcode
              ? { ...product, hasError: true }
              : product
          );
          setAddedProducts(productsWithErrors);
          // console.log("rcvdDataErrorAdded", addedProducts);
        } else {
          // openLabelInNew(rcvdData);
          GenerateLabel(rcvdData, labelFormat);
          setAddedProducts(rcvdData);
          // Fetch all labelled stock after saving items
          const labelledStockResponse =
            await apiService.fetchAllLabelledStock();
          setAllLabelledStockData(labelledStockResponse); // Update state with labelled stock data
          setHasUnsavedChanges(false);
          // console.log("addedProducts", addedProducts);
          // console.log("updatedProducts", updatedProducts);
          setLoading(false);
        }
      }
    } catch (error) {
      alert(error);
      console.error(error);
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     if (hasUnsavedChanges) {
  //       const message =
  //         "You have unsaved changes. Are you sure you want to leave?";
  //       event.returnValue = message;
  //       return message;
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [hasUnsavedChanges]);

  const handleEditProductsUnlabelled = async () => {
    setLoading(true);
    setReadOnly(false);
    try {
      const updatedProductsString = addedProducts.map((product) => ({
        ...product,
        TotalGrossWt: product.TotalGrossWt.toString(),
        TotalStoneWeight: product.TotalStoneWeight.toString(),
        TotalNetWt: product.TotalNetWt.toString(),
        MRP: product.MRP.toString(),
        Pieces: `${parseInt(product.pieces)}`,
      }));
      const updatedProductsString2 = updatedProductsString.map((product) => {
        // Filter out properties with null values
        const filteredProduct = Object.fromEntries(
          Object.entries(product).filter(([key, value]) => value !== null)
        );
        return filteredProduct;
      });
      const createFormData = (data) => {
        const formData = new FormData();
        for (const key in data) {
          if (data.hasOwnProperty(key)) {
            formData.append(key, data[key]);
          }
        }
        return formData;
      };

      // Assuming updatedProductsString2[0] contains the data you want to send
      const formData = createFormData(updatedProductsString2[0]);
      // Send the updated products to the edit API endpoint
      // const formData = new FormData();
      // formData.append("Data", updatedProductsString2)
      const response = await fetch(a199, {
        method: "POST",
        body: formData,
      });
      const rcvdData = await response.json();
      setHasUnsavedChanges(true);
      setStockType("Labelled");
      setAddedProducts([rcvdData]);
      setAddedProducts([]);
      alert("Unlabelled Stock Added");
      scrollToCenter("addBulkProductsBoxTop");
      //       openLabelInNew(rcvdData.data);
      //       console.log("addedProducts", addedProducts);
      //       console.log("updatedProducts", updatedProducts);
      //       // setUpdatedProducts(rcvdData.data);
      //       setLoading(false);
      //     }
      //   } catch (error) {
      //     alert(error);
      //     console.error(error);
      //     setLoading(false);
      //   }
      // };
      if (rcvdData.status === "error") {
        setLoading(false);
        alert(rcvdData.message); // Show error message
      } else {
        // console.log("updatedProducts", updatedProducts);
        setLoading(false);
      }
    } catch (error) {
      alert(error);
      console.error(error);
      setLoading(false);
    }
  };
  // console.log("2addedProducts", addedProducts);
  const filteredProducts = productTypeData.filter(
    (product) => product.CategoryId == baseMetal
  );
  const filteredCollection = collectionTypeData.filter(
    (product) => product.ProductId == productTypeId
  );
  const filteredPurity = purityData.filter(
    (product) => product.CategoryId == baseMetal
  );
  const filteredBoxes = boxData.filter((box) => box.BoxId == bid);

  const handleCheckboxChange = (productId, itemCode) => {
    let updatedCheckedProducts = [...checkedProducts];
    let updatedSelectedItemCodes = [...selectedItemCodes];

    if (updatedCheckedProducts.includes(productId)) {
      updatedCheckedProducts = updatedCheckedProducts.filter(
        (id) => id !== productId
      );
      updatedSelectedItemCodes = updatedSelectedItemCodes.filter(
        (code) => code !== itemCode
      );
    } else {
      updatedCheckedProducts.push(productId);
      updatedSelectedItemCodes.push(itemCode);
    }

    if (updatedCheckedProducts.length > 0) {
      setDeleteSelected(true);
    } else {
      setDeleteSelected(false);
    }

    setCheckedProducts(updatedCheckedProducts);
    setSelectedItemCodes(updatedSelectedItemCodes);
  };

  const selectedItems = selectedItemCodes.map((itemCode) => ({
    ItemCode: itemCode,
  }));

  // console.log("checkedProducts", checkedProducts);
  // console.log("selectedItemCodes", selectedItemCodes);
  // console.log("selectedItems", selectedItems);

  const deleteAllProducts = async (itemsToDelete) => {
    setReadOnly(false);
    const deletAllItemsList = itemsToDelete.map((x) => {
      return { ...x, ClientCode: clientCode };
    });
    // const deletAllItemsList = itemsToDelete.map(x => ({...x, ClientCode: clientCode}));

    try {
      const response = await fetch(a178, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(deletAllItemsList),
      });

      const rcvdData = await response.json();
      // console.log("AllItemsDeleted", rcvdData);
      if (response.ok) {
        // Deletion was successful
        // console.log("Item deleted successfully:", response.message);
        // alert(rcvdData.message);
        setSelectedItemCodes([]);
        setCheckedProducts([]);
        scrollToCenter("addBulkProductsBoxTop");
        // You can show an alert or notification here
        // alert(data.message);
        if (itemsToDelete == allItemCodesArray) {
          setAddedProducts([]);
        } else {
          const updatedAddedProducts = addedProducts.filter((product) => {
            return !itemsToDelete.some(
              (item) => item.ItemCode === product.ItemCode
            );
          });
          setAddedProducts(updatedAddedProducts);
        }
        setDeleteAll(false);
        setDeleteSelected(false);
      } else {
        // Handle the case where deletion failed
        console.error("Failed to delete item:", response.message);

        // You can show an error message to the user
        alert("Failed to delete item: " + response.message);
      }
    } catch (error) {
      // Handle any network or fetch API errors
      console.error("An error occurred:", error);

      // Show an error message to the user
      // alert("An error occurred while deleting the item.");
    }
  };
  const showAllInputs = () => {
    setHalfInputs(!halfInputs);
    const allFields = document.getElementById("bulkStockAddProductDetailsBox");
    if (halfInputs) {
      allFields.classList.add("bulkStockAddProductDetailsBoxHalfHeight");
      allFields.classList.remove("bulkStockAddProductDetailsBoxFullHeight");
    } else {
      allFields.classList.remove("bulkStockAddProductDetailsBoxHalfHeight");
      allFields.classList.add("bulkStockAddProductDetailsBoxFullHeight");
    }
  };

  // const handleSkuInputChange = (e) => {
  //   const { value } = e.target;
  //   const uppercaseValue = value.toUpperCase();
  //   setSelectedSkuName(uppercaseValue);
  //   let selectedSkuItem = [];
  //   // selectedSkuItem = allSku.find((x) => x.StockKeepingUnit == uppercaseValue);
  //   selectedSkuItem = allFilteredPurchaseItems.find((x) => x.StockKeepingUnit == uppercaseValue);

  //   // setSelectedSku(selectedSkuItem);
  //   if (selectedSkuItem) {
  //     setSelectedSkuName(uppercaseValue);
  //     setSelectedSku(selectedSkuItem);
  //   } 
  //   // else {
  //   //   setSelectedSkuName(""); // Reset if SKU doesn't exist
  //   // }
  // };


  const handleSkuInputChange = (e) => {
    const enteredSku = e.target.value;
    // const uppercaseValue = value.toUpperCase();
    // Use contains logic for filtering the SKU list during input
    const partialMatch = filteredsku.filter((sku) =>
      sku.StockKeepingUnit.toLowerCase().includes(enteredSku.toLowerCase())
    );

    // Update the SKU name with partial matching during input
    setSelectedSkuName(enteredSku);

    // Update the filtered SKU list based on partial match
    setFilteredsku(partialMatch);
  };

  const handleSkuSelect = (e) => {
    const selectedSku = e.target.value;

    // Use exact match logic when user selects from the dropdown
    const exactMatch = filteredsku.find(
      (sku) => sku.StockKeepingUnit === selectedSku
    );

    console.log('check selected sku ', exactMatch)

    // If exact match is found, set the SKU name, otherwise reset it
    if (exactMatch) {
      setSelectedSkuName(selectedSku);
      setSelectedSku(exactMatch);
    } else {
      setSelectedSkuName(""); // Reset if no exact match
      setSelectedSku([]);
    }
  };


  useEffect(() => {
    if (selectedSkuName !== "" && selectedSku) {
      if (selectedSku.WeightCategories) {
        const parsedWeights = selectedSku.WeightCategories.split(",")
          .map(Number)
          .filter((num) => !isNaN(num)); // Ensures only valid numbers are included
        setSelectedweights(parsedWeights);
      } else {
        setSelectedweights([]);
      }

      const matchingCollection = collectionmainlist.find(
        (collectionObj) => collectionObj.Collection.Id === selectedSku.CollectionId
      );

      const matchingCollection1 = collectionmainlist.find(
        (collectionObj) => collectionObj.Collection.Id == selectedSku.CollectionId
      );

      console.log('checking sku ', selectedSku, '  ', matchingCollection1)

      if (matchingCollection) {
        // Set the CollectionName to the selected collection's name
        setCollectionmain(
          `${parseInt(matchingCollection.Collection.Id)},${matchingCollection.Collection.CollectionName}`
        );
      } else {
        // Handle case where no matching collection is found
        setCollectionmain(`${selectedSku.CollectionId}, ''`);
      }


      setDescription(selectedSku.Description);
      setNetWt(selectedSku.NetWt);
      setBoxId(0);
      setPartyTypeId(selectedSku.VendorId);
      // categoryName = selectedSku.category;
      // productTypeName = selectedSku.productType;
      // collectionName = selectedSku.collection;
      // purityName = selectedSku.purity;
      setCategory(`${selectedSku.CategoryId}`);
      setBaseMetal(`${selectedSku.CategoryId}`);
      setProductType(`${selectedSku.ProductId},${selectedSku.ProductName}`);
      setBoxType(`${selectedSku.BoxId},${selectedSku.BoxName}`);
      setCollection(`${selectedSku.DesignId},${selectedSku.DesignName}`);
      // setCollectionmain(
      //   `${selectedSku.CollectionId},${selectedSku.CollectionNameSKU}`
      // );
      setPurity(`${selectedSku.PurityId},${selectedSku.PurityName}`);
      // categoryId = selectedSku.categoryId;
      // productTypeId = selectedSku.productTypeId;
      // purityId = selectedSku.purityId;
      // collectionId = selectedSku.collectionId;
      setSize(selectedSku.Size);
      setGrosswt(selectedSku.GrossWt);
      setPieces(selectedSku.Pieces);
      // setStoneWeight(
      //   parseFloat(selectedSku.TotalStoneWeight) *
      //     parseFloat(selectedSku.Pieces)
      // );
      setSelectedFiles(selectedSku.Images);
      // setSelectedFiles(selectedSku.images);
      setMaking_Percentage(selectedSku.MakingPercentage);
      setMaking_Fixed_Amt(selectedSku.MakingFixedAmt);
      setMaking_per_gram(selectedSku.MakingPerGram);
      setMaking_Fixed_Wastage(selectedSku.MakingFixedWastage);
      setMRP(selectedSku.MRP);
      if (selectedSku.SKUStoneMain && selectedSku.SKUStoneMain.length > 0) {
        setAllSelectedSkuStones(selectedSku.SKUStoneMain);

        const updatedStonesList = [
          ...allStonesListmain,
          ...(Array.isArray(selectedSku.SKUStoneMain) ? selectedSku.SKUStoneMain : []),
        ];

        // Set the combined list
        setAllStonesList(updatedStonesList);
        console.log('check suk stones', updatedStonesList)

      } else {
        setAllSelectedSkuStones([]);
        setAllStonesList(allStonesListmain);
      }



      setAllSelectedSkuDiamonds(selectedSku.Diamonds);
      if (
        selectedSku.ClipWeight == 0 ||
        selectedSku.ClipWeight == "0" ||
        selectedSku.ClipWeight == "0.00" ||
        selectedSku.ClipWeight == "0.000"
      ) {
        setClipWeight(selectedSku.ClipWeight);
        setGrossWithClip(false);
      } else {
        setClipWeight(selectedSku.ClipWeight);

        setGrossWithClip(true);
      }
      const allFilteredPurchaseItemsList = allPurchaseItems.filter(
        (x) => x.SKUId === selectedSku.Id
      );
      // setAllFilteredPurchaseItems(allFilteredPurchaseItemsList);
    } else {
      setDeleteAll(true);
      // setPartyTypeId("");
      setCategory("");
      setBaseMetal("");
      setProductType("");
      setBoxType("");

      setPurity("");
      setQuantity(1);
      setCollection("");
      setCollectionmain("");
      setGrosswt(0);
      setNetWt(0);
      setClipWeight(0);
      setGender("");
      setStoneWeight(0);
      setMRP(0);
      setBoxId(0);
      setProductName("");
      setDescription("");
      setSelectedFiles([]);
      // setAllFilteredPurchaseItems(allPurchaseItems);
      setGrossWithClip(false);
      setAllStonesList(allStonesListmain);
    }
  }, [selectedSku]);

  // useEffect(() => {
  //   const allFilteredPurchaseItemsList = allPurchaseItems.filter(
  //     (x) => x.VendorId === parseInt(partyTypeId)
  //   );
  //   setAllFilteredPurchaseItems(allFilteredPurchaseItemsList);
  // }, [partyTypeId]);

  const uploadExcelFile = async () => {
    if (importFile) {
      const formData = new FormData();
      formData.append("file", importFile);

      fetch(a90, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          const contentType = response.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return response.json().then((data) => ({
              status: response.status,
              data,
            }));
          } else {
            return response.text().then((text) => ({
              status: response.status,
              data: text,
            }));
          }
        })
        .then(({ status, data }) => {
          alert("Imported Data");
          setShowImportPopup(false);
          // Handle success response
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle errors
        });
    } else {
      alert("Please select a file.");
    }
  };

  const handleSelectedSkuStoneChange = (e) => {
    if (e.target.value !== "") {
      const selectedStone = allSelectedSkuStones.find(
        (stone) => stone.Id == e.target.value
      );
      setSelectedSkuStones(selectedStone);
      setStoneWeight(
        (
          parseFloat(selectedStone.StoneMainWeight) *
          parseFloat(selectedSku.Pieces)
        ).toFixed(3)
      );
      setNetWt(
        (
          parseFloat(grosswt) -
          parseFloat(selectedStone.StoneMainWeight) *
          parseFloat(selectedSku.Pieces)
        ).toFixed(3)
      );
      setStoneAmount(
        (
          parseFloat(selectedStone.StoneMainAmount) *
          parseFloat(selectedSku.Pieces)
        ).toFixed(2)
      );
    } else {
      setSelectedSkuStones({ Id: 0 });
    }
  };

  const handleSelectedweights = (e) => {
    const value = e.target.value;

    if (value !== "") {
      setWeights(parseFloat(value)); // Set the selected weight
    } else {
      setWeights(0); // Set to default if empty value is selected
    }
  };

  async function fetchAllStonesList() {
    const formData = { ClientCode: clientCode };
    try {
      const response = await fetch(a146, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      setAllStonesListmain(data);
      setAllStonesList(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchAllDiamondsList() {
    const formData = { ClientCode: clientCode };
    try {
      const response = await fetch(a153, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      const data = await response.json();
      setAllDiamondsList(data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchAllStonesList();
    fetchAllDiamondsList();
  }, []);
  useEffect(() => {
    if (selectedSku) {
      // setAllStonesList(selectedSku.SKUStoneMain);
    } else {
      fetchAllStonesList();
    }
  }, [selectedSku]);
  // const handleStoneChange = (index, property, value) => {
  //   const newStones = newStonesList;
  //   const selectedStone = allStonesList.find(
  //     (stone) => stone.StoneName === value
  //   );
  //   console.log(selectedStone, "selected Stone");
  //   console.log(selectedStone, "selected Stone");

  //   if (selectedStone) {
  //     newStones[index] = {
  //       ...newStones[index],
  //       StoneName: selectedStone.StoneName,
  //       StoneWeight: selectedStone.StoneWeight, // Assuming these fields exist in your stone objects
  //       StonePieces: selectedStone.StonePieces,
  //       StoneRate: selectedStone.StoneRate,
  //       StoneAmount: selectedStone.StoneAmount, // Calculate or pull this value as required
  //       Description: selectedStone.Description, // Assuming a description field exists
  //     };
  //   } else {
  //     newStones[index] = {
  //       ...newStones[index],
  //       [property]: value,
  //     };
  //   }

  //   return setNewStonesList(newStones);
  // };
  function getShapeValue(id, shape) {
    if (id) {
      const shapeValue = allDiamondAttributes
        .filter((x) => x.DiamondAttribute == "DiamondShape")
        ?.find((item) => item.Id == id);
      return id ? shapeValue?.DiamondValue : "";
    }
    if (shape) {
      const shapeValue = allDiamondAttributes
        .filter((x) => x.DiamondAttribute == "DiamondShape")
        ?.find((item) => item.DiamondValue == shape);
      return shape ? shapeValue?.Id : "";
    }
  }

  function getDiamondClarity(id, clarity) {
    if (id) {
      const clarityValue = allDiamondAttributes
        .filter((x) => x.DiamondAttribute == "DiamondClarity")
        ?.find((item) => item.Id == id);
      return id ? clarityValue?.DiamondValue : "";
    }
    if (clarity) {
      const clarityValue = allDiamondAttributes
        .filter((x) => x.DiamondAttribute == "DiamondClarity")
        ?.find((item) => item.DiamondValue == clarity);
      return clarity ? clarityValue?.Id : "";
    }
  }

  const [selectedStone, setSelectedStone] = useState(null);
  // const handleStoneChange = (stoneIndex, field, value) => {
  //   setAddedProducts((prevProducts) => {
  //     const updatedProducts = prevProducts.map((product, index) => {
  //       if (index !== selectedProductIndex) return product;

  //       const updatedStones = product.Stones.map((stone, sIndex) => {
  //         if (sIndex !== stoneIndex) return stone;

  //         // Handle stone updates based on the field
  //         if (field === "StoneName") {
  //           const selectedStone = allStonesList.find(
  //             (stone) =>
  //               (stone.StoneName ? stone.StoneName : stone.StoneMainName) ===
  //               value
  //           );

  //           let StonePiecest = 0;

  //           if (selectedStone) {

  //             StonePiecest = 0;
  //             selectedStone.StonePieces !== null && selectedStone.StonePieces !== undefined
  //               ? selectedStone.StonePieces
  //               : selectedStone.StoneMainPieces !== null && selectedStone.StoneMainPieces !== undefined
  //                 ? selectedStone.StoneMainPieces
  //                 : 1;

  //             if (StonePiecest == 0) {
  //               StonePiecest = 1;
  //             }


  //             return {
  //               ...stone,
  //               StoneName: selectedStone.StoneName
  //                 ? selectedStone.StoneName
  //                 : selectedStone.StoneMainName,
  //               StoneWeight: selectedStone.StoneWeight
  //                 ? selectedStone.StoneWeight
  //                 : selectedStone.StoneMainWeight,
  //               StonePieces: StonePiecest,
  //               StoneRate: selectedStone.StoneRate
  //                 ? selectedStone.StoneRate
  //                 : selectedStone.StoneMainRate,
  //               StoneAmount: selectedStone.StoneAmount
  //                 ? selectedStone.StoneAmount
  //                 : selectedStone.StoneMainAmount,
  //               Description: selectedStone.Description
  //                 ? selectedStone.Description
  //                 : selectedStone.StoneMainDescription,
  //             };
  //           }
  //         }

  //         const rate = parseFloat(stone.StoneWeight) *
  //           parseFloat(stone.StoneRate);

  //         stone.StoneAmount = rate;

  //         // Update the stone with the new value for the given field
  //         return { ...stone, [field]: value };
  //       });

  //       // Calculate totals
  //       const productPieces = parseFloat(product.Pieces || 0);

  //       const totalStoneWeight = updatedStones
  //         .reduce(
  //           (total, stone) =>
  //             total + parseFloat(stone.StoneWeight || 0) * productPieces,
  //           0
  //         )
  //         .toFixed(3);

  //       const totalStoneAmount = updatedStones
  //         .reduce(
  //           (total, stone) =>
  //             total + parseFloat(stone.StoneAmount || 0) * productPieces,
  //           0
  //         )
  //         .toFixed(2);

  //       const totalNetWt = parseFloat(
  //         parseFloat(product.GrossWt) - parseFloat(totalStoneWeight)
  //       ).toFixed(3);

  //       const totalStonePieces = (
  //         updatedStones.reduce(
  //           (total, stone) => total + parseFloat(stone.StonePieces || 1),
  //           0
  //         ) || 0
  //       ).toString();




  //       return {
  //         ...product,
  //         Stones: updatedStones,
  //         TotalStoneWeight: totalStoneWeight,
  //         TotalStoneAmount: totalStoneAmount,
  //         TotalStonePieces: totalStonePieces,
  //         NetWt: totalNetWt,
  //       };
  //     });

  //     console.log("Updated Products After Stone Change:", updatedProducts);
  //     return updatedProducts;
  //   });
  // };
  const handleDiamondChange = (diamondIndex, field, value) => {
    setAddedProducts((prevProducts) => {
      const updatedProducts = prevProducts.map((product, index) => {
        if (index !== selectedProductIndex) return product;

        const updatedDiamonds = product.Diamonds.map((diamond, sIndex) => {
          if (sIndex !== diamondIndex) return diamond;

          let updatedDiamond = { ...diamond, [field]: value };

          if (field === "DiamondSize") {
            const selectedDiamond = allDiamondSizeWeightRate.find(
              (diamond) => diamond.DiamondSize === value
            );
            if (selectedDiamond) {
              updatedDiamond = {
                ...diamond,
                ...selectedDiamond,
                DiamondSellAmount: "0",
                DiamondPurchaseAmount: "0",
                TotaldiamondWeight: "0",
                DiamondPieces: "0",
              };
            }
          } else if (field === "DiamondMargin") {
            const diamondPurchaseRate = parseFloat(
              diamond.DiamondPurchaseRate || 0
            );
            const diamondMargin = parseFloat(value || 0);
            const diamondSellRate = (
              diamondPurchaseRate *
              (1 + diamondMargin / 100)
            ).toFixed(2);

            updatedDiamond = {
              ...diamond,
              [field]: value,
              DiamondSellRate: diamondSellRate,
              DiamondSellAmount: "0",
              DiamondPurchaseAmount: "0",
              TotaldiamondWeight: "0",
              DiamondPieces: "0",
            };
          } else if (field === "DiamondPurchaseRate") {
            const diamondPurchaseRate = parseFloat(value || 0);
            const diamondMargin = parseFloat(diamond.DiamondMargin || 0);
            const diamondSellRate = (
              diamondPurchaseRate *
              (1 + diamondMargin / 100)
            ).toFixed(2);

            updatedDiamond = {
              ...diamond,
              [field]: value,
              DiamondSellRate: diamondSellRate,
              DiamondSellAmount: "0",
              DiamondPurchaseAmount: "0",
              TotaldiamondWeight: "0",
              DiamondPieces: "0",
            };
          } else if (field === "DiamondSellRate") {
            const diamondSellRate = parseFloat(value || 0);
            const diamondPurchaseRate = parseFloat(
              diamond.DiamondPurchaseRate || 0
            );

            if (diamondPurchaseRate !== 0) {
              const diamondMargin = (
                (diamondSellRate / diamondPurchaseRate - 1) *
                100
              ).toFixed(2);

              updatedDiamond = {
                ...diamond,
                [field]: value,
                DiamondMargin: diamondMargin,
                DiamondSellAmount: "0",
                DiamondPurchaseAmount: "0",
                TotaldiamondWeight: "0",
                DiamondPieces: "0",
              };
            } else {
              updatedDiamond = {
                ...diamond,
                [field]: value,
                DiamondSellAmount: "0",
                DiamondPurchaseAmount: "0",
                TotaldiamondWeight: "0",
                DiamondPieces: "0",
              };
            }
          } else if (field === "DiamondPieces") {
            const diamondPieces = parseFloat(value || "0");
            const diamondWeight = parseFloat(diamond.DiamondWeight || 0);
            const diamondPurchaseRate = parseFloat(
              diamond.DiamondPurchaseRate || 0
            );
            const diamondSellRate = parseFloat(diamond.DiamondSellRate || 0);

            updatedDiamond = {
              ...diamond,
              [field]: value,
              TotalDiamondWeight: (diamondWeight * diamondPieces).toFixed(3),
              DiamondPurchaseAmount: (
                diamondPurchaseRate * diamondPieces
              ).toFixed(2),
              DiamondSellAmount: (diamondSellRate * diamondPieces).toFixed(2),
            };
          }

          // Update DiamondPurchaseRate based on DiamondWeight, DiamondShape, or DiamondClarity
          // if (field === 'DiamondWeight' || field === 'DiamondShape' || field === 'DiamondClarity') {
          //     const diamondTemplate = allDiamondSizeWeightRate.find((template) => {
          //         return template.Id === diamondTemplateId;
          //     });
          //     if (diamondTemplate) {
          //         const shape = updatedDiamond.DiamondShape
          //             ? getShapeValue(null, updatedDiamond.DiamondShape)
          //             : null;
          //         const clarity = updatedDiamond.DiamondClarity
          //             ? getDiamondClarity(null, updatedDiamond.DiamondClarity)
          //             : null;

          //         const findedData = findClosestHigherDiamondWeight(
          //             diamondTemplate.DiamondSizeWeightRates,
          //             updatedDiamond.DiamondWeight,
          //             shape,
          //             clarity
          //         );
          //         if (findedData) {
          //             updatedDiamond.DiamondPurchaseRate = findedData.DiamondPurchaseRate;
          //         }
          //     }
          // }

          return updatedDiamond;
        });

        const productPieces = parseFloat(product.Pieces || "0");

        const totalDiamondWeight = updatedDiamonds
          .reduce(
            (total, stone) =>
              total + parseFloat(stone.TotalDiamondWeight || 0) * productPieces,
            0
          )
          .toFixed(3);

        const totalDiamondAmount = updatedDiamonds
          .reduce(
            (total, stone) =>
              total + parseFloat(stone.DiamondSellAmount || 0) * productPieces,
            0
          )
          .toFixed(2);

        const totalNetWt = parseFloat(
          parseFloat(product.GrossWt) -
          parseFloat(product.TotalStoneWeight) -
          parseFloat(totalDiamondWeight)
        ).toFixed(3);

        const totalDiamondPieces = (
          product.Diamonds.length * productPieces
        ).toString();

        return {
          ...product,
          Diamonds: updatedDiamonds,
          TotalDiamondWeight: totalDiamondWeight,
          TotalDiamondAmount: totalDiamondAmount,
          TotalDiamondPieces: totalDiamondPieces,
          NetWt: totalNetWt,
        };
      });
      return updatedProducts;
    });
  };

  const handleAddStone = () => {
    setAddedProducts((prevProducts) =>
      prevProducts.map((product, index) =>
        index === selectedProductIndex
          ? { ...product, Stones: [...product.Stones, {}] }
          : product
      )
    );
  };
  const handleAddDiamond = () => {
    setAddedProducts((prevProducts) =>
      prevProducts.map((product, index) =>
        index === selectedProductIndex
          ? { ...product, Diamonds: [...product.Diamonds, {}] }
          : product
      )
    );
  };
  // const deleteStone = (stoneIndex) => {
  //   setAddedProducts((prevProducts) =>
  //     prevProducts.map((product, index) =>
  //       index === selectedProductIndex
  //         ? {
  //           ...product,
  //           Stones: product.Stones.filter(
  //             (_, sIndex) => sIndex !== stoneIndex
  //           ),
  //         }
  //         : product
  //     )
  //   );
  // };
  const deleteDiamond = (diamondIndex) => {
    setAddedProducts((prevProducts) =>
      prevProducts.map((product, index) =>
        index === selectedProductIndex
          ? {
            ...product,
            Diamonds: product.Diamonds.filter(
              (_, sIndex) => sIndex !== diamondIndex
            ),
          }
          : product
      )
    );
    if (addedProducts[selectedProductIndex]?.Diamonds.length === 1) {
      setShowAddDiamondBox(false);
    }
  };
  const handleClose = () => {
    // setAddedProducts((prevProducts) =>
    //   prevProducts.map((product, index) => {
    //     if (index !== selectedProductIndex) return product;

    //     const filteredStones = product.Stones.filter(
    //       (stone) =>
    //         stone.StoneName &&
    //         stone.StoneWeight &&
    //         stone.StonePieces &&
    //         stone.StoneRate &&
    //         stone.StoneAmount &&
    //         stone.Description
    //     );

    //     return { ...product, Stones: filteredStones };
    //   })
    // );
    setShowAddStoneBox(false);
  };
  const handleCloseDiamond = () => {
    // setAddedProducts((prevProducts) =>
    //     prevProducts.map((product, index) => {
    //         if (index !== selectedProductIndex) return product;
    //
    //         const filteredDiamonds = product.Diamonds.filter(
    //             (diamond) =>
    //                 diamond.DiamondPieces &&
    //                 diamond.TotalDiamondWeight &&
    //                 diamond.DiamondWeight &&
    //                 diamond.DiamondSellAmount &&
    //                 diamond.DiamondSellRate &&
    //                 diamond.DiamondPurchaseAmount &&
    //                 diamond.DiamondPurchaseRate &&
    //                 diamond.DiamondMargin &&
    //                 diamond.DiamondSize
    //         );
    //
    //         return {...product, Diamonds: filteredDiamonds};
    //     })
    // );
    setShowAddDiamondBox(false);
  };
  useEffect(() => {
    if (selectedStone !== null) {
      const { index, stone } = selectedStone;
      const newStones = [...newStonesList];
      newStones[index] = {
        ...newStones[index],
        StoneName: stone.StoneName,
        StoneWeight: stone.StoneWeight,
        StonePieces: stone.StonePieces,
        StoneRate: stone.StoneRate,
        StoneAmount: stone.StoneAmount,
        Description: stone.Description,
      };
      setNewStonesList(newStones);
      setSelectedStone(null);
    }
  }, [selectedStone, newStonesList]);

  const handleEditgroupProducts = async () => {

    console.log('triggered');


    // event.preventDefault();
    setLoading(true);
    setReadOnly(false);

    try {
      // Validate 'grosswt' and 'RFIDCode/TIDNumber' for all products across all groups
      const hasInvalidGrossWt = groupproducts.some(group =>
        group.some(product =>
          (product.GrossWt === "" && product.CategoryId == 1) ||
          (parseFloat(product.GrossWt) === 0 && product.CategoryId == 1)
        )
      );

      const hasMissingBarcodeAndTid = groupproducts.some(group =>
        group.some(product => {
          if (product.RFIDCode && product.RFIDCode.length !== 0) {
            return product.TIDNumber === null || product.TIDNumber === "";
          }
          return false;
        })
      );

      if (hasInvalidGrossWt) {

        console.log('triggered ');

        // setErrorMessage("please enter grosswt")
        // alert('Please enter grosswt')
        // setLoading(false);
        return;


      } else if (hasMissingBarcodeAndTid) {
        setLoading(false);
        setBarCodeAlert(true);
        playTimer();
      } else {
        // Prepare transformed payload
        const transformedProducts = groupproducts.flatMap(group => {
          return group.map(product => {
            // Calculate totalStoneWeight from Stones
            let totalStoneWeight = 0;
            if (product.Stones.length > 0) {
              totalStoneWeight = product.Stones.reduce((total, stone) => {
                const stoneWeight = parseFloat(stone.StoneWeight) || 0;
                const pieces = parseFloat(product.Pieces) || 1;
                return total + (stoneWeight * pieces);
              }, 0);
            } else {
              totalStoneWeight =
                totalStoneWeight > 0
                  ? parseFloat(totalStoneWeight).toFixed(3)
                  : product.TotalStoneWeight;
            }






            // Determine the nearest weight category based on the product's GrossWt
            let weightCategory = null;
            if (selectedSkuName !== "" && selectedSku) {
              const wt = selectedSku.WeightCategories;
              if (wt) {
                if (!product.WeightCategory) {
                  const weightCategoriesArray = wt.split(",").map(Number);
                  const grossWt = parseFloat(product.GrossWt);
                  weightCategory = findClosestWeightCategory(grossWt, weightCategoriesArray) || null;
                } else {
                  weightCategory = product.WeightCategory ? parseFloat(product.WeightCategory) : null;
                }
              }
            }

            // Convert stones for API compatibility
            const updatedStones = product.Stones.map(stone => ({
              ...stone,
              StonePieces: stone.StonePieces ? stone.StonePieces.toString() : "0",
              StoneAmount: stone.StoneAmount ? stone.StoneAmount.toString() : "0",
            }));

            const updatedDiamonds = product.Diamonds.map(stone => ({
              ...stone,
              DiamondWeight: stone.DiamondWeight ? stone.DiamondWeight.toString() : "0",
              DiamondPieces: stone.DiamondPieces ? stone.DiamondPieces.toString() : "0",
              DiamondAmount: stone.DiamondAmount ? stone.DiamondAmount.toString() : "0",
              DiamondSize: stone.DiamondSize ? stone.DiamondSize.toString() : "0",
            }));



            return {
              ...product,
              GrossWt: !grossWithClip
                ? product.GrossWt.toString()
                : parseFloat(
                  parseFloat(product.GrossWt) - parseFloat(product.ClipWeight)
                )
                  .toFixed(3)
                  .toString(),
              TotalStoneWeight: totalStoneWeight.toString(),
              TotalStoneAmount: product.TotalStoneAmount.toString(),
              TotalDiamondAmount: product.TotalDiamondAmount.toString(),
              TotalDiamondWeight: product.TotalDiamondWeight.toString(),
              TotalStonePieces: product.TotalStonePieces ? product.TotalStonePieces.toString() : "0", // Convert to string here
              NetWt: product.NetWt.toString(),
              ClipWeight: product.ClipWeight.toString(),
              WeightCategory: weightCategory,
              Stones: updatedStones,
              Diamonds: updatedDiamonds

            };
          });
        });

        let editProduct = transformedProducts.length > 0 && transformedProducts[0].Id;

        console.log("Payload for Edit Products:", transformedProducts);
        const response = await fetch(!editProduct ? a217 : a177, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(transformedProducts),
        });
        const rcvdData = await response.json();

        console.log('check responseee ', rcvdData)

        if (response.ok) {
          setHasUnsavedChanges(false);
          // If the response is successful
          GenerateLabel(rcvdData, labelFormat);
          const labelledStockResponse =
            await apiService.fetchAllLabelledStock();
          setAllLabelledStockData(labelledStockResponse);
          setLoading(false);

          setGroupedProducts([]);


        } else {
          // If there are validation errors
          setLoading(false);

          // Check if there's a specific error message
          if (rcvdData.Errors) {
            const errorMessages = Object.entries(rcvdData.Errors)
              .map(([field, messages]) => `${field}: ${messages.join(', ')}`)
              .join('\n');

            alert(`Validation errors:\n${errorMessages}`);
          } else {
            // Fallback for other errors
            alert(rcvdData.Message || 'An unknown error occurred.');
          }
        }
      }

    } catch (error) {
      console.error("Error editing products:", error);
      setLoading(false);
    }
  }

  const handleEnterKeyNavigation = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent form submission or page navigation
      const form = event.target.form;
      if (!form) return;
      let index = Array.from(form.elements).indexOf(event.target);
      let nextElement = form.elements[index + 1];
      while (
        nextElement &&
        (nextElement.disabled || nextElement.readOnly || nextElement.tagName === 'SELECT' || nextElement.tagName === 'SPAN')
      ) {
        index += 1;
        nextElement = form.elements[index + 1];
      }
      if (nextElement) {
        nextElement.focus();
        if (nextElement.tagName === 'INPUT' || nextElement.tagName === 'TEXTAREA') {
          nextElement.select(); // Select all text inside the input
        }
      }
    }
  };


  const handledropdown = (e, groupIndex, productIndex, key) => {
    const value = e.target.value;
    if (key === 'VendorId') {
      const selectedItem = partyData.find(item => item.Id == value);
      const diamondtamplet = selectedItem?.DiamondSizeWeightRateTemplateId || "";
      console.log('check change  ', selectedItem, '  ', diamondtamplet);
      setGroupedProducts((prevGroupedProducts) => {
        // Create a copy of the current state for immutability
        const updatedGroups = [...prevGroupedProducts];

        // Ensure that the specific group and product exist
        if (updatedGroups[groupIndex] && updatedGroups[groupIndex][productIndex]) {
          // Update only the necessary fields for the specific product
          updatedGroups[groupIndex][productIndex] = {
            ...updatedGroups[groupIndex][productIndex],
            VendorName: selectedItem.VendorName,
            VendorId: selectedItem.Id,
            // DiamondTemplateId: diamondTemplate,
          };
        } else {
          console.warn(`Group or product index not found: Group ${groupIndex}, Product ${productIndex}`);
        }

        return updatedGroups;
      });
    }
    if (key === 'Gender') {
      //   const selectedItem = partyData.find(item => item.Id == value);
      // const diamondtamplet = selectedItem?.DiamondSizeWeightRateTemplateId || "";
      // console.log('check change  ', selectedItem, '  ', diamondtamplet);
      setGroupedProducts((prevGroupedProducts) => {
        // Create a copy of the current state for immutability
        const updatedGroups = [...prevGroupedProducts];

        // Ensure that the specific group and product exist
        if (updatedGroups[groupIndex] && updatedGroups[groupIndex][productIndex]) {
          // Update only the necessary fields for the specific product
          updatedGroups[groupIndex][productIndex] = {
            ...updatedGroups[groupIndex][productIndex],
            Gender: value
            // DiamondTemplateId: diamondTemplate,
          };
        } else {
          console.warn(`Group or product index not found: Group ${groupIndex}, Product ${productIndex}`);
        }

        return updatedGroups;
      });

    }

  };


  const handleInputChangenew = (e, groupIndex, productIndex, property) => {

    let { value } = e.target;


    const isNumeric = !isNaN(value) && value.trim() !== ""; // Check if value is a number

    // Apply decimal restriction only if the value is numeric
    if (isNumeric) {
      // Regex to allow numbers with up to 3 decimal places
      const decimalRegex = /^\d*\.?\d{0,3}$/;

      // Validate input: if the value has more than 3 decimal places, trim it
      if (!decimalRegex.test(value)) {
        return; // Exit if the input is invalid
      }

      // Format numeric value to 3 decimal places if necessary

    }

    const updatedProducts = [...groupproducts];

    // Ensure the groupIndex maps to the correct product within that group
    const product = updatedProducts[groupIndex]?.[productIndex];


    if (product) {
      let updatedProduct = { ...product, [property]: value };
      const grosswt =
        stockType === "Labelled"
          ? parseFloat(product.GrossWt) || 0
          : stockType === "Unlabelled"
            ? parseFloat(product.TotalGrossWt) || 0
            : 0;
      const stoneWeight = parseFloat(product.TotalStoneWeight) || 0;
      const netWt = parseFloat(product.NetWt) || 0;
      const clipWeight = parseFloat(product.ClipWeight) || 0;

      if (property === "RFIDCode") {
        // Convert the barcode number to uppercase before doing the comparison
        const barcodeValue = value.toUpperCase();
        updatedProduct.RFIDCode = barcodeValue; // Set the barcodeNumber property to uppercase
        const matchingLabelledProduct = allLabelledStockData.find((item) => {
          return item.RFIDCode === barcodeValue && item.Status !== "Sold";
        });

        const matchingGroupProduct = groupproducts.some((group) =>
          group.some((product) =>
            product.RFIDCode === barcodeValue && product.Status !== "Sold"
          )
        );

        if (matchingLabelledProduct || matchingGroupProduct) {
          // Show an alert if a match is found
          alert(`Barcode ${barcodeValue} is already in use for another product.`);
          updatedProduct.RFIDCode = ""; // Clear the RFIDCode field
        } else {
          // If no match is found, proceed with the normal logic
          const matchingProduct = rifdData.find(
            (item) => item.BarcodeNumber === barcodeValue
          );

          if (matchingProduct) {
            updatedProduct.TIDNumber = matchingProduct.TidValue;
          } else {
            // If no matching product found, set TIDNumber to null
            updatedProduct.TIDNumber = null;
          }
        }
      }

      let totalFilteredGrossWt = 0;
      if (lotNumber && lotNumber !== "0") {
        totalFilteredGrossWt = unlabeledgold;
        if (property === "GrossWt" && !isNaN(value)) {
          if (value > totalFilteredGrossWt) {
            alert("Error: Total GrossWt of all items exceeds the available GrossWt.");
            updatedProduct.GrossWt = 0;
            updatedProduct.NetWt = 0;
          } else {
            updatedProduct.NetWt =
              parseFloat(value) - parseFloat(clipWeight) - parseFloat(stoneWeight) > 0
                ? (
                  parseFloat(value) -
                  parseFloat(clipWeight) -
                  parseFloat(stoneWeight)
                ).toFixed(3)
                : 0;
          }
        }

      } else {
        if (property === "GrossWt" && !isNaN(value)) {

          updatedProduct.NetWt =
            parseFloat(value) - parseFloat(clipWeight) - parseFloat(stoneWeight) > 0
              ? (
                parseFloat(value) -
                parseFloat(clipWeight) -
                parseFloat(stoneWeight)
              ).toFixed(3)
              : 0;

        }

      }
      if (property === "TotalGrossWt" && !isNaN(value)) {


        updatedProduct.TotalNetWt =
          parseFloat(value) - parseFloat(updatedProduct.TotalStoneWeight) > 0
            ? (
              parseFloat(value) -
              parseFloat(updatedProduct.ClipWeight) -
              parseFloat(updatedProduct.TotalStoneWeight)
            ).toFixed(3)
            : (updatedProduct.TotalGrossWt = value);
      }

      // If 'stoneWeight' is changed, calculate 'netWt'
      if (property === "TotalStoneWeight" && !isNaN(value)) {
        const clipWeight1 = parseFloat(value);
        if (grosswt > clipWeight1) {
          updatedProduct.NetWt = (updatedProduct.GrossWt - parseFloat(value) - updatedProduct.ClipWeight).toFixed(3);
          updatedProduct.TotalStoneWeight = value

          // );
        } else {
          updatedProduct.NetWt = (updatedProduct.GrossWt - 0 - updatedProduct.ClipWeight).toFixed(3);
          updatedProduct.TotalStoneWeight = 0

        }


      }
      if (property === "ClipWeight" && !isNaN(value)) {
        // const clipWeight1 = parseFloat(value);

        const clipWeight1 = parseFloat(value) || 0;
        const totalStoneWt = parseFloat(updatedProduct.TotalStoneWeight) || 0;
        const totalWeight = parseFloat(updatedProduct.TotalDiamondWeight) || 0;
        const grosswt = parseFloat(updatedProduct.GrossWt) || 0;  // Make sure grosswt is numeric

        const tclip = clipWeight1 + totalStoneWt + totalWeight;

        // Debug logs to check values

        // updatedProduct.ClipWeight = clipWeight1;
        if (grosswt > clipWeight1) {
          let net = parseFloat(grosswt) - parseFloat(tclip).toFixed(3);
          console.log("grosswt:", grosswt, "clipWeight1:", clipWeight1, 'netwt ', net);
          updatedProduct.ClipWeight = value;
          updatedProduct.ClipQuantity = "1";
          updatedProduct.NetWt = net
        } else {

          updatedProduct.ClipWeight = 0;

          updatedProduct.ClipQuantity = "1";
          updatedProduct.NetWt = parseFloat(grosswt - (totalStoneWt + totalWeight)).toFixed(3);

        }
      }

      // If 'netWt' is changed, calculate 'grosswt' and 'stoneWeight'
      if (property === "NetWt" && !isNaN(value)) {
        updatedProduct.GrossWt = (
          parseFloat(value) +
          parseFloat(updatedProduct.ClipWeight) +
          stoneWeight
        ).toFixed(3);
        updatedProduct.TotalStoneWeight = (
          grosswt -
          parseFloat(value) -
          parseFloat(updatedProduct.ClipWeight)
        ).toFixed(3);
      }

      if (property === "TotalNetWt" && !isNaN(value)) {
        updatedProduct.TotalGrossWt = (
          parseFloat(value) +
          stoneWeight +
          parseFloat(updatedProduct.ClipWeight)
        ).toFixed(3);
        updatedProduct.TotalStoneWeight = (grosswt - parseFloat(value)).toFixed(
          3
        );
      }
      if (property === "Pieces" && value > 1 && stockType === "Labelled") {
        // setPiecesBox(true);
        // setProductPiecesEditId(index);
        // handlePiecesChange(value, index);

        updatedProduct.Pieces = value;

      }



      updatedProducts[groupIndex][productIndex] = updatedProduct;
      if (lotNumber && lotNumber !== "0") {

        const totalCurrentGrossWt = updatedProducts.reduce((acc, item) => {
          return acc + parseFloat(item.GrossWt || 0);
        }, 0);

        if (totalCurrentGrossWt > totalFilteredGrossWt) {
          alert("Error: Total GrossWt of all items exceeds the available GrossWt.");
          return; // Stop further execution
        }
      }
      setGroupedProducts(updatedProducts)
    }
  }


  const toggleGroup = (groupIndex) => {
    setExpandedGroups((prev) => ({
      ...prev,
      [groupIndex]: !prev[groupIndex], // Toggle the boolean value for the group
    }));
  };

  const handleButtonClick = (event, key, gindex, iindex) => {
    event.preventDefault();


    if (key == "Stones") {
      setItemindex(iindex);
      setGroupindex(gindex)

      // Access the targeted item directly in groupproducts
      const targetItem = groupproducts[gindex][iindex];

      // Check if 'Stones' array exists; if not, initialize it with an empty array

      console.log('check stone ', targetItem.Stones)
      if (!Array.isArray(targetItem.Stones) || targetItem.Stones.length === 0) {
        targetItem.Stones = [addStone];
        const updatedColumns = itemfields.map(column => {
          if (["TotalStoneWeight", "TotalStoneAmount"].includes(column.key)) {
            return { ...column, visibility: 'ReadOnly' }; // Adjust the keys as needed
          }
          return column;
        });

        // setColumns(updatedColumns);
      }

      setShowAddStoneBox(true);
      // setSelectedProductIndex(index);
    }
    if (key == "DButton") {
      setItemindex(iindex);
      setGroupindex(gindex)


      const targetItem = groupproducts[gindex][iindex];

      // Check if 'Stones' array exists; if not, initialize it with an empty array

      console.log('check stone ', targetItem.Stones)
      if (!Array.isArray(targetItem.Diamonds) || targetItem.Diamonds.length === 0) {
        targetItem.Diamonds = [addDiamond];
        // const updatedColumns = itemfields.map(column => {
        //   if (["TotalStoneWeight", "TotalStoneAmount"].includes(column.key)) {
        //     return { ...column, visibility: 'ReadOnly' }; // Adjust the keys as needed
        //   }
        //   return column;
        // });

        // setColumns(updatedColumns);
      }


      setShowAddDiamondBox(true)
    }



  }

  const handleclose = () => {
    const targetItem = groupproducts[groupindex][itemindex];

    // Check if 'Stones' array exists; if not, initialize it with an empty array
    if (targetItem.Stones.length === 0) {
      // targetItem.Stones = [addStone];
    }

    // Validate if any StoneName or StoneWeight is empty or 0
    const isValid = targetItem.Stones.every((stone) => {
      const isStoneNameValid = stone.StoneName && stone.StoneName.trim() !== '';
      const isStoneWeightValid = parseFloat(stone.StoneWeight) > 0;

      return isStoneNameValid && isStoneWeightValid;
    });

    if (!isValid) {
      // Show an alert or message to the user if validation fails
      alert('Please ensure all stones have a valid name and weight greater than 0.');
      return; // Prevent closing if validation fails
    }

    // Proceed with closing the popup if validation passes
    setShowAddStoneBox(false);
  };

  const handlediamondclose = () => {

    const targetItem = groupproducts[groupindex][itemindex];

    // Check if 'Stones' array exists; if not, initialize it with an empty array
    if (targetItem.Stones.Diamonds === 0) {
      // targetItem.Stones = [addStone];
    }

    // Validate if any StoneName or StoneWeight is empty or 0
    const isValid = targetItem.Diamonds.every((stone) => {
      const isStoneNameValid = stone.DiamondName && stone.DiamondName.trim() !== '';
      const isStoneWeightValid = parseFloat(stone.DiamondWeight) > 0;

      return isStoneNameValid && isStoneWeightValid;
    });

    if (!isValid) {
      // Show an alert or message to the user if validation fails
      alert('Please ensure all Diamond have a valid name and weight greater than 0.');
      return; // Prevent closing if validation fails
    }
    setShowAddDiamondBox(false);
  }


  const normalizeString = (str) => {
    return str.replace(/\s+/g, " ").trim().toLowerCase();
  };

  const handleStoneChange = (index, property, value) => {


    const updatedProducts = [...groupproducts]; // Create a copy of your products state
    const groupIndex = groupindex; // Get the current group index
    const itemIndex = itemindex; // Get the current item index

    // Access the specific item and its stones
    const currentItem = updatedProducts[groupIndex]?.[itemIndex];


    const newStones = [...currentItem.Stones];
    const newdiamond = [...currentItem.Diamonds];

    const normalizedValue = normalizeString(value);
    console.log("Normalized value:", normalizedValue);

    let selectedStone;
    allStonesList.forEach((stone) => {
      const normalizedStoneName = normalizeString(
        stone.StoneName ? stone.StoneName : stone.StoneMainName
      );
      console.log(
        `Comparing "${normalizedStoneName}" with "${normalizedValue}"`
      );
      if (normalizedStoneName === normalizedValue) {
        selectedStone = stone;
        console.log("Match found :", stone);
      }
    });

    let StonePiecest = 0;


    if (selectedStone) {
      StonePiecest = 0;
      selectedStone.StonePieces !== null && selectedStone.StonePieces !== undefined
        ? selectedStone.StonePieces
        : selectedStone.StoneMainPieces !== null && selectedStone.StoneMainPieces !== undefined
          ? selectedStone.StoneMainPieces
          : 1;

      if (StonePiecest == 0) {
        StonePiecest = 1;
      }
      newStones[index] = {
        ...newStones[index],
        StoneName: selectedStone.StoneName || selectedStone.StoneMainName,
        StoneWeight: parseFloat(selectedStone.StoneWeight || selectedStone.StoneMainWeight || 0).toFixed(3),
        StonePieces: StonePiecest,
        StoneRate: parseFloat(selectedStone.StoneRate || selectedStone.StoneMainRate || 0).toFixed(2),
        StoneAmount: parseFloat(selectedStone.StoneAmount || selectedStone.StoneMainAmount || 0).toFixed(2),
        Description: selectedStone.Description || selectedStone.StoneMainDescription,
      };
    } else {
      newStones[index] = {
        ...newStones[index],
        [property]: value,
      };
    }

    const skuPieces = parseFloat(selectedSku?.Pieces) || 1;
    const qty = parseFloat(currentItem.ClipQuantity) || 1;

    const rate = (
      parseFloat(newStones[index].StoneWeight) * parseFloat(newStones[index].StoneRate)
    ).toFixed(2);

    const totalStoneWt = newStones.reduce((total, stone) => {
      return total + (parseFloat(stone.StoneWeight) || 0) * skuPieces * qty;
    }, 0).toFixed(3);

    const tamount = newStones.reduce((total, stone) => {
      return total + (parseFloat(stone.StoneWeight) || 0) * (parseFloat(stone.StoneRate) || 0);
    }, 0).toFixed(2);

    let totalwt =
      (parseFloat(newStones[index].StoneWeight) * skuPieces * qty).toFixed(3);

    let totalpcs =
      (parseInt(newStones[index].StonePieces) || 1) * skuPieces * qty;

    const totaldiamondWt = newdiamond.reduce((total, stone) => {
      return total + (parseFloat(stone.DiamondWeight) || 0) * skuPieces * qty;
    }, 0).toFixed(3);



    const grossWeight = parseFloat(currentItem.GrossWt) || 0;
    const cwt = parseFloat(currentItem.ClipWeight) || 0;
    const twt = parseFloat(totalStoneWt) + parseFloat(cwt) + parseFloat(totaldiamondWt);
    const nwt = (grossWeight - twt).toFixed(3);

    console.log("Check updated stones", grossWeight, '  ', cwt, '  ', twt, '  ', nwt);

    if (twt < grossWeight) {
      // Assign calculated totals
      newStones[index].TotalStoneWt = totalwt;
      newStones[index].TotalStonePcs = totalpcs || 1;

      newStones[index].StoneAmount = rate;


      updatedProducts[groupIndex][itemIndex].Stones = newStones;
      updatedProducts[groupIndex][itemIndex].NetWt = nwt;
      updatedProducts[groupIndex][itemIndex].TotalStoneWeight = totalStoneWt
      updatedProducts[groupIndex][itemIndex].TotalStoneAmount = tamount

      // Set the updated products back to state
      setGroupedProducts(updatedProducts);
    } else {
      // Handle the case where totalwt exceeds the Gross weight
      alert(`Total stone weight (${twt}) exceeds the gross weight (${grossWeight}) of the item.`);
      // You may also want to reset the StoneWeight or any related field to avoid invalid state
      newStones[index].StoneWeight = ""; // Reset or take any other action you prefer

    }
    // setGroupedProducts(updatedProducts);

  };

  const handlediamondChange = (index, property, value, diamondref) => {

    const updatedProducts = [...groupproducts];
    const groupIndex = groupindex; // Get the current group index
    const itemIndex = itemindex; // Get the current item index

    console.log("check values", value);

    // Access the specific item and its diamonds
    const currentItem = updatedProducts[groupIndex]?.[itemIndex];
    const newStones = [...currentItem.Stones];
    const newdiamond = [...currentItem.Diamonds];

    newdiamond[index] = {
      ...newdiamond[index],
      [property]: value,
    };

    const skuPieces = parseFloat(selectedSku?.Pieces) || 1;
    const qty = parseFloat(currentItem.ClipQuantity) || 1;

    const totalWeight = newdiamond.reduce((sum, diamond) => sum + (parseFloat(diamond.DiamondWeight) || 0), 0);
    const totalAmount = newdiamond.reduce((sum, diamond) => sum + ((parseFloat(diamond.DiamondWeight) || 0) * (parseFloat(diamond.DiamondRate) || 0)), 0);


    const totalStoneWt = newStones.reduce((total, stone) => {
      return total + (parseFloat(stone.StoneWeight) || 0) * skuPieces * qty;
    }, 0).toFixed(3);

    const grossWeight = parseFloat(currentItem.GrossWt) || 0;
    const cwt = parseFloat(currentItem.ClipWeight) || 0;
    const twt = parseFloat(totalStoneWt) + parseFloat(cwt) + parseFloat(totalWeight); // Ensure all parts are numeric
    const nwt = (grossWeight - twt).toFixed(3);

    console.log('check diamond weight', totalWeight, 'twt:', twt, 'Gross Weight:', grossWeight);

    // Check if twt is unexpectedly equal to or greater than grossWeight
    if (twt < grossWeight) {
      // Assign calculated totals
      newdiamond[index].DiamondAmount =
        (parseFloat(newdiamond[index].DiamondWeight) || 0) *
        (parseFloat(newdiamond[index].DiamondRate) || 0);

      updatedProducts[groupIndex][itemIndex].Diamonds = newdiamond;
      updatedProducts[groupIndex][itemIndex].NetWt = nwt;
      updatedProducts[groupIndex][itemIndex].TotalDiamondWeight = totalWeight;
      updatedProducts[groupIndex][itemIndex].TotalDiamondAmount = totalAmount;

      // Set the updated products back to state
      setGroupedProducts(updatedProducts);
    } else {
      // Handle the case where twt exceeds the Gross weight
      alert(`Total diamond weight (${twt}) exceeds the gross weight (${grossWeight}) of the item.`);
    }

  }

  const deletediamond = (diamondindex) => {
    const updatedGroupedProducts = [...groupproducts];

    // Access the current group and item
    const targetItem = updatedGroupedProducts[groupindex][itemindex];

    // Check if 'Stones' exists and is an array
    if (Array.isArray(targetItem.Diamonds) && targetItem.Diamonds.length > 0) {
      // Remove the stone at the specified index
      targetItem.Diamonds.splice(diamondindex, 1);

      // Update the state with the modified grouped products
      setGroupedProducts(updatedGroupedProducts);
    }

    // Optional: Hide the stone popup after deletion if needed
    // setShowAddStoneBox(false);
  };

  const deleteStone = (stoneIndex) => {
    const updatedGroupedProducts = [...groupproducts];

    // Access the current group and item
    const targetItem = updatedGroupedProducts[groupindex][itemindex];

    // Check if 'Stones' exists and is an array
    if (Array.isArray(targetItem.Stones) && targetItem.Stones.length > 0) {
      // Remove the stone at the specified index
      targetItem.Stones.splice(stoneIndex, 1);

      // Recalculate the total values after the deletion
      const skuPieces = parseFloat(targetItem?.Pieces) || 1;
      const qty = parseFloat(targetItem.ClipQuantity) || 1;

      // Recalculate TotalStoneWeight (sum of StoneWeight * pieces * quantity)
      const totalStoneWeight = targetItem.Stones.reduce((total, stone) => {
        return total + (parseFloat(stone.StoneWeight) || 0) * skuPieces * qty;
      }, 0).toFixed(3);

      // Recalculate TotalStoneAmount (sum of StoneWeight * StoneRate)
      const totalStoneAmount = targetItem.Stones.reduce((total, stone) => {
        return total + (parseFloat(stone.StoneWeight) || 0) * (parseFloat(stone.StoneRate) || 0);
      }, 0).toFixed(2);

      // Recalculate TotalStonePieces (sum of pieces for each stone)
      const totalStonePieces = targetItem.Stones.reduce((total, stone) => {
        return total + (parseInt(stone.StonePieces) || 0);
      }, 0);

      // Update other related fields
      const grossWeight = parseFloat(targetItem.GrossWt) || 0;
      const stoneWeight = parseFloat(targetItem.Stones.StoneWeight) || 0;
      const clipWeight = parseFloat(targetItem.ClipWeight) || 0;
      const newNetWeight = grossWeight - (totalStoneWeight + clipWeight);

      // Update the item with the new calculated values
      targetItem.NetWt = newNetWeight;
      targetItem.TotalStoneWeight = totalStoneWeight;
      targetItem.TotalStoneAmount = totalStoneAmount;
      targetItem.TotalStonePieces = totalStonePieces;

      // Update the state with the modified grouped products
      setGroupedProducts(updatedGroupedProducts);
    }

    // Optional: Hide the stone popup after deletion if needed
    // setShowAddStoneBox(false);
  };

  const handleCategoryChange = (e) => {
    const selectedCategory = e.target.value;



    const cat = categoriesData
      .filter((x) => x.Id == selectedCategory)

    console.log('check category ', cat);

    setCategory(selectedCategory);

    if (cat && cat[0].CategoryName.toUpperCase() === 'LOOSE DIAMOND') { // Assuming '10' is the ID for "loose diamonds"
      // Set all fields to invisible
      // const updatedFields = itemfields.map(field => ({ ...field, IsVisible: false }));

      // Enable visibility for the 17 specific fields
      const visibleKeys = ["CategoryId", "MDiamondShape", "MDiamondClarity",
        "MDiamondColour", "MDiamondSize", "dsleves",
        "dweight", "dselrate", "qty", "PacketId", "boxId", "MDiamondCut", "dsetting", "tcertificate", "tpdecription"];
      const updatedFields = itemfields.map(field => {
        if (field.place === 'middle') {
          return { ...field, IsVisible: visibleKeys.includes(field.key) };
        }
        return field; // Keep other fields unchanged
      });

      setItemfields(updatedFields);
    } else {
      // Reset all fields or set different logic for other 


      const updatedFields = itemfields.map(field => {
        if (field.place === 'middle' && field.key.toLowerCase().includes('diamond')) {
          return { ...field, IsVisible: false };
        }
        return field; // Keep other fields unchanged
      });

      setItemfields(updatedFields);
      // setItemfields(
      //   itemfields.map(field => ({ ...field, IsVisible: true }))
      // );
    }
  };

  const getProcessedKey = (key) => key.startsWith("M") ? key.slice(1) : key;


  const middlediamondchnge = (key, value) => {
    setAddDiamond(prev => ({
      ...prev,
      [key]: value,
    }));
  };
  
  




  return (
    <div>
      <div>
        <AdminHeading />
        {loading ? (
          // Show spinner while loading
          <div className="spinner-container">
            <ClipLoader size={50} color={"#123abc"} loading={loading} />
            <p>Loading data...</p>
          </div>
        ) : (
          <div>
            <ErrorModal
              isOpen={showModal}
              onRequestClose={() => {
                setShowModal(false); // Close the modal
                navigate("/adminhome"); // Redirect to /adminhome
              }}
              onReload={reloadData} // Pass reload function
              message={errorMessage}
            />
            <div className="adminMainBodyBox" id="addBulkProductsBoxTop">
              <AdminBreadCrump
                title={"Add Bulk Stock"}
                companyName={"Loyalstring"}
                module={"E-commerce"}
                page={"Add Bulk Stock"}
              />
              <div className="white-container">
                {showAddStoneBox && (
                  <div className="popup">
                    <div
                      style={{ maxHeight: "250px", overflowY: "auto" }}
                      className="popup-inner"
                    >


                      <StonePopup
                        purchaseProduct={groupproducts[groupindex]?.[itemindex]}
                        handleStoneChange={handleStoneChange}
                        deleteStone={deleteStone}
                        addStone={addStone}
                        setPurchaseProduct={setGroupedProducts}
                        closePopup={handleclose} // Close function
                        allStonesList={allStonesList}
                        from={'addstock'}
                        gindex={groupindex}
                        iindex={itemindex}
                      />
                    </div>
                  </div>
                )}

                {showAddDiamondBox && (
                  <div className="popup">
                    <div
                      style={{ maxHeight: "250px", overflowY: "auto" }}
                      className="popup-inner"
                    >


                      <Diamondpopup
                        purchaseProduct={groupproducts[groupindex]?.[itemindex]}
                        allDiamondAttributes={allDiamondAttributes}
                        handlediamondChange={handlediamondChange}
                        closePopup={handlediamondclose}
                        adddiamond={addDiamond}
                        deletediamond={deletediamond}
                        setPurchaseProduct={setGroupedProducts}
                        from={'addstock'}
                        gindex={groupindex}
                        iindex={itemindex}


                      />


                    </div>
                  </div>
                )}
                {/* <div className="adminAddCategoryMainbox addProductMain"> */}
                <form
                  style={{
                    marginTop: "10px",
                  }}
                  onSubmit={
                    stockType === "Labelled"
                      ? handleCreateAddedProducts
                      : handleSubmitUnlabelled
                    // ? handleSubmit
                  }
                >


                  <div className="header-box">
                    {/* Left Side: Heading */}
                    <div className="heading">
                      ADD BULK STOCK
                    </div>

                    {/* Right Side: Dropdown */}
                    <div>
                      <label
                        htmlFor="selectBranch"
                        style={{ textAlign: "right" }}
                      >
                        <strong
                          style={{ textAlign: "right", color: "white" }}
                        >
                          SELECT BRANCH
                        </strong>
                      </label>
                      <select
                        id="selectBranch"
                        required="required"
                        value={branch}
                        onChange={(e) => setBranch(e.target.value)}
                      >
                        <option value="">Select a branch</option>
                        {branchOption.map((item) => (
                          <option value={item.BranchName}>
                            {item.BranchName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>


                  <div style={{ marginRight: '20px', marginLeft: '20px' }}>
                    <div className="addProductDetailsUpperBox"
                      style={{
                        marginTop: "3rem",
                        display: "unset",
                        marginLeft: "30px",
                        marginRight: "20px",
                      }}>
                      <Grid container xs={12} spacing={4}>
                        {itemfields
                          .filter((field) => field.place === 'top' && field.visibility !== 'Hidden' && field.IsVisible)
                          .sort((a, b) => a.priority - b.priority)
                          .map((field, index) => (
                            // <Grid item xs={12} md={3} key={index} style={{ textAlign: 'left' }}>
                            <Grid xs={6} md={3} key={index} item style={{ textAlign: 'left' }}>
                              <div >
                                {field.type === "inputselect" ? (
                                  <div>
                                    <label
                                      htmlFor="category"
                                      style={{
                                        paddingLeft: "0%",
                                        marginLeft: "0%",
                                      }}
                                    >
                                      <strong
                                        style={{
                                          color: "black",
                                          fontSize: "15px",
                                        }}
                                      >
                                        {field.label}
                                      </strong>
                                    </label>
                                    {field.key === "Supplier" ? (
                                      <div>
                                        <input
                                          style={{
                                            width: "100%",
                                            marginLeft: "0px",
                                          }}
                                          // style={{ width: "30vw" }}
                                          type="text"
                                          name="supplier"
                                          placeholder="Select Party / Karigar Name"
                                          value={partyTypeId}
                                          onKeyDown={handleEnterKeyNavigation}
                                          onInput={(e) => handletopdropdown(e, "supplier")}  // Partial match on input
                                          // onBlur={handleSkuSelect}
                                          list="supplier"
                                          autoComplete="off"
                                        />

                                        <datalist id="supplier">
                                          {filteredparty.map((x, y) => (
                                            <option key={y} value={x.Id}>
                                              {x.VendorName}
                                            </option>
                                          ))}
                                        </datalist>
                                      </div>
                                    ) : field.key === "StockKeepingUnit" ? (

                                      <div>
                                        <input
                                          style={{
                                            width: "100%",
                                            marginLeft: "0px",
                                          }}
                                          // style={{ width: "30vw" }}
                                          type="text"
                                          name="skuList"
                                          placeholder="Enter SKU"
                                          value={selectedSkuName}
                                          onInput={handleSkuInputChange}  // Partial match on input
                                          onBlur={handleSkuSelect}
                                          list="skuList"
                                          autoComplete="off"
                                        />

                                        <datalist id="skuList">
                                          {filteredsku.map((sku, index) => (
                                            <option
                                              key={index}
                                              value={`${sku.StockKeepingUnit}`}
                                              vendor={sku.SKUId}
                                            />
                                          ))}
                                        </datalist>
                                      </div>

                                    ) : field.key === "LotNumber" ? (
                                      <div>
                                        <select
                                          style={{
                                            width: "100%",
                                            marginLeft: "0px",
                                          }}
                                          id="selectBranch"
                                          // required="required"
                                          value={lotNumber}
                                          onChange={(e) =>
                                            handleGold(e, "lotNumber")
                                          }
                                        >
                                          <option value={0}>
                                            Select Lot Number
                                          </option>

                                          {uniqueLotNumbers &&
                                            uniqueLotNumbers.map((x) => {
                                              return (
                                                <option value={x.LotNumber}>
                                                  {x.LotNumber}
                                                </option>
                                              );
                                            })}
                                        </select>
                                      </div>
                                    ) : null
                                    }
                                  </div>

                                ) : field.type === "dropdown" ? (

                                  <div>
                                    <label
                                      htmlFor="category"
                                      style={{
                                        paddingLeft: "0%",
                                        marginLeft: "0%",
                                      }}
                                    >
                                      <strong
                                        style={{
                                          color: "black",
                                          fontSize: "15px",
                                        }}
                                      >
                                        {field.label}
                                      </strong>
                                    </label>

                                    {/* Check if field.key is "Supplier" */}
                                    {field.key === "Supplier" ?
                                      (
                                        <select
                                          style={{
                                            width: "100%",
                                            marginLeft: "0px",
                                          }}
                                          id="category"
                                          required="required"
                                          value={partyTypeId}
                                          onChange={(e) => {
                                            setPartyTypeId(e.target.value);
                                            const tempId = partyData.find(
                                              (event, ind) =>
                                                event.Id == e.target.value
                                            );
                                            setDiamondTemplateId(
                                              tempId?.DiamondSizeWeightRateTemplateId
                                            );
                                          }}
                                        >
                                          <option value="">
                                            Select Party / Karigar Name
                                          </option>

                                          {filteredparty.map((x, y) => {
                                            return (
                                              <option
                                                key={y}
                                                value={parseInt(x.Id)}
                                              >
                                                {x.VendorName}
                                              </option>
                                            );
                                          })}
                                        </select>
                                      ) : field.key === "StockType" ? (
                                        <select
                                          style={{
                                            width: "100%",
                                            marginLeft: "0px",
                                          }}
                                          id="invoiceType"
                                          required="required"
                                          value={stockType}
                                          onChange={(e) =>
                                            setStockType(e.target.value)
                                          }
                                        >
                                          <option value="Labelled">Labelled</option>
                                          <option value="Unlabelled">
                                            Unlabelled
                                          </option>
                                        </select>
                                      ) : null}
                                  </div>
                                ) :
                                  null}
                              </div>
                            </Grid>
                          ))}
                      </Grid>
                    </div>
                    <h4
                      style={{ width: "95%", marginTop: "30px" }}
                      id="adminInvoiceAddedCustomerEdit"
                      className="adminInvoiceAddTitles"
                    >
                      Add Items
                    </h4>

                    {itemfields.some(field => field.place === 'middle' && field.visibility !== 'Hidden' && field.IsVisible) ? (

                      <>

                        <div className="addstocktable">
                          <div className="addstockitems">


                            <table>
                              <thead>
                                <tr>
                                  {itemfields
                                    .filter((field) => field.place === 'middle' && field.visibility !== 'Hidden' && field.IsVisible)
                                    .sort((a, b) => a.priority - b.priority)
                                    .map((field, index) => (
                                      <th1 key={index}>
                                        {/* Wrap the label in a <div> if needed */}
                                        {/* <div style={{ textAlign: 'left' }}>{field.label}</div> */}
                                        {field.type !== "button" && (
                                          <div style={{ textAlign: 'left' }}>{field.label}</div>
                                        )}
                                        {field.type === "inputselect" ? (
                                          // SKU Input with datalist
                                          <input
                                            type="text"
                                            name={field.key}
                                            placeholder={`Enter ${field.label}`}
                                            // value={purchaseProduct[field.key] || ""}
                                            // disabled={field.visibility === "ReadOnly"}
                                            // onInput={handleSkuInputChange}
                                            list="skuList"
                                            autoComplete="off"
                                          />
                                        ) :
                                        
                                          field.type === "dropdown" ? (
                                            field.key === "CategoryId" ? (

                                              <select
                                                id="category"
                                                required="required"
                                                value={category}
                                                // disabled={readOnly}
                                                onChange={(e) => {
                                                  setDiamondShape("");
                                                  setDiamondClarity("");
                                                  setDiamondColour("");
                                                  setDiamondSize("");
                                                  // setDiamondPieces('');
                                                  setPacketNumber("");
                                                  setDiamondSleve("");
                                                  setDiamondQty("");
                                                  setDiamondWeight("");
                                                  setDiamondSellRate("");
                                                  setDiamondTotalWeight("");
                                                  setDiamondTotalAmount("");
                                                  setDiamondCut("");
                                                  setDiamondSettingType("");
                                                  setDiamondCertificate("");
                                                  setDiamondDescription("");
                                                  if (
                                                    e.target.value == 11 ||
                                                    e.target.value == 12 ||
                                                    e.target.value == 13
                                                  ) {
                                                    setShowDiamondBtn(true);
                                                  } else {
                                                    setShowDiamondBtn(false);
                                                  }

                                                  if (e.target.value == 10) {
                                                    setIsLooseDiamond(true);
                                                  } else {
                                                    setIsLooseDiamond(false);
                                                  }
                                                  if (
                                                    categoriesData &&
                                                    categoriesData
                                                      .filter((x) => x.Id == e.target.value)[0]
                                                      ?.CategoryName.toLowerCase() !==
                                                    "diamonds"
                                                  ) {
                                                    setCategory(e.target.value),
                                                      setBaseMetal(e.target.value);
                                                  } else if (
                                                    categoriesData &&
                                                    categoriesData.filter(
                                                      (x) => x.Id == e.target.value
                                                    )[0]?.CategoryName !== "loose diamonds"
                                                  ) {
                                                    setCategory(e.target.value),
                                                      setBaseMetal(0),
                                                      setProductType(""),
                                                      setBoxType("");

                                                    setCollection(""), setCollectionmain("");
                                                    setPurity(""),
                                                      setBoxId(""),
                                                      setDiamondSize(""),
                                                      setDiamondWeight("0"),
                                                      setDiamondPurchaseRate("0"),
                                                      setDiamondSellRate("0"),
                                                      setDiamondClarity(""),
                                                      setDiamondColour(""),
                                                      setDiamondShape(""),
                                                      setDiamondCut(""),
                                                      setDiamondSettingType(""),
                                                      setDiamondCertificate(""),
                                                      setDiamondPieces("0"),
                                                      setDiamondPurchaseAmount("0"),
                                                      setDiamondTotalAmount("0"),
                                                      setDiamondDescription(""),
                                                      setStockType("Labelled");
                                                  } else {
                                                    setCategory(e.target.value),
                                                      setBaseMetal(1);
                                                  }
                                                  if (e.target.value == 10) {
                                                    setStockType("Unlabelled");
                                                  }
                                                  handleCategoryChange(e)
                                                }}
                                              >
                                                <option value="">Category</option>
                                                {categoriesData.map((x, y) => {
                                                  return (
                                                    <option key={y} value={x.Id}>
                                                      {x.CategoryName}
                                                    </option>
                                                  );
                                                })}
                                              </select>

                                            ) :

                                              field.key === "productTypeId" ? (

                                                <select
                                                  id="productTypeId"
                                                  required="required"
                                                  value={productType}
                                                  onChange={(e) =>
                                                    setProductType(e.target.value)
                                                  }
                                                >
                                                  <option value="">Product Type</option>
                                                  {filteredProducts.map((x, y) => {
                                                    return (
                                                      <option
                                                        key={y}
                                                        value={`${parseInt(x.Id)},${x.ProductName
                                                          }`}
                                                      >
                                                        {x.ProductName}
                                                      </option>
                                                    );
                                                  })}
                                                </select>

                                              ) :

                                                field.key === "DesignId" ? (
                                                  <select
                                                    id="collection"
                                                    required="required"
                                                    value={collection}
                                                    onChange={(e) =>
                                                      setCollection(e.target.value)
                                                    }
                                                  >
                                                    <option value="">Design</option>
                                                    {filteredCollection.map((x, y) => {
                                                      return (
                                                        <option
                                                          key={y}
                                                          value={`${parseInt(x.Id)},${x.DesignName
                                                            }`}
                                                        >
                                                          {x.DesignName}
                                                        </option>
                                                      );
                                                    })}
                                                  </select>


                                                ) :

                                                  field.key === "collectionmain" ? (

                                                    <select
                                                      id="collection"
                                                      value={collectionmain}
                                                      onChange={(e) =>
                                                        setCollectionmain(e.target.value)
                                                      }
                                                    >
                                                      <option value="">Collection</option>
                                                      {Array.isArray(collectionmainlist) && collectionmainlist.map((x, y) => {
                                                        return (
                                                          <option
                                                            key={y}
                                                            value={`${parseInt(
                                                              x.Collection.Id
                                                            )},${x.Collection.CollectionName}`}
                                                          >
                                                            {x.Collection.CollectionName}
                                                          </option>
                                                        );
                                                      })}
                                                    </select>

                                                  ) :
                                                    field.key === "purity" ? (
                                                      <select
                                                        id="purity"
                                                        required="required"
                                                        value={purity}
                                                        onChange={(e) => setPurity(e.target.value)}
                                                      >
                                                        <option value="">Purity</option>

                                                        {filteredPurity.map((x, y) => {
                                                          return (
                                                            <option
                                                              key={y}
                                                              value={`${parseInt(x.Id)},${x.PurityName
                                                                }`}
                                                            >
                                                              {x.PurityName}
                                                            </option>
                                                          );
                                                        })}
                                                      </select>
                                                    ) :
                                                      field.key === "PacketId" ? (

                                                        <select
                                                          id="PacketId"
                                                          // required="required"
                                                          value={packetNumber}
                                                          onChange={(e) =>
                                                            setPacketNumber(e.target.value)
                                                          }
                                                        >
                                                          <option value="">Packet</option>
                                                          {allPacketNumbers.map((x, y) => {
                                                            return (
                                                              <option key={y} value={parseInt(x.Id)}>
                                                                {x.PacketName}
                                                              </option>
                                                            );
                                                          })}
                                                        </select>
                                                      ) :
                                                        field.key === "boxId" ? (
                                                          <select
                                                            id="boxId"
                                                            // required="required"
                                                            // value={boxId}
                                                            value={boxType}
                                                            onChange={(e) =>
                                                              setBoxType(e.target.value)
                                                            }
                                                          >
                                                            <option value="">Box</option>
                                                            {boxData.map((x, y) => {
                                                              return (
                                                                <option
                                                                  key={y}
                                                                  value={`${parseInt(x.Id)},${x.BoxName
                                                                    }`}
                                                                >
                                                                  {x.BoxName}
                                                                </option>
                                                              );
                                                            })}
                                                          </select>

                                                        ) :
                                                          field.key === "mainstone" ? (

                                                            <select
                                                              // required="required"
                                                              value={
                                                                selectedSkuStones
                                                                  ? selectedSkuStones.Id
                                                                  : ""
                                                              }
                                                              onChange={handleSelectedSkuStoneChange}
                                                            >
                                                              {/* Default option with an empty value */}
                                                              <option value="">Select An Stone</option>
                                                              {allSelectedSkuStones.map(
                                                                (stone, index) => (
                                                                  <option key={index} value={stone.Id}>
                                                                    {stone.StoneMainName}
                                                                  </option>
                                                                )
                                                              )}
                                                            </select>

                                                          ) :
                                                            field.key === "weightcategory" ? (

                                                              <select
                                                                value={weights ? weights : ""}
                                                                onChange={handleSelectedweights}
                                                              >
                                                                <option value="">Select a weight</option>
                                                                {selectedweights.map((weight, index) => (
                                                                  <option key={index} value={weight}>
                                                                    {weight}
                                                                  </option>
                                                                ))}
                                                              </select>

                                                            ) :
                                                              field.key === "tgender" ? (

                                                                <select
                                                                  value={gender}
                                                                  onChange={(e) =>
                                                                    setGender(e.target.value)
                                                                  }
                                                                >
                                                                  <option value="">
                                                                    Select an option
                                                                  </option>
                                                                  <option value="Male">Male</option>
                                                                  <option value="Female">
                                                                    Female
                                                                  </option>
                                                                  <option value="Unisex">
                                                                    Unisex
                                                                  </option>
                                                                  <option value="Kids">Kids</option>
                                                                </select>

                                                              ) :


                                                                null
                                          ) :
                                            field.type === "inputnumber" ? (
                                              field.key === "qty" ? (
                                                <input
                                                  required="required"
                                                  type="number"
                                                  value={quantity}
                                                  onChange={(e) => {
                                                    setQuantity(e.target.value);
                                                  }}
                                                />
                                              ) :
                                                field.key === "pieces" ? (
                                                  <input
                                                    required="required"
                                                    type="number"
                                                    value={pieces}
                                                    onChange={(e) => {
                                                      setPieces(e.target.value);
                                                    }}
                                                  />
                                                ) :
                                                  field.key === "tgrosswt" ? (
                                                    <input
                                                      type="number"
                                                      id="grosswt"
                                                      required
                                                      value={grosswt}
                                                      onChange={(e) => {
                                                        const grossWeightValue = parseFloat(
                                                          e.target.value
                                                        );
                                                        setGrosswt(grossWeightValue);

                                                        if (
                                                          grossWeightValue -
                                                          parseFloat(stoneWeight) >
                                                          0
                                                        ) {
                                                          setNetWt(
                                                            grossWeightValue -
                                                            parseFloat(clipWeight) -
                                                            parseFloat(stoneWeight)
                                                          );
                                                        } else {
                                                          setNetWt(0);
                                                          setStoneWeight(0);
                                                          setClipWeight(0);
                                                          setSelectedSkuStones({ Id: 0 });
                                                        }
                                                      }}
                                                    />
                                                  ) :
                                                    field.key === "tclipWeight" ? (
                                                      <input
                                                        type="number"
                                                        id="clipWeight"
                                                        value={clipWeight}
                                                        onChange={(e) => {
                                                          const clipWeightValue = parseFloat(
                                                            e.target.value
                                                          );
                                                          setClipWeight(clipWeightValue);
                                                          setNetWt(
                                                            (
                                                              parseFloat(grosswt) -
                                                              parseFloat(stoneWeight) -
                                                              clipWeightValue
                                                            ).toFixed(3)
                                                          );
                                                        }}
                                                      />
                                                    ) :
                                                      field.key === "tstoneWeight" ? (
                                                        <input
                                                          type="number"
                                                          id="stoneWeight"
                                                          value={stoneWeight}
                                                          onChange={(e) => {
                                                            const stoneWeightValue = parseFloat(
                                                              e.target.value
                                                            );
                                                            if (
                                                              stoneWeightValue <=
                                                              parseFloat(grosswt)
                                                            ) {
                                                              setStoneWeight(stoneWeightValue);

                                                              setNetWt(
                                                                (
                                                                  parseFloat(grosswt) -
                                                                  parseFloat(clipWeight) -
                                                                  stoneWeightValue
                                                                ).toFixed(3)
                                                              );
                                                            }
                                                          }}
                                                        />
                                                      ) :
                                                        field.key === "tnetWt" ? (
                                                          <input
                                                            type="number"
                                                            id="netWt"
                                                            value={netWt}
                                                            readOnly
                                                          />
                                                        ) :
                                                          field.key === "tmrp" ? (
                                                            <input
                                                              type="number"
                                                              value={mrp}
                                                              onChange={(e) =>
                                                                setMRP(e.target.value)
                                                              }
                                                            />
                                                          ) : field.key === "tmaking%" ? (
                                                            <input
                                                              type="text"
                                                              id="name"
                                                              value={making_Percentage}
                                                              onChange={(e) =>
                                                                setMaking_Percentage(
                                                                  e.target.value
                                                                )
                                                              }
                                                            />
                                                          ) : null

                                            ) :
                                              field.type === 'inputtext' ? (
                                                field.key === "tpdecription" ? (
                                                  <input
                                                    style={{ width: "2fr" }}
                                                    type="text"
                                                    id="description"
                                                    value={description}
                                                    onChange={(e) =>
                                                      setDescription(e.target.value)
                                                    }
                                                  />

                                                ) :
                                                  field.key === "tpnme" ? (
                                                    <input
                                                      type="text"
                                                      id="name"
                                                      value={productName}
                                                      onChange={(e) =>
                                                        setProductName(e.target.value)
                                                      }
                                                    />

                                                  ) : null



                                              )
                                                :
                                                field.type === 'button' ? (

                                                  <button
                                                    type="submit"
                                                    style={{
                                                      width: "100px",
                                                      marginRight: "10px",
                                                    }}
                                                  >
                                                    <IoIosAddCircleOutline
                                                      style={{ marginRight: "10px" }}
                                                      size={"20px"}
                                                    />
                                                    Add
                                                  </button>

                                                ) : field.type === 'dinputselect' ? (
                                                  <div>
                <input
                  value={addDiamond[getProcessedKey(field.key)] || ""}
                  onChange={(e) => middlediamondchnge(getProcessedKey(field.key), e.target.value)}
                  // onKeyDown={(e) => handleEnterKey(e, index, getProcessedKey(field.key), diamondFields.map(f => f.attributeType))}
                  type="text"
                  placeholder={field.placeholder}
                  list={`diamondAttributes-${field.attributeType}`}
                />
                <datalist id={`diamondAttributes-${field.attributeType}`}>
                  {allDiamondAttributes
                    .filter((attr) => attr.DiamondAttribute === getProcessedKey(field.key))
                    .map((attribute) => (
                      <option key={attribute.DiamondValue} value={attribute.DiamondValue}>
                        {attribute.DiamondValue}
                      </option>
                    ))}
                </datalist>
              </div>



                                                ) :


                                                  null}
                                      </th1>


                                    ))}
                                </tr>
                              </thead>
                            </table>



                          </div>
                        </div>

                      </>
                    ) : null}
                  </div>
                </form>
                <form>

                  <div>



                    {groupproducts && (
                      <>


                        <h3
                          style={{
                            margin: "0px",
                            padding: "0px",
                            textAlign: "left"
                          }}
                          className="adminAddBulkStockAddedTitle"
                        >
                          Added Products
                        </h3>
                        <div className="adminAddBulkStockAddedProductsOuterBox">


                          {groupproducts.map((groupItems, index) => {
                            // Calculate totals for the group
                            const totalGrossWt = groupItems.reduce((sum, product) => sum + (parseFloat(product.GrossWt) || 0), 0).toFixed(3);
                            const totalStoneWt = groupItems.reduce((sum, product) => sum + (parseFloat(product.TotalStoneWeight) || 0), 0).toFixed(3);
                            const totalNetWt = groupItems.reduce((sum, product) => sum + (parseFloat(product.NetWt) || 0), 0).toFixed(3);

                            const vendorName = groupItems[0]?.VendorName || "N/A"; // Fallback in case of missing vendor
                            const sku = groupItems[0]?.SKU || "N/A"; // Fallback in case of missing SKU
                            const productName = groupItems[0]?.ProductName; // Assuming all items in a group have the same product name

                            return (
                              <div key={index} className="summary-box" style={{ marginBottom: '10px', border: '1px solid #ccc', borderRadius: '4px' }}>
                                <div
                                  onClick={() => toggleGroup(index)}
                                  style={{ padding: '10px', cursor: 'pointer', background: '#f0f0f0', display: 'flex', justifyContent: 'space-between' }}>
                                  <span style={{ fontSize: '12px' }}> Product Qty -{groupItems.length}</span>
                                  {/* Display additional info beside group header */}

                                  <span style={{ marginLeft: '5px', fontSize: '12px', fontWeight: 'normal' }}>
                                    {/* <strong>Product Qty - {groupItems.length}</strong> {vendorName},  */}
                                    <strong>Vendor:</strong> {vendorName},
                                    <strong>SKU:</strong> {sku}, <strong>Product:</strong> {productName},
                                    <strong>     Gross Weight:</strong> {totalGrossWt}, <strong>Stone Weight:</strong> {totalStoneWt},
                                    <strong>  Net Weight:</strong> {totalNetWt}
                                  </span>


                                  <span>{expandedGroups[index] ? '▲' : '▼'}</span> {/* Dropdown arrow */}
                                </div>
                                {expandedGroups[index] && (
                                  <form>
                                    <TableContainer sx={{ borderSpacing: '0', borderCollapse: 'collapse' }}>
                                      <table size="small" style={{ borderRadius: '4px', borderCollapse: 'collapse', borderSpacing: '0' }}>
                                        <thead style={{ backgroundColor: '#627383', color: '#FFFFFF' }}>
                                          <tr>
                                            {
                                              // sortedColumns
                                              itemfields
                                                .filter((field) => field.place === 'grid' && field.IsVisible)
                                                .filter(field => {
                                                  const category = groupItems[0]?.Category; // assuming all items in a group share the same category
                                                  if (category && !category.includes("diamond")) {
                                                    return !field.key.toLowerCase().includes("diamond");
                                                  }
                                                  if (category === "loose diamond") {
                                                    return field.key.toLowerCase().includes("diamond");
                                                  }
                                                  return true; // Render all fields if category contains "diamond"
                                                })
                                                .map((column) => (
                                                  <th key={column.key} style={{
                                                    padding: '3px',
                                                    border: '1px solid #ccc',
                                                    textAlign: 'center',
                                                    backgroundColor: '#627383', // Dark backgroundF
                                                    color: '#fff', // White text
                                                    fontSize: '12px', // Font size set to 8px
                                                    fontWeight: 'normal', // Non-bold font
                                                  }}  >{column.label}</th>
                                                ))}
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {groupItems.map((product, idx) => (
                                            <tr key={idx} style={{ borderBottom: '1px solid #ccc' }}>
                                              {
                                                // sortedColumns
                                                itemfields
                                                  .filter((field) => field.place === 'grid' && field.IsVisible)
                                                  .filter(field => {
                                                    const category = groupItems[0]?.Category; // assuming all items in a group share the same category
                                                    if (category && !category.includes("diamond")) {
                                                      return !field.key.toLowerCase().includes("diamond");
                                                    }
                                                    if (category === "loose diamond") {
                                                      return field.key.toLowerCase().includes("diamond");
                                                    }
                                                    return true; // Render all fields if category contains "diamond"
                                                  })
                                                  .map((column) => (
                                                    <td key={column.key} style={tdStyle}>
                                                      {column.type === "number" ? (
                                                        <input
                                                          id={column.key}
                                                          type="text"
                                                          placeholder={column.placeholder || product[column.key]}
                                                          value={product[column.key]}
                                                          onChange={(e) => handleInputChangenew(e, index, idx, column.key)} // Adjust the function as needed
                                                          style={inputStyle}
                                                          onKeyDown={handleEnterKeyNavigation}
                                                          disabled={
                                                            column.disabled ||
                                                            ((column.key === "TotalStoneWeight" || column.key === "TotalStoneAmount" || column.key === "TotalStoneLength") && product.Stones.length > 0)
                                                          }
                                                        />
                                                      ) : column.type === "read" ? (
                                                        <span style={{ fontSize: '12px', minWidth: '10px' }}>{product[column.key]}</span> // Read-only text
                                                      ) : column.type === "button" ? (
                                                        <button
                                                          style={{ fontSize: '12px' }}
                                                          onClick={(event) => handleButtonClick(event, column.key, index, idx)}
                                                        // onClick={() => handleButtonClick(column.key, index, idx)}
                                                        >
                                                          {`${column.placeholder}-${product[column.key] ? product[column.key].length : 0}`}
                                                        </button>
                                                      ) : column.type === "dropdown" ? (
                                                        column.key === 'VendorId' ? (

                                                          <select
                                                            name="VendorId"
                                                            onChange={(e) => handledropdown(e, index, idx, column.key)}
                                                            onKeyDown={handleEnterKeyNavigation}
                                                            value={product[column.key]}
                                                            disabled={column.disabled}
                                                          >
                                                            <option value="">Select a Purity</option>
                                                            {filteredparty.map((x, y) => {
                                                              return (
                                                                <option
                                                                  key={y}
                                                                  value={parseInt(x.Id)}
                                                                >
                                                                  {x.VendorName}
                                                                </option>
                                                              );
                                                            })}
                                                          </select>
                                                        ) : column.key === 'Gender' ? (

                                                          <select
                                                            name="Gender"
                                                            onChange={(e) => handledropdown(e, index, idx, column.key)}
                                                            onKeyDown={handleEnterKeyNavigation}
                                                            value={product[column.key]}
                                                            disabled={column.disabled}
                                                          >
                                                            <option value="">
                                                              Select an option
                                                            </option>
                                                            <option value="Male">Male</option>
                                                            <option value="Female">
                                                              Female
                                                            </option>
                                                            <option value="Unisex">
                                                              Unisex
                                                            </option>
                                                            <option value="Kids">Kids</option>
                                                          </select>
                                                        ) :
                                                          null





                                                      ) : null}
                                                    </td>
                                                  ))}
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>

                                    </TableContainer>
                                  </form>
                                )}

                              </div>

                            );
                          })}

                          <div
                            // style={{ marginLeft: "10px" }}
                            style={{ width: "100%" }}
                            className="bulkProductAddingTableMain"
                          >
                            {groupproducts.length > 0 ? (
                              <button
                                type="button"
                                style={{
                                  marginLeft: !deleteSelected ? "1.5rem" : "0px",
                                  cursor: "pointer",
                                }}
                                onClick={handleEditgroupProducts}>


                                {/* onClick={
                                   stockType === "Labelled"
                                     ? handleEditgroupProducts
                                     : handleEditProductsUnlabelled
                                 }
                              > */}
                                <BiSave
                                  size={"12px"}
                                  style={{ marginRight: "5px" }}
                                />
                                Save All1
                              </button>
                            ) : null}

                            <Link to="/inventory">
                              <button
                                style={{
                                  cursor: "pointer",
                                  marginLeft:
                                    addedProducts.length > 0 ? null : "1.5rem",
                                }}
                              >
                                <BiListUl
                                  size={"12px"}
                                  style={{
                                    marginRight: "5px",
                                  }}
                                />
                                Labelled List
                              </button>
                            </Link>
                            <Link to="/unlabelled_list">
                              <button style={{ cursor: "pointer" }}>
                                <BiListUl
                                  size={"12px"}
                                  style={{ marginRight: "5px" }}
                                />
                                Unlabelled List
                              </button>
                            </Link>
                          </div>




                        </div>
                      </>
                    )}

                  </div>


                </form>




              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
