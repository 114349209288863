// import React, { useEffect, useRef, useState } from "react";
// import { MdOutlineDashboard } from "react-icons/md";
// import {
//   AiOutlineDown,
//   AiOutlineAppstore,
//   AiOutlineGold,
// } from "react-icons/ai";
// import { TfiRulerPencil } from "react-icons/tfi";
// import { TbStack2 } from "react-icons/tb";
// import { BsClipboardPlus } from "react-icons/bs";
// import { FcMoneyTransfer } from "react-icons/fc";
// import { FaFileInvoiceDollar } from "react-icons/fa";
// import { RiCoupon3Line } from "react-icons/ri";
// import { HiOutlineDocumentReport } from "react-icons/hi";
// import { GiReceiveMoney } from "react-icons/gi";
// import { useNavigate } from "react-router-dom";

// export default function AdminSecondNavbar() {
//   const navigate = useNavigate();
//   const reports = [
//     { id: 24, name: "Stock", linkto: "/stock_report" },
//     { id: 25, name: "Sale", linkto: "/admin_sale_report" },
//     { id: 25, name: "Order List", linkto: "/admin_orders" },
//     { id: 26, name: "Purchase", linkto: "/purchase" },
//     { id: 27, name: "Customer Ledger", linkto: "/ledger_main" },
//     { id: 27, name: "Supplier Ledger", linkto: "/ledger_main" },
//     { id: 29, name: "Old Metal", linkto: "/purchase?openView:RDPurchase" },
//     { id: 28, name: "Cash", linkto: "/cash_report" },
//     // { id: 30, name: "GST", linkto: "/adminhome" },
//   ];

//   return (
//     <div className="adminDesktopSecondNavbarMainBox">
//       <div
//         onClick={() => {
//           navigate("/purchase_entry");
//         }}
//         className="adminDesktopSecondNavbarItemsBox"
//       >
//         <AiOutlineGold size={"20px"} />
        
//         <p>Purchase Entry</p>
       
//       </div>
//       <div
//         onClick={() => {
//           // navigate("/add_bulk_product");
//           navigate("/add_bulk_stock_new");
//         }}
//         className="adminDesktopSecondNavbarItemsBox"
//       >
//         <BsClipboardPlus size={"17px"} />
//         <p>Add Stock</p>
       
//       </div>
//       <div
//         onClick={() => navigate("/admin_estimation")}
//         className="adminDesktopSecondNavbarItemsBox"
//       >
//         <FaFileInvoiceDollar size={"17px"} />
//         <p>WholeSale</p>
       
//       </div>
//       <div
//         onClick={() => navigate("/admin_invoice")}
//         className="adminDesktopSecondNavbarItemsBox"
//       >
//         <FaFileInvoiceDollar size={"17px"} />
//         <p>Invoice</p>
       
//       </div>
//       <div
//         onClick={() => navigate("/receive_payments")}
//         className="adminDesktopSecondNavbarItemsBox"
//       >
//         <GiReceiveMoney size={"17px"} />
//         <p>Payment Received</p>
        
//       </div>
//       <div className="adminDesktopSecondNavbarDropdownReportsBox">
//         <div className="adminDesktopSecondNavbarItemsBox">
//           <HiOutlineDocumentReport size={"17px"} />
//           <p>Reports</p>
//           <AiOutlineDown />
//         </div>
//         <div className="adminDesktopSecondNavbarDropdownReportsItemsMainBox">
//           {reports.map((x) => {
//             return <p onClick={() => navigate(`${x.linkto}`)}>{x.name}</p>;
//           })}
//         </div>
//       </div>
//     </div>
//   );
// }


import React from "react";
import { MdOutlineDashboard } from "react-icons/md";
import {
  AiOutlineDown,
  AiOutlineAppstore,
  AiOutlineGold,
} from "react-icons/ai";
import { TfiRulerPencil } from "react-icons/tfi";
import { TbStack2 } from "react-icons/tb";
import { BsClipboardPlus } from "react-icons/bs";
import { FcMoneyTransfer } from "react-icons/fc";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { RiCoupon3Line } from "react-icons/ri";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { GiReceiveMoney } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function AdminSecondNavbar() {
  const navigate = useNavigate();
  const adminLoggedIn = useSelector((state) => state.reducer1);
  const permissionsLoaded = useSelector((state) => state.reducer1.permissionsLoaded);

  // Check permission for a given link
  const hasPermission = (path) => {

    const path1 = path.startsWith("/")
    ? path.slice(1)
    : path;

    if ((!adminLoggedIn?.Modules || adminLoggedIn.Modules.length === 0) && adminLoggedIn?.SuperAdmin === 1) {
      return true;
  }
  
    return adminLoggedIn?.Modules?.some(
      (module) => module.PageName === path1 && module.PagePermission === "enable"
    );
  };


  if (!permissionsLoaded) {
    return <div>Loading...</div>; // Or any other loading indicator
}

  const reports = [
    { id: 24, name: "Stock", linkto: "/stock_report" },
    { id: 25, name: "Sale", linkto: "/admin_sale_report" },
    { id: 25, name: "Order List", linkto: "/admin_orders" },
    { id: 26, name: "Purchase", linkto: "/purchase" },
    { id: 27, name: "Customer Ledger", linkto: "/ledger_main" },
    { id: 27, name: "Supplier Ledger", linkto: "/ledger_main" },
    { id: 29, name: "Old Metal", linkto: "/purchase?openView:RDPurchase" },
    { id: 28, name: "Cash", linkto: "/cash_report" },
  ];

  return (
    <div className="adminDesktopSecondNavbarMainBox">
      {/* Conditionally render each menu item based on permission */}
      {hasPermission("/purchase_entry") && (
        <div
          onClick={() => navigate("/purchase_entry")}
          className="adminDesktopSecondNavbarItemsBox"
        >
          <AiOutlineGold size={"20px"} />
          <p>Purchase Entry</p>
        </div>
      )}
      
      {hasPermission("/add_bulk_stock_new") && (
        <div
          onClick={() => navigate("/add_bulk_stock_new")}
          className="adminDesktopSecondNavbarItemsBox"
        >
          <BsClipboardPlus size={"17px"} />
          <p>Add Stock</p>
        </div>
      )}

      {hasPermission("/admin_estimation") && (
        <div
          onClick={() => navigate("/admin_estimation")}
          className="adminDesktopSecondNavbarItemsBox"
        >
          <FaFileInvoiceDollar size={"17px"} />
          <p>WholeSale</p>
        </div>
      )}

      {hasPermission("/admin_invoice") && (
        <div
          onClick={() => navigate("/admin_invoice")}
          className="adminDesktopSecondNavbarItemsBox"
        >
          <FaFileInvoiceDollar size={"17px"} />
          <p>Invoice</p>
        </div>
      )}

      {hasPermission("/receive_payments") && (
        <div
          onClick={() => navigate("/receive_payments")}
          className="adminDesktopSecondNavbarItemsBox"
        >
          <GiReceiveMoney size={"17px"} />
          <p>Payment Received</p>
        </div>
      )}

      <div className="adminDesktopSecondNavbarDropdownReportsBox">
        <div className="adminDesktopSecondNavbarItemsBox">
          <HiOutlineDocumentReport size={"17px"} />
          <p>Reports</p>
          <AiOutlineDown />
        </div>
        <div className="adminDesktopSecondNavbarDropdownReportsItemsMainBox">
          {reports
            .filter((report) => hasPermission(report.linkto)) // Only render reports with permission
            .map((x) => (
              <p key={x.id} onClick={() => navigate(`${x.linkto}`)}>
                {x.name}
              </p>
            ))}
        </div>
      </div>
    </div>
  );
}
